import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FacebookProvider, Like } from 'react-facebook';

import './PostView.css'
import Author from './Author'
import Categories from './Categories'
import CommentsForm from './CommentsForm'
import PostWidget from './PostWidget'
import AdjacentPosts from './AdjacentPosts'
import PostDetail from './PostDetail'
import CatHeader from './CatHeader'
import { getCategories, getPostDetails } from '../../API/BlogStore'
import { Helmet } from 'react-helmet-async';

const PostView = () => {
    const [categories, setCategories] = useState([]);
    const [SelectedPost, setSelectedPost] = useState([]);

    const { slug } = useParams()

    useEffect(() => {
        async function receivedPostDetails() {
            const sPost = await getPostDetails(slug)
            setSelectedPost(sPost)
        }
        receivedPostDetails()
    }, [slug])

    useEffect(() => {
        getCategories()
            .then((newCategories) => {
                setCategories(newCategories);
            });
    }, []);


    const facebookID = 686871576266039;


    return (
        SelectedPost.length === 0 ?
            (
                <h1>Loading...</h1>
            )
            :
            (
                <>
       <Helmet>
          <title>{SelectedPost.title}</title>
          <meta name="description" content={SelectedPost.excerpt} />
          <meta property="og:title" content={SelectedPost.title} />
          <meta property="og:description" content={SelectedPost.excerpt} />
          <meta property="og:image" content={SelectedPost.featuredImage.url} />
          <meta property="og:url" content={`https://superjcybs.com/blog/post/${SelectedPost.slug}`} />
        <link rel='canonical' href={`https://superjcybs.com/blog/post/${SelectedPost.slug}`} />
                <meta name="twitter:title" content={SelectedPost.title} />
        <meta name="twitter:description" content={SelectedPost.excerpt} />
      </Helmet>
                    <main id='post'>
                        <p style={{ textAlign: 'center', backgroundColor:'blueviolet'}}>
                            <Link to='/blog'>Back to Blog </Link>
                        </p>
                        <CatHeader categories={categories} />

                        <div className="container" >
                            <div className='blog__contentLeft'>
                                <PostDetail post={SelectedPost} />
                                <Author author={SelectedPost.author} />
                                <AdjacentPosts slug={SelectedPost.slug} createdAt={SelectedPost.createdAt} />
                                <CommentsForm slug={SelectedPost.slug} />
                                <hr />
                                <FacebookProvider appId={facebookID}>
                                    <Like href="http://www.facebook.com" colorScheme="dark" showFaces share />
                                </FacebookProvider>
                        </div>

                        <div className="blog__contentRight">
                            <div className=" relative">
                                    <PostWidget slug={SelectedPost.slug} categories={SelectedPost.categories.map((category) => category.slug)} />
                                <Categories />
                            </div>
                        </div>

                    </div>
                </main>
                </>
            )
    )
}

export default PostView