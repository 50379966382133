import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import './Hero.css'
const Hero = () => {
  return (
    <div id='hero'>
          <h1 hidden>SUPERJCYBS - Super J Cy-Business And Services</h1>
      <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/Assets/images/hero/1copy.jpg"
            alt="Superjcybs hero 1st slide"
            title='Superjcybs hero 1st slide' loading='lazy'
          />
          <Carousel.Caption className='hcaption' >
            <h2>SUPERJCYBS - We Match All</h2>
            <p>No need to think of whether we can undertake your orders in an unappropriate time.
              The inappropriateness may exist with you and not us. So link up
              </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/Assets/images/hero/2.jpg"
            alt="Second slide"
            title='Superjcybs hero 2nd slide' loading='lazy'
          />

          <Carousel.Caption className='hcaption' >
            <h2 >We are Creative with Technology</h2>
            <p>We buy and sell into creativity since we believe in technology.
              Distance isn't a factor at all because our Creativity in Technology can get to you.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/Assets/images/hero/3.jpg"
            alt="Third slide"
            title='Superjcybs hero 3rd slide' loading='lazy'
          />

          <Carousel.Caption className='hcaption'>
            <h2 >SUPERJCYBS - We Top All</h2>
            <p>
              There is no alternative to the the best so walk into any of our offices or platforms for excellent results.
              We are always ready to deliver so call us.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

    </div>
  )
}

export default Hero