// import React, { useEffect, useState } from 'react'
import './Pagination.css'
import { getPosts } from '../../API/BlogStore'
import { Link, useNavigate, useLocation} from 'react-router-dom';
import { useEffect, useState } from 'react';


const Pagination = ({ setPosts, totalPage }) => {

    const { search } = useLocation();
      const navigate = useNavigate();
      const page = Number(new URLSearchParams(search).get('page')) || 1;
      
      const [pageNumber, setpageNumber] = useState([]);

useEffect(() => {
    if (totalPage <= 1) return;

    if (totalPage <= 5) {
        const newArr = [...Array(totalPage)].map((_, i) => i + 1);
        return setpageNumber(newArr);
    }
    let newArr = [];
    for (let index = 1; index <= 5; index++) {
        if (page <= 2) {
            newArr.push(index)
        }
        if (page > 2 && page < totalPage - 2) {
            newArr.push(page + index - 3)
        }
        if (page >= totalPage - 2) {
            newArr.push(index - 5 + totalPage)
        }
    }
    setpageNumber(newArr)
}, [totalPage, page])

    if (totalPage <= 1) return null;

    const handleChange = async (direction) => {
        let newPage;
        if (direction === 'next') {
            newPage = page + 1 >= totalPage ? totalPage : page + 1;
        } else {
            newPage = page - 1 <= 1 ? 1 : page - 1;
        }
        navigate(`?page=${newPage}`);

        const { edges } = await getPosts(newPage);
        setPosts(Array.isArray(edges) ? edges : []);
    }

    const delOption = document.querySelectorAll('.page-item')

    const changeActiveItem = () => {
        delOption.forEach(item => {
            item.classList.remove('active')
        })
    }

    delOption.forEach(item => {
        item.addEventListener('click', () => {
            changeActiveItem()
            item.classList.add('active')
        })
    })

    return (
        <div >
            <nav aria-label="Page navigation">
                <ul className="pagination my-3">
                    <li className="page-item">
                        <button
                            className="page-link previous"
                            onClick={() => handleChange('prev')}
                            disabled={page <= 1}
                        >
                            Previous
                        </button>
                    </li>
                    <ul className='dtnumbers p-2 bg-white border rounded-3'>
                        {
                            pageNumber.map(num => (
                                <Link to={`?page=${num}`} key={num}>
                                    <li className={`page-item ${page === num ? 'active' : ''}`}>
                                        <button className="page-link">{num}</button>
                                    </li>
                                </Link>
                            ))
                        }
                    </ul>
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={() => handleChange('next')}
                            disabled={page >= totalPage}
                        >
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    )
}


export default Pagination 