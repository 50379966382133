import React from 'react'
import MoodIcon from '@mui/icons-material/Mood';
import { Link } from 'react-router-dom';

const DTNotFound = () => {
  return (
    <>
    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <img src='/Assets/images/dunamistravels/notfound.png' alt='' style={{margin:'20px 0', maxWidth:'100%', maxHeight:'100%', objectFit:'cover', mixBlendMode: 'darken'}}/>
    </div>
      <div style={{ display: 'flex', justifyContent: 'center', fontSize: '1.5rem', fontWeight:'bold' }}>
      <p>Sorry the page you seek isn't available, please check what you typed well or &nbsp;</p><br/>
      <Link to='/dunamistravels' className='link' style={{color:'red'}}><MoodIcon />click on me</Link>
      </div>
  </>
      )
}

export default DTNotFound