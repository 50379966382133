import React, { useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import './TSBFGH.css'
import { Link } from 'react-router-dom';

const TSBFGH = () => {
    useEffect(() => {
        document.title = "TSBF Ghana"
    }, [])

    return (
        <div className='tsbfgh'>
            <p style={{ textAlign: 'center' }}>
                <Link to='/'>Back to SUPERJYCBS</Link>
            </p>
            <article className='tsbf__intro'>
                We are "The Supernaturals Business Family" which is the full meaning of the abbreviation TSBF, we are involved in finding solutions
                to the woes of humanity.
                With our combined knowledge and 'can dos', we access the issues faced by people and generate solution measures to ease their pains.
                We operate with variations of sectors hence you can contact us for all your worries even if it is not stated below...
            </article>
            <section className="tsbf">
                <h2 className='font-bold'>OUR SECTORS</h2>
                <h4 className='text-center'>We have done a lot during this our 5 years of establishment</h4>

                <Row xs={1} sm={2} md={3} lg={4} className="g-4 p-5 text-center">
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/superjcybs.jpg" alt='SUPERJCYBS' title='SUPERJCYBS' loading='lazy' />
                            <Card.Body>
                                <Card.Title>SUPERJCYBS</Card.Title>
                                <Card.Text>
                                    SUPERJCYBS is the sector of this group which is in-charge of putting whatever actions on the web in all forms.
                                    It is also responsible for every technological trainings throughout <b><i>The Supernaturals Business Family</i></b>.
                                </Card.Text>
                                <Link to="/"><Button variant="primary">Visit our page</Button></Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/Dunamis.jpg" target='blank' alt='Dunamis Travels' title='Dunamis Travels' loading='lazy' />
                            <Card.Body>
                                <Card.Title>Dunamis Travels</Card.Title>
                                <Card.Text>
                                    DUNAMIS TRAVELS is the sector of <b><i>The Supernaturals Business Family</i></b> responsible for Local and International recruitment, Travels and
                                    overseas activities that may become necessary for any member or beneficiary.
                                </Card.Text>
                                <a href="/dunamistravels" target='blank'><Button variant="primary">Visit our page</Button></a>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/tsbftv.jpg" alt='TSBF TV' title='TSBF TV' loading='lazy' />
                            <Card.Body>
                                <Card.Title>TSBF TV</Card.Title>
                                <Card.Text>
                                    TSBF TV is the sector of <b><i>The Supernaturals Business Family</i></b> which is responsible for broadcasting our works to the world; whether it be
                                    visual or audio. Its includes a radio and TV production.
                                </Card.Text>
                                <a href="https://tsbftv.superjcybs.com" target='blank'><Button variant="primary">Visit our page</Button></a>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/supamartgh.png" alt='Supamart Ghana' title='Supamart Ghana' loading='lazy' />
                            <Card.Body>
                                <Card.Title>Supamart Ghana</Card.Title>
                                <Card.Text>
                                    SUPAMART GHANA is the sector of <b><i>The Supernaturals Business Family</i></b> which provides a shopping arcade both offline and online for members, beneficiaries and even the general public
                                    consumption/usage.<br />
                                </Card.Text>
                                <a href="/tsbf-ghana/sector=supamart" target='blank'><Button variant="primary">Visit our page</Button></a>

                            </Card.Body>
                        </Card>
                    </Col>
                    {/* </Row> */}
                    {/* <Row xs={1} sm={2} md={3} lg={4} className="g-4 p-5 text-center"> */}
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/ts4humanity.png" alt='TS4Humanity' title='TS4Humanity' loading='lazy' />
                            <Card.Body>
                                <Card.Title>TS4Humanity</Card.Title>
                                <Card.Text>
                                    TS4HUMANITY is the sector of <b><i>The Supernaturals Business Family</i></b> responsible for spotting and serving the less priveleged in
                                    societies and to recruit them into the groupe's mission.<br />
                                    It also serves communities with social amenities when the need be.
                                </Card.Text>
                                <Link to="/tsbf-ghana/sector=TS4Humanity" target='blank'><Button variant="primary">Visit our page</Button></Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/tstransport.png" alt='TS Transport Services' title='TS Transport Services' loading='lazy' />
                            <Card.Body>
                                <Card.Title>TS Transport Services</Card.Title>
                                <Card.Text>
                                    TS TRANSPORT SERVICES is the sector of <b><i>The Supernaturals Business Family</i></b> responsible for every form of land commutations of members, beneficiaries
                                    and even the general public at an unbeatable price or no cost at all.
                                </Card.Text>
                                <Link to="/tsbf-ghana/sector=TSTransport" target='blank'><Button variant="primary">Visit our page</Button></Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/flashsolve.png" alt='Flash Solve FMS' title='Flash Solve FMS' loading='lazy' />
                            <Card.Body>
                                <Card.Title>Flash Solve FMS</Card.Title>
                                <Card.Text>
                                    FLASH SOLVE FACILITY MANAGEMENT SERVICES is the sector of <b><i>The Supernaturals Business Family</i></b> which serves as the working hand.<br />
                                    Members are assigned roles and locations of work under the auspices of this sector. <br />
                                    External firms are able to request for staff from here.
                                </Card.Text>
                                <Link to="/tsbf-ghana/sector=FlashSolveFMS" target='blank'><Button variant="primary">Visit our page</Button></Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/tscatering.png" alt='TS Catering Services' title='TS Catering Services' loading='lazy' />
                            <Card.Body>
                                <Card.Title>TS Catering Services</Card.Title>
                                <Card.Text>
                                    TS CATERING SERVICES is the sector of <b><i>The Supernaturals Business Family</i></b> responsible for the feeding of members, beneficiaries and
                                    even the general public if the need be.<br />
                                    This sector feeds the stomachs of all on all database and beyond.
                                </Card.Text>
                                <a href="/tsbf-ghana/sector=tscateringservices" target='blank'><Button variant="primary">Visit our page</Button></a>

                            </Card.Body>
                        </Card>
                    </Col>
                    {/* </Row> */}
                    {/* <Row xs={1} sm={2} md={3} lg={4} className="g-4 p-5 text-center"> */}
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/tsmicrofinance.png" alt='TS Microfinance' title='TS Microfinance' loading='lazy' />
                            <Card.Body>
                                <Card.Title>TS Microfinance</Card.Title>
                                <Card.Text>
                                    TS MICROFINANCE is the sector of <b><i>The Supernaturals Business Family</i></b> responsible for keeping and controlling the financial accounting
                                    part of the groupe.<br />
                                    It also serve as an advisory body in terms of finances for members, beneficiaries and even the general public.
                                </Card.Text>
                                <a href="/tsbf-ghana/sector=tsmicrofinance" target='blank'><Button variant="primary">Visit our page</Button></a>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/estgyimfarms.jpg" alt='EstGyim Farms' title='EstGyim Farms' loading='lazy' />
                            <Card.Body>
                                <Card.Title>EstGyim Farms</Card.Title>
                                <Card.Text>
                                    ESTGYIM FARMS is the first sector of <b><i>The Supernaturals Business Family</i></b> which was created and named after the inspiration of
                                    any person outside of The Supernaturals Family.
                                    It was named after Maame Esther Gyimah who is the mother of Rev. Jerry K. Supernatural.
                                </Card.Text>
                                <Link to="/tsbf-ghana/sector=EstGyimFarms" target='blank'><Button variant="primary">Visit our page</Button></Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/tstelecom.png" alt='TS Telecom' title='TS Telecom' loading='lazy' />
                            <Card.Body>
                                <Card.Title>TS Telecom</Card.Title>
                                <Card.Text>
                                    TS TELECOM is the sector of <b><i>The Supernaturals Business Family</i></b> responsible for everything telecommunication amongst members, beneficiaries and even the
                                    general public as well as external communication.
                                </Card.Text>
                                <Link to="/tsbf-ghana/sector=TSTelecom" target='blank'><Button variant="primary">Visit our page</Button></Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/crownedbrains.png" alt='Crowned Brains PTI' title='Crowned Brains PTI' loading='lazy' />
                            <Card.Body>
                                <Card.Title>Crowned Brains PTI</Card.Title>
                                <Card.Text>
                                    CROWNED BRAINS PROFESSIONAL TRAINING INSTITUTE (CBPTI) is the sector of <b><i>The Supernaturals Business Family</i></b> which is responsible
                                    for the entire training/bringing up of beneficiaries of the groupe.<br />
                                    This includes physical bodily trainings, mind and spiritual training.
                                </Card.Text>
                                <Link to="/tsbf-ghana/sector=CrownedBrainsPTI" target='blank'><Button variant="primary">Visit our page</Button></Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    {/* </Row> */}
                    {/* <Row xs={1} sm={2} md={3} lg={4} className="g-4 p-5 text-center"> */}
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/tsindustries.png" alt='TS Industries' title='TS Industries' loading='lazy' />
                            <Card.Body>
                                <Card.Title>TS Industries</Card.Title>
                                <Card.Text>
                                    TS INDUSTRIES is the sector of <b><i>The Supernaturals Business Family</i></b> responsible for every industrial works.<br />
                                    This sectors deals with machineries for manufactoring and processing of raw materials into finished or semi-finished goods.
                                </Card.Text>
                                <Link to="/tsbf-ghana/sector=TSIndustries" target='blank'><Button variant="primary">Visit our page</Button></Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/tscid.png" alt='TS Construction & Interior Designs' title='TS Construction & Interior Designs' loading='lazy' />
                            <Card.Body>
                                <Card.Title>TS Construction & Interior Designs</Card.Title>
                                <Card.Text>
                                    TS CONSTRUCTION AND INTERIOR DESIGNS is the sector of <b><i>The Supernaturals Business Family</i></b> responsible for everything construction.<br />
                                    From building everything structural to giving it aesthetic looks. For the groupe and public.
                                </Card.Text>
                                <Link to="/tsbf-ghana/sector=TSConstructionID" target='blank'><Button variant="primary">Visit our page</Button></Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/supercityah.png" alt='SuperCity Accomodation Hub' title='SuperCity Accomodation Hub' loading='lazy' />
                            <Card.Body>
                                <Card.Title>SuperCity Acc. Hub</Card.Title>
                                <Card.Text>
                                    SUPERCITY ACCOMODATION HUB is the sector of <b><i>The Supernaturals Business Family</i></b> responsible for managing the structures built
                                    by the construction department.<br />
                                    They provide decent Accomodation for the beneficiaries, members and even the general public.
                                </Card.Text>
                                <Link to="/tsbf-ghana/sector=SuperCityAccomodationHub" target='blank'><Button variant="primary">Visit our page</Button></Link>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="Assets/images/sectors/iwinyec.jpg" alt='iWIN Youth Empowerment Club' title='iWIN Youth Empowerment Club' loading='lazy' />
                            <Card.Body>
                                <Card.Title>IWINYEC</Card.Title>
                                <Card.Text>
                                    I WIN YOUTH EMPOWERMENT CLUB is the sector of <b><i>The Supernaturals Business Family</i></b> responsible for putting our ideas on the
                                    minds of the youth right from Primary, Junior, Secondary and even the tertiary level of others schools out there.
                                </Card.Text>
                                <Link to="/tsbf-ghana/sector=IWINYEC" target='blank'><Button variant="primary">Visit our page</Button></Link>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </div>
    )
}

export default TSBFGH