import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import YoutubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';

const DTFooter = () => {
  return (
    <footer id='dtfooter'>
    <span className='copyright text-white'>DUNAMIS TRAVELS @ 2024</span>
    <span className='dev'><a href='https://superjcybs.com'>Powered By: SUPERJCYBS</a></span>
    
    <div className="footer-social">
      <a href="tel:+233247792110"><CallIcon /></a>
      <a href='mailto:dunamistravels@superjcybs.com'><MailOutlineIcon /></a>
      <a href='https://facebook.com/dunamistravels1'><FacebookIcon /></a>
      <a href='https://youtube.com/@tsbftv'><YoutubeIcon /></a>
      <a href='https://twitter.com/superjcybs'><TwitterIcon/></a>
      <a href='https://instagram.com/superjcybs'><InstagramIcon /></a>
      <a href='https://t.me/superjcybs'><TelegramIcon /></a>
    </div>
    </footer>

  )
}

export default DTFooter