import React from 'react'
import Nav from 'react-bootstrap/Nav';

const CatHeader = ({categories}) => {

    return (
        <>
            <Nav className="justify-content-center bg-info" activeKey="/home">
                {categories.map((category) => (
                    <Nav.Item key={category.slug} >
                        <Nav.Link className="text-light" href={`/blog/category/${category.slug}`}>{category.name}</Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
            <hr style={{ color: 'white' }} />
        </>
    )
}

export default CatHeader