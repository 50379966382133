import React from 'react'
import './Clients.css'
import Marquee from 'react-fast-marquee'


const Clients = () => {
  return (
    <>
      <section id="clients" className="clients  text-center">
        <div className="container-fluid">
          <div className="section-title" data-aos="fade-up">
            <h2>Our Clients</h2>
            <p>They trust us to deliver</p>
          </div>
          {/* style={{ transform: 'translate3d(-3220px, 0px, 0px)', transition: 'all 0.25s ease 0s', width: '5700px' }} */}
          <div className="owl-carousel clients-carousel" data-aos="fade-up" data-aos-delay={100}>
            <div className="owl-stage-outer">
              <div className="owl-stage" >
                <Marquee
                  // gradient={false}
                  pauseOnHover={true}
                  style={{ Direction: "right", width: "100%", height: "20", scrollAmount: "1055" }} >
                  <img className="client" src="Assets/images/clients/Supamart.png" alt='SupaMart Ghana' title='SupaMart Ghana' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/crownedbrains.png" alt='Crowned Brains PTI' title='Crowned Brains PTI' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/Dunamistravels.png" alt='Dunamis Travels' title='Dunamis Travels' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/flashsolve.png" alt='Flash Solve FMS' title='Flash Solve FMS' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/tsbftv.png" alt='TSBF TV' title='TSBF TV' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/ts4humanity.png" alt='TS For Humanity' title='TS For Humanity' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/tscatering.png" alt='TS Catering' title='TS Catering' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/tscid3.png" alt='TS Construction' title='TS Construction' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/WebHypers.png" alt='Web Hypers Community' title='Web Hypers Community' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/TSTelecom.png" alt='TS Telecom' title='TS Telecom' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/iwinyec.png" alt='IWIN Youth Empowerment Club' title='IWIN Youth Empowerment Club' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/SupercityAH.png" alt='Supercity Accomodation Hub' title='Supercity Accomodation Hub' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/TSIndustries.png" alt='TS Industries' title='TS Industries' loading='lazy'/>
                  <img className="client" src="Assets/images/clients/TSTransport.png" alt='TS Transport' title='TS Transport' loading='lazy'/>
                </Marquee>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Clients