// import firebase from "firebase";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { collection, getDocs, addDoc, doc, setDoc } from 'firebase/firestore';

const firebaseApp = firebase.initializeApp ({
    apiKey: "AIzaSyAerEO4o76cevd7E9f_QbVR9ghhvdNwqc8",
    authDomain: "dunamistravelsregistration.firebaseapp.com",
    projectId: "dunamistravelsregistration",
    storageBucket: "dunamistravelsregistration.appspot.com",
    messagingSenderId: "640364673927",
    appId: "1:640364673927:web:45dff7db0d2c6bdc607122"
});

// Initialize Firebase
const db = firebaseApp.firestore();
const storage = getStorage()
// const storageRef = ref(storage)
// export default db;
export { db, collection, getDocs, addDoc, doc, setDoc, storage };