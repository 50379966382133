import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Link } from 'react-router-dom'

import './Team.css'

const Team = () => {
  return (
    <div>
      <section id="team" className="team">
        <div className="container">
          <div className="section-title text-center" data-aos="fade-up">
            <h2>Meet Our Team</h2>
            <p>Our team is always here to help</p>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay={100}>
              <div className="member">
                <img src="Assets/images/users/himself.png" className="img-fluid" alt='Jerry Babatunde (Jerry Kwophi Supernatural)' title='Jerry Babatunde (Jerry Kwophi Supernatural)' loading='lazy'/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Jerry Kwophi Supernatural (Babatunde)</h4>
                    <span>Chief Executive Officer</span>
                  </div>
                  <div className="social">
                    <a href="https://wa.me/233247792110"><WhatsAppIcon /></a>
                    <a href="https://www.facebook.com/kofisupernatural"><FacebookIcon /></a>
                    <a href='mailto:jerrybabatunde1@gmail.com'> <MailOutlineIcon /></a>
                    <a href="https://www.linkedin.com/in/jerry-babatunde-81a26bb5/"><LinkedInIcon /></a>
                    <Link to="/cv=jerry-babatunde (Jerry Kwophi Supernatural)">CV</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay={200}>
              <div className="member">
                <img src="Assets/images/users/Amoike.png" className="img-fluid" alt='Isaac Amoasi' title='Isaac Amoasi' loading='lazy'/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Isaac Amoasi</h4>
                    <span>Chief Operations Officer</span>
                  </div>
                  <div className="social">
                    <a href="https://wa.me/233554814506"><WhatsAppIcon /></a>
                    <a href="https://www.facebook.com/amoasi.amoasi.7"><FacebookIcon /></a>
                    <a href='mailto:amoasiisaac2929@gmail.com'> <MailOutlineIcon /> </a>
                    <a href="https://www.linkedin.com/in/isaac-amoasi-33245"><LinkedInIcon /></a>
                    <Link to="/cv=isaac-amoasi">CV</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay={300}>
              <div className="member">
                <img src="Assets/images/users/Millicent.png" className="img-fluid" alt='Millicent Asomanyi' title='Millicent Asomanyi' loading='lazy'/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Millicent Asomani</h4>
                    <span>Public Relations Officer</span>
                  </div>
                  <div className="social">
                    <a href="https://wa.me/233591639589"><WhatsAppIcon /></a>
                    <a href="https://www.facebook.com/constance.afful.98"><FacebookIcon /></a>
                    <a href='mailto:asomanimillicent8@gmail.com'> <MailOutlineIcon /> </a>
                    <a href="https://www.linkedin.com/in/millicent-asomani-4248"><LinkedInIcon /></a>
                    <Link to="/cv=millicent-asomani">CV</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay={400}>
              <div className="member">
                <img src="Assets/images/users/joseph.png" className="img-fluid" alt='Joseph Effraim' title='Joseph Effraim' loading='lazy'/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Joseph Effraim</h4>
                    <span>Sales & Marketing Director</span>
                  </div>
                  <div className="social">
                    <a href="https://wa.me/233241937582"><WhatsAppIcon /></a>
                    <a href="https://www.facebook.com/joseph.afful.98"><FacebookIcon /></a>
                    <a href='mailto:josephpayos@gmail.com'> <MailOutlineIcon /> </a>
                    <a href="https://www.linkedin.com/in/joseph-effraim-81a26b"><LinkedInIcon /></a>
                    <Link to="/cv=joseph-effraim">CV</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay={400}>
              <div className="member">
                <img src="Assets/images/users/eunice.jpg" className="img-fluid" alt='Eunice Maame Esi Supernatural' title='Eunice Maame Esi Supernatural' loading='lazy'/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Eunice Maame Esi Supernatural</h4>
                    <span> Vice President</span>
                  </div>
                  <div className="social">
                    <a href="https://wa.me/233245620564"><WhatsAppIcon /></a>
                    <a href="https://www.facebook.com/eunice.yenu.7"><FacebookIcon /></a>
                    <a href='mailto:eunicemaameesisupernatural@gmail.com'> <MailOutlineIcon /> </a>
                    <Link to='/cv=eunice-maame-esi-supernatural'>CV</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Team