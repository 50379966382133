import React from 'react'
import About from '../components/Home/About'
// import Store from '../components/Home/Store'
import BlogFeed from '../components/Home/BlogFeed'
import Clients from '../components/Home/Clients'
import Contact from '../components/Home/Contact'
import Hero from '../components/Home/Hero'
import Portfolio from '../components/Home/Portfolio'
import Services2 from '../components/Home/Services2'
import Team from '../components/Home/Team'
import SocialFeeds from '../components/Home/SocialFeeds'
import Video from '../components/Home/Video'
import Adverts from '../components/Home/Adverts'
import OurYoutubeFeed from '../components/Home/OurYoutubeFeed'
// import WMN from '../components/Home/WMN'

const Home = () => {


  return (
    <div>
      <Hero />
      <About />
      <Video />
      <Adverts />
      <OurYoutubeFeed />
      <Services2 />
      <br />
      <a href="https://bit.ly/superjcybsonppl" className='d-flex justify-content-center'>
        <img src="http://cashsuperstar.com/ppl/images/makemoneynow728x90.jpg" style={{ width: '80vw' }} alt="Want To Increase Your Online Income? Then Get This System..." />
      </a>
      <br />
      <Portfolio />
      <Clients />
      {/* <WMN/> */}
      <BlogFeed />
      <SocialFeeds />
      {/* <Store/> */}
      <Team />
      <Contact />
    </div>
  )
}

export default Home