import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';
import './TopBar.css'
const TopBar = () => {
  return (
    <div className='topbar'>
      <div className='topbar__left'>
        <ul>
          {/* // USE A SHOPPING CART FOR THE IN-WEB-STORE INSTEAD OF LINKEDIN */}
          <li><a href='https://www.linkedin.com/company/super-j-cy-business-and-services' target="blank"><LinkedInIcon color="primary" /></a></li>
          <li><a href='https://www.facebook.com/superjcybs' target="blank"><FacebookIcon color="primary"/></a></li>
          <li><a href='https://www.instagram.com/superjcybs' target="blank"><InstagramIcon style={{ color: "#8334b9" }} /></a></li>
          <li><a href='https://www.youtube.com/channel/UCnYL6e5pYht_mWKlEE7NTLw?sub_confirmation=1' target="blank"><YouTubeIcon style={{ color:"red" }} /></a></li>
          <li><a href='https://wa.me/+233247792110' target="blank"><WhatsAppIcon style={{ color:"#25D366" }} /></a></li>
          <li><a href='tel:+233247792110' target="blank"><CallIcon style={{ color: "black" }} /><span className='emailadd' style={{ color: 'black' }}>+233 24 7792110</span></a> </li>
          <li><a href='mailto:contact@superjcybs.com' target="blank"> <MailOutlineIcon style={{ color: "#DB4437" }} /><span className='emailadd' style={{color:'black'}}>contact@supercybs.com</span> </a> </li>
          <li><a href='https://paystack.com/pay/buycoffeeforsuperj' target="blank" style={{color: "#DB4437", fontWeight:'bold' }} >DONATE</a></li>
        </ul>
      </div>
      {/* <div className="topbar__middle">
        <input type='text' placeholder='Search'  />
      </div> */}
      <div className='topbar__right'>
        <ul>
          <li><a href='https://forms.gle/r4SavJ4R4p2DUc7H6' target="blank"> Advertise With Us</a></li>
          <li><a href='https://forms.gle/kDpSPcxpkKnQKXY9A' target="blank">Sell With Us</a> </li>
          <li><a href='https://forms.gle/6Y2LUMksayZgdwhy5' target="blank">Job Search</a></li>
          <li><a href='https://paystack.com/pay/buycoffeeforsuperj' target="blank">DONATE</a></li>
        </ul>
      </div>
    </div>
  )
}

export default TopBar