import React, { useState } from 'react'
import moment from 'moment';
import './Comments.css'
import ReplyForm from './ReplyForm';


const Comments = ({slug, commentList }) => {
const [showReplyFormId, setShowReplyFormId] = useState(null);
  return (
    <>
      {commentList.length > 0 && (
        <div className="comments">
          <h3>
            {commentList.length}
            {' '}
            Comment(s)
          </h3>

          {/* COMMENTS */}
          {commentList.map((comment, index) => (
            <div key={index} className="comments__details border rounded-2 p-1 px-3">
              <p>
                <span>{comment.name}</span> {' '}on {' '}{moment(comment.createdAt).format('MMM DD, YYYY')}
              <button className=' border border-2 rounded-2 float-end' onClick={() =>setShowReplyFormId(showReplyFormId === comment.id?null:comment.id)}
                >
                {showReplyFormId === comment.id ? 'Cancel' : 'Reply'}
              </button>
              </p>
              <p className='bg-white border rounded-2 p-3'>{(comment.comment)}</p>
                      
              {showReplyFormId ===comment.id && <ReplyForm addReply={setShowReplyFormId} parentId={comment.id} slug={slug} />}



              {/* REPLIES */}
         <div className="replies  ms-5">
      {comment.replies && comment.replies.map(reply => (
        <div key={reply.id} style={{ marginLeft: '20px' }}>
<p>{'replied by '}
                <span>{reply.name}</span> {' '}on {' '}{moment(reply.createdAt).format('MMM DD, YYYY')}
              <button className=' border border-2 rounded-2 float-end' onClick={() =>setShowReplyFormId(showReplyFormId === reply.id?null:reply.id)}
                >
                {showReplyFormId === comment.id ? 'Cancel' : 'Reply'}
              </button>
              </p>
              <p className='bg-white border rounded-2 p-2'>{(reply.reply)}</p>
                  {showReplyFormId ===reply.id && <ReplyForm addReply={setShowReplyFormId} parentId={reply.id} slug={slug} />}
        </div>
      ))}
        </div>




            </div>
          ))}

        </div>

      )}
    </>
  )
}

export default Comments