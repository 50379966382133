import React from 'react'
import Image from 'react-bootstrap/Image'
import './Author.css'

const Author = ({ author }) => {
    // console.log('fromAuthorPage', author);
  return (
      <div className='author text-center'>
          <div className='author__image'>
              <Image
                  unoptimized='true'
                  alt={author.title}
                  height="90px"
                  width="90px"
                  style={{ borderRadius: '50%', verticalAlign: 'middle' }}
                  src={author.photo.url}
              />
          </div>
          <h3>{author.name}</h3>
          <p>{author.bio}</p>
      </div>
  )
}

export default Author