import React, { useState } from 'react'
import './Subweb.css'
import { Link } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Contact from '../components/Home/Contact';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


const TS4Humanity = () => {
const [Key, setKey] = useState('home');
  return (
    <div className='ts4humanity'>
       <p style={{ textAlign: 'center' }}>
       <Link to='/tsbf-ghana'><i><ChevronLeftIcon /></i> Back to TSBF GHANA</Link>
        </p>
        <h1 className='text-center fw-bold'>TS FOR HUMANITY</h1>

<Tabs id='forts4humanity' activeKey={Key} onSelect={(k)=>setKey(k)} className='mb-3 border' justify variant='pills'>
<Tab eventKey='home' title='Home'>
<h1 style={{fontSize:'10rem', display:'flex', minHeight:'70vh', alignItems:'center', justifyContent:'center', color:'red'}}>COMING SOON...</h1>
</Tab>
<Tab eventKey='contact' title='Contact'>
<Contact/>
</Tab>
</Tabs>
</div>
  )
}

export default TS4Humanity