import React, {useState } from 'react'
import { useParams } from 'react-router-dom'
import { graphcms, CREATE_COMMENT, PUBLISH_COMMENT } from '../Graphql/Mutations'
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import PersonIcon from '@mui/icons-material/Person';
import moment from 'moment';

const DTComments = ({comments}) => {

    const [commentList, setCommentList] = useState(comments);

    const { slug } = useParams();

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [comment, setComment] = useState('')
    const [save, setSave] = useState(true)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)

    const handleComment = async (e) => {
        e.preventDefault();
        const obj = { name, email, comment, slug }

        if (save) {
            localStorage.setItem('name', name)
            localStorage.setItem('email', email)
        } else {
            localStorage.removeItem('name', name)
            localStorage.removeItem('email', email)
        }
        setLoading(true)
        const { createComment } = await graphcms.request(CREATE_COMMENT, obj);
        await graphcms.request(PUBLISH_COMMENT, { id: createComment?.id })
        sendEmail()
        setLoading(false)
        setCommentList([...commentList, { id: createComment.id, name, email, comment }]);
        e.target.reset()
        setName('');
        setEmail('');
        setComment('');
    }


  
    const sendEmail = (e) => {
        // e.preventDefault();
        const templateParams = {
            user_name: name,
            user_email: email,
            comment: comment,
            type:"Comment",
            message: "Not Applicable",
            subject: "Not Applicable",
            platform: "DUNAMIS TRAVELS GHANA",
            slug: slug
        };
        emailjs
            .send('service_qxsvb5t', 'template_zgr4cxj', templateParams, { publicKey: 'ibZjaF1rjAsvzDVGf' })
            .then(
                (result) => {
                    setSuccess(true)
                    setTimeout(() => {
                        setSuccess(null)
                    }, 5000);
                },
                (error) => {
                    setSuccess(false);
                    setTimeout(() => {
                        setSuccess(null)
                    }, 5000);
                });
    };


    return (
        <div className='comments'>
            <h2>Leave a Reply</h2>
            <form target='blank' onSubmit={handleComment}>
                <input type="text" name='user_name' required autoComplete='off' placeholder='Name' value={name} onChange={e => setName(e.target.value)} />
                <input type="Email" name='user_email' required autoComplete='off' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
                <textarea value={comment} name='comment' placeholder="Comment" cols="30" rows="5" required autoComplete='off' onChange={e => setComment(e.target.value)}></textarea>
                <div className="check">
                    <input type="checkbox" id='chk' checked={save} onChange={e => setSave(e.target.value)} />
                    <label htmlFor="chk"> Save my email and name for the next time I comment</label>
                </div>
                {(success === true) ?
                    (<Alert variant='success'>Comment posted Successfully. Thank you!</Alert>) 
                    :
                    ((success === false)?(<Alert variant='danger'>Sorry, posting error, retry</Alert>)
                    :
                    (''))
                }
                <button disabled={loading}>{loading ? 'Loading...' : 'Post Comment'}</button>
            </form>

            <div>
                {
                    commentList?.map(comm => (
                        <div className='content' key={comm.id}>
                            <strong>
                                <PersonIcon/> 
                                {comm.name}{' '} on{' '}
                                {moment(comment.createdAt).format('MMM DD, YYYY')}
                            </strong>
                            <p className='bg-white p-2 text-dark border border-primary border-2 rounded-pill'>{comm.comment}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default DTComments