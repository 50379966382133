import React, { useEffect, useState } from 'react'
import './BlogPage.css'
import PostCard from './PostCard';
import PostWidget from './PostWidget';
import Categories from './Categories';
import Header from './Header';
import CatHeader from './CatHeader';
import { getCategories, getPosts} from './../../API/BlogStore'
import FeaturedPosts from './FeaturedPosts';
import Pagination from './Pagination';
import { useLocation } from 'react-router-dom';

const BlogPage = (props) => {
  useEffect(() => {
    document.title = "Get Informed Blog"
  }, [])

  const { search } = useLocation();
  const page = Number(new URLSearchParams(search).get('page')) || 1;

  const [Posts, setPosts] = useState([]);
  const [totalPage, setTotalPage ] = useState(1);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function receivedPosts() {
      const {edges,aggregate} = await getPosts(page)    
      setTotalPage(aggregate.count)
      setPosts(Array.isArray(edges)?edges:[]);
    }
    receivedPosts()
  }, [page])

  useEffect(() => {
    getCategories()
      .then((newCategories) => {
        setCategories(newCategories);
      });
  }, []);


  return (
    <>

      <main >
        <Header />
        <CatHeader categories={categories} />
        <FeaturedPosts />
        <div className="container">
          <div className='blog__contentLeft'>
            {Posts.map((post, index) => (
              <PostCard post={post.node} key={index} />
            ))}
          <br/>
<a href="https://bit.ly/superjcybsonppl" className='d-flex justify-content-center'>
<img src="http://cashsuperstar.com/ppl/images/makemoneynow728x90.jpg" style={{width:'80vw'}} alt="Want To Increase Your Online Income? Then Get This System..."/>
</a>
<br/>
          </div>

          <div className="blog__contentRight">
            <div className="relative">
              <PostWidget/>
              <Categories/>
            </div>
          </div>
        </div>
        <Pagination setPosts={setPosts} totalPage={totalPage}/>
      </main>
    </>
  )
}

export default BlogPage