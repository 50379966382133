import React from 'react'
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Helmet } from 'react-helmet-async';

const DTPost = ({ post }) => {
  // console.log('4rm DTPost',post)
  return (
    <article>
          <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.description} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="og:image" content={post.coverPhoto.url} />
        <meta property="og:url" content={`https://superjcybs.com/dunamistravels/article/${post.slug}`} />
        <link rel='canonical' href={`https://superjcybs.com/dunamistravels/article/${post.slug}`} />

      </Helmet>
      <h2 className='title'>{post.title}</h2>
      <div className="dtpublished-time">
        <ScheduleIcon/>
        <span>{new Date(post.updatedAt).toDateString()}</span>
      </div>
      <div className="content" dangerouslySetInnerHTML={{ __html: post.content.html }} />
    </article>
  )
}

export default DTPost