import React from 'react'
import moment from 'moment'
import Image from 'react-bootstrap/Image'
import './PostCard.css'
import { Link } from 'react-router-dom'

const PostCard = ({ post }) => {

  // console.log('fromPostCard', post);
  return (
    <div id="postcard" >
      <Link to={`/blog/post/${post.slug}`}>
      <div className="post__featuredImage">
      <img src={post.featuredImage.url} alt={post.title} className="post__image" />
      </div>
        <h2 className="post__title">
          {post.title}
        </h2>
      </Link>
       <div className='post__extras'>
         <div className='post__author'>
          
         <Image
          unoptimized='true' 
          // loader={graphCMSImageLoader}
          alt={post.author.name}
          height="30px"
          width="30px"
          style={{ borderRadius: '50%', verticalAlign: 'middle' }}
          src={post.author.photo.url}
          />
          <p>{post.author.name}</p>
        </div> 
      <div className='post__timestamp'>
        <svg xmlns="http://www.w3.org/2000/svg" className="calenderIcon h-6 w-6 inline mr-2 text-pink-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
        <span style={{ verticalAlign: 'middle' }}>{moment(post.createdAt).format('MMM DD, YYYY')}</span>
      </div>
      </div>
      
      {/* <SocialShare url={post.slug} title={post.title} appId='686871576266039'/> */}
        <Link to={`/blog/post/${post.slug}`}>
      <p className="post__excerpt"> {post.excerpt} </p>
      </Link>
      <div className="text-center ">
        <Link to={`/blog/post/${post.slug}`}>
          <span className='post__readmore'>Continue Reading...</span>
        </Link>
      </div>
    </div>
  )
}

export default PostCard