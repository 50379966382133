import React, { useState } from 'react'
import './Features.css'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Header from '../components/common/Header';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ImageViewer } from "react-image-viewer-dv"
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// import FreeCodingFinal from '../Featured/FreeCodingFinal';

const Features = () => {

  // const [freeCF, setFreeCF] = useState(false);
  // console.log(freeCF);

  // const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <>
      <Helmet>
        <title>Features - SUPERJCYBS</title>
        <meta name="description" content='Checkout a handful of some works done in times past' />
        <meta property="og:title" content='Features - SUPERJCYBS' />
        <meta property="og:description" content='Checkout a handful of some works done in times past' />
        <meta property="og:url" content='https://superjcybs.com/features' />
        <meta name="twitter:title" content='Features - SUPERJCYBS' />
        <meta name="twitter:description" content='Checkout a handful of some works done in times past' />
      </Helmet>
      <Header />
      <div className="featured" id='#'>
        <div className="featured__title">
          <h2>FEATURED RESOURCES</h2>
        </div>
        <br/>
<a href="https://bit.ly/superjcybsonppl" className='d-flex justify-content-center'>
<img src="http://cashsuperstar.com/ppl/images/makemoneynow728x90.jpg" style={{width:'80vw'}} alt="Want To Increase Your Online Income? Then Get This System..."/>
</a>
<br/>
        <br />
        <ButtonToolbar className="justify-content-center" aria-label="Toolbar with Button groups">
          <ButtonGroup aria-label="First group">
            <Button variant="secondary" href='#FreeCoding'>Free Coding Lessons</Button>{' '}
            <Button variant="secondary" href='#FreeBusiness'>Free Business Mentorship</Button>{' '}
            <Button variant="secondary" href='#GeneralComputing'>General Computing</Button>{' '}
            <Button variant="secondary" href='#GomoaMusicAward'>Gomoa Music Awards 2022</Button>{' '}
            <Button variant="secondary" href='#tsmarriageoutline'>TS Marriage Outline</Button>{' '}
            <Button variant="secondary" href='#CryptoMining'>Crypto Mining Tutorials</Button>{' '}
          </ButtonGroup>
        </ButtonToolbar>
        <br />
        <>
          {/* {values.map((v, idx) => (
          <Button key={idx} className="me-2 mb-2" onClick={() => handleShow(v)}>
            Full screen
            {typeof v === 'string' && `below ${v.split('-')[0]}`}
          </Button>
        ))} */}
          <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Fully Functional Business Website</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe src="https://freecodingwithsuperjcybs.netlify.app/#about" frameBorder="0" title='final Work' width="1320" height="1000"></iframe>
            </Modal.Body>
          </Modal>
        </>
        <video controls src="./Assets/videos/vid.mp4" type="video/mp4" />


        <div id='tsmarriageoutline' style={{ minHeight: '100vh', margin: 'auto', padding: '1rem' }} className='text-center'>
          <h2 className='sec__head'>The Supernaturals Marriage</h2>
          <Card>
            <h3>Appreciation</h3>
            <Col>
              <Link to='/'>
                <img src='./Assets/images/features/wedding/appreciation1.png' alt='TS Marriage invitation' />
              </Link>
            </Col>
          </Card>
          <br />
          <h2>YOU ARE INVITED!</h2><br />

          <Link to='/'>
            <img src='./Assets/images/features/wedding/Invitation2.png' alt='TS Marriage invitation' />
          </Link>
          <Link to='/'>
            <img src='./Assets/images/features/wedding/mainInvitation.png' alt='TS Marriage invitation' />
          </Link>
        </div>

        <br /><h2 className='text-center'>PROGRAMME OUTLINE</h2><br />

        <Row xs={1} md={3} lg={4} className="g-4 px-3">
          <Col>
            <ImageViewer>
              <Card border='primary' bg="dark" text='white'>
                <Card.Img variant="top" src='./Assets/images/features/wedding/p1.png' alt="Intro" />
                <Card.Footer>
                  <small className="text-light">Preface</small>
                </Card.Footer>
              </Card>
            </ImageViewer>
          </Col>

          <Col>
            <ImageViewer>
              <Card className="bg-success text-white" border='success'>
                <Card.Img src='./Assets/images/features/wedding/p2.png' alt="Order-Of-Activity" />
                <Card.Footer>
                  <small className="text-light">Order Of Activites</small>
                </Card.Footer>
              </Card>
            </ImageViewer>
          </Col>
          <Col>
            <ImageViewer>
              <Card className="bg-danger text-white border-danger" >
                <Card.Img src='./Assets/images/features/wedding/p3.png' alt="Order-Of-Photography" />
                <Card.Footer>
                  <small className="text-light">Order Of Photography</small>
                </Card.Footer>
              </Card>
            </ImageViewer>
          </Col>
          <Col>
            <ImageViewer>
              <Card className="bg-info text-white border-info">
                <Card.Img src='./Assets/images/features/wedding/p4.png' alt="Appreciation" />
                <Card.Footer>
                  <small className="text-light">Appreciation</small>
                </Card.Footer>
              </Card>
            </ImageViewer>
          </Col>
        </Row>
      </div>

      <hr />
      <div id='GomoaMusicAward' style={{ minHeight: '100vh', margin: 'auto', padding: '2rem' }} className='text-center sepads'>
        <h2 className='sec__head'>Gomoa Music Awards 2022 Nominations is CLOSED</h2>
        <a href='https://youtube.com/@superjcybs' target='blank'>
          <img className='mb-1' src='./Assets/images/features/goma/GOMA Nominees.jpg' alt='gma2022-flyer' />
        </a>
        <a href='https://youtube.com/shorts/EmGgCgxr4CA?si=pYbeJEGSteulCmkl' target='blank'>
          <img src='./Assets/images/works/fix-it-clinic.png' alt='fixitclinic-nkanfoa' />
        </a>
      </div>
      <hr />
      <div id='namingCeremony' style={{ minHeight: '100vh', margin: 'auto', padding: '2rem' }} className='text-center sepads'>
        <h2 className='sec__head'>Naming Ceremony</h2>
          <img src='./Assets/images/features/naming/joysnaming.png' alt='gma2022-flyer' />
      </div>
<hr/>

      <div id='FreeCoding' style={{ minHeight: '100vh', padding: '2rem' }} className='text-center'>
        <h2 className='sec__head'>Welcome to FREE CODING LESSONS section</h2>
        <button className='mb-5 btn btn-primary' onClick={() => handleShow(true)}> Click on me to check out the website we will be building
        </button>
        <Accordion defaultActiveKey="1" flush>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Over Protocol</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://youtu.be/kmCv9vxCkIs?si=PgODyg2zcEm9h1ez" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>VT Network</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://youtu.be/0R3qdYXtMlU?si=nZYBeIyzEN2ygaiY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>OPENEX ($OEX) Token (satoshi)</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://youtu.be/BID5PiUlHmc?si=SSaHG-SenWyrRNw2" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>OGC Token</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://youtu.be/-1sQClFr2wk?si=iaf18-myABepFn70" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Zeusnet $ZNT Token</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://youtu.be/KVFLw4eIQZY?si=HEkoNE8Svi1Nk6UD" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>Good Dollar ($G) Token</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://youtu.be/R3sktaPCMrQ?si=rpJWQGn33Mncdhyz" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>Bitbama ($BAMA)</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://youtu.be/-NZSOgnpR6o?si=v99259n5iRVurT8x" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <hr />
      <div id='CryptoMining' style={{ minHeight: '100vh', padding: '2rem' }} className='text-center'>
        <h2 className='sec__head'>MINE CRYPTO TOKENS</h2>
        <button className='mb-5 btn btn-primary' onClick={() => handleShow(true)}> Click on me to check out the website we will be building
        </button>
        <Accordion defaultActiveKey="1" flush>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Intro</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/m5IdA9ntRWY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Lesson 1 - Free Coding Lessons</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/KtdrTLc-NNw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Lesson 2 - Free Coding Lessons</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/E-bv327JmSI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Lesson 3 - Free Coding Lessons</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/VSspgoclnx4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Lesson 4 - Free Coding Lessons</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/4JP66Sd-D-Q" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>Lesson 5 - Free Coding Lessons</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/ZkIAWVvAnrc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>Lesson 6 - Free Coding Lessons</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Sm1X3nzsFIA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <hr />

      <div id='FreeBusiness' style={{ minHeight: '100vh', padding: '2rem' }} className='text-center'>
        <h2 className='sec__head'>Welcome to FREE BUSINESS MENTORSHIP section</h2>
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Lesson 1 - Free Business Mentorship</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/ER_mRrscBxc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Lesson 2 - Free Business Mentorship</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Gxt1_Izg0EQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Lesson 3 - Free Business Mentorship</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/jv-5DD2Ngws" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Lesson 4 - Free Business Mentorship</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/1TGkClTEhlM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Lesson 5 - Free Business Mentorship</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/LPqk0Lwvvzo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Lesson 6 - Free Business Mentorship</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/IANybjad0Go" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <hr />

      <div id='GeneralComputing' style={{ minHeight: '100vh', padding: '2rem' }} className='text-center'>
        <h2 className='sec__head'>Computing Tutorials</h2>

        <Accordion defaultActiveKey="1" flush>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Creating Duplicates of images in PDF</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Getting FREE crypto from Binanace</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/KtdrTLc-NNw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>AI Advertise Tutorial - Robot Reading</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/E-bv327JmSI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Sell Airtime and Data Bundle to ALL NETWORKS</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/VSspgoclnx4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Creating a Free Website with BLOGGER 1</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/4JP66Sd-D-Q" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>Creating a Free Website with BLOGGER 2</Accordion.Header>
            <Accordion.Body>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/2Di2qjfqmgE?si=dVXNbQJrMIBqOKa6" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>Best App to record your phone screen</Accordion.Header>
            <Accordion.Body>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/f9UmA4LehaA?si=DeS_srqsesQZoH99" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>Easy steps to launch 6 microsoft office products</Accordion.Header>
            <Accordion.Body>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/BGQ32I-zIzw?si=9DuU2yXbocoLVprJ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>How to delete telegram account permanently</Accordion.Header>
            <Accordion.Body>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/PpYouF_v17A?si=ixVeYWyv0_Fdo1fF" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <hr />
      <div id='GeneralComputing' style={{ minHeight: '100vh', padding: '2rem' }} className='text-center'>
        <h2 className='sec__head'>Other Feed from SUPERJCYBS YOUTUBE CHANNEL</h2>

        <Accordion defaultActiveKey="1" flush>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Creat</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <hr />

      <div id='GeneralComputing' style={{ minHeight: '100vh', padding: '2rem' }} className='text-center'>
        <h2 className='sec__head'>Supernatural Life Sanctuary Inc.</h2>

        <Accordion defaultActiveKey="1" flush>

          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-22</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-21</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-20</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-19</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-18</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-17</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-16</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-15</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-15</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-14</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-13</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-12</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-11</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-10</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-9</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-8</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>WORDIFY-2024-DAY-7</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>WORDIFY-2024-DAY-6</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/KtdrTLc-NNw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>WORDIFY-2024-DAY-5</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/E-bv327JmSI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>WORDIFY-2024-DAY-4</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/VSspgoclnx4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>WORDIFY-2024-DAY-3</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/4JP66Sd-D-Q" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>WORDIFY-2024-DAY-2</Accordion.Header>
            <Accordion.Body>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/2Di2qjfqmgE?si=dVXNbQJrMIBqOKa6" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>WORDIFY-2024-DAY-1</Accordion.Header>
            <Accordion.Body>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/f9UmA4LehaA?si=DeS_srqsesQZoH99" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>Supernatural Moments Episode 2</Accordion.Header>
            <Accordion.Body>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/BGQ32I-zIzw?si=9DuU2yXbocoLVprJ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>Supernatural Moments Episode 1</Accordion.Header>
            <Accordion.Body>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/PpYouF_v17A?si=ixVeYWyv0_Fdo1fF" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>Trailer - Time with The Supernatural</Accordion.Header>
            <Accordion.Body>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/PpYouF_v17A?si=ixVeYWyv0_Fdo1fF" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <hr />

      <div id='GeneralComputing' style={{ minHeight: '100vh', padding: '2rem' }} className='text-center'>
        <h2 className='sec__head'>Other feed from TSBF TV YOUTUBE CHANNEL</h2>

        <Accordion defaultActiveKey="1" flush>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Lets Learn Rightly with SUPERJCYBS - Episode 2</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Make Money Online with SUPERJCYBS - Episode 3</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>5 Types Of Team Members</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UU8GaNSSt_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Earn cryptocurrencies</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/KtdrTLc-NNw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>The untold secrets of wealth</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/E-bv327JmSI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Test Transmission</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/VSspgoclnx4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>TSBFTV social handles</Accordion.Header>
            <Accordion.Body>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/4JP66Sd-D-Q" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  )
}

export default Features


