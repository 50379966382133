import React from 'react'
import { QUERY_SEARCH_POSTS } from '../Graphql/Queries';
import Posts from '../components/DTPosts';
import UseQueryPosts from '../Hooks/UseQueryPosts';
import NotFound from '../components/DTNotFound';

const SearchResult = () => {
  const { posts, error, status } = UseQueryPosts({ query: QUERY_SEARCH_POSTS })

  if (status === 'sucess' && posts.length === 0) return <NotFound />
  if (error) return <h2 style={{ textAlign: 'center' }}>{error}</h2>

//   const { slug } = useParams();
//   const [posts, setPosts] = useState([]);

//   useEffect(() => {
//     graphcms.request(QUERY_SEARCH_POSTS, { slug })
//       .then(res => setPosts(res.posts))
//   }, [slug])
// console.log(posts)
  return (
    <>
      <Posts posts={posts} />
    </>
  )
}

export default SearchResult