import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';


const Search = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch("")
    let value = search.trim().replace(/\s+/g, "-").toLocaleLowerCase(); // remove all spaces 
    navigate(`/dunamistravels/search/${value}`) // URL to redirect to
    setSearch(value)
  }

  return (
    <form className='search' onSubmit={handleSearch}>
      <input type='search' placeholder='Search...' required value={search} onChange={e => setSearch(e.target.value)} />
      <button type='submit'>
        <SearchIcon className="fa fa-search" />
      </button>
    </form>
  )
}

export default Search