import React, { useEffect, useState } from 'react'
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Marquee from 'react-fast-marquee';
import '../../DunamisTravels.css'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Search from "./DTSearch.js"
import NavLinks from '../components/NavLinks.js';
import {QUERY_SLUG_CATEGORIES, graphcms } from '../Graphql/Queries';


const DTheader = () => {

    const [categories, setCategories] = useState([]);

    // const { posts, error, status } = UseQueryPosts({ query: QUERY_POSTS })
    // console.log('made', posts)
    useEffect(() => {
        graphcms.request(QUERY_SLUG_CATEGORIES)
            .then(res => setCategories(res.categories))
    }, [])


    // if(error)return <h2 style={{textAlign:'center'}}>{error}</h2>

    // const [posts, setPosts] = useState([]);

    //   useEffect(()=>{
    //     graphcms.request(QUERY_POSTS)
    //     .then(res=>setPosts(res.posts))
    // },[])

  return (
    <>
      <header className='justify-content-space-around' position="fixed">
          <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" >
              <Container>
                  <Navbar.Brand href="/dunamistravels">
                      <img src="/Assets/images/dunamistravels/dtlogo.png" alt='DT logo' className="rounded-pill logo" style={{ width: 40 }} />
                  </Navbar.Brand>
                  <Search />
                  <Navbar.Toggle aria-controls="offcanvasNavbar-expand-false" placement="end" />
                  <Navbar.Offcanvas id="offcanvasNavbar-expand-false" aria-labelledby="offcanvasNavbarLabel-expand-false" placement="end">
                      <Offcanvas.Header closeButton>
                          <Offcanvas.Title id="offcanvasNavbarLabel-expand-false">
                              DUNAMIS TRAVELS
                          </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                          <Nav className="me-auto" >
                              {/* <Nav.Link href= target="blank"> <MailOutlineIcon style={{ color: "#DB4437" }} /><span style={{color:'black'}}></span></Nav.Link> */}
                          </Nav>
                          <Nav>
                              <Nav.Link href="/">SUPERJCYBS</Nav.Link>
                                  <NavDropdown title="Services" id="serviceDrop" eventkey={3} >
                                  <NavDropdown.Item className='text-dark' href='#'>Passport Application</NavDropdown.Item>
                                  <NavDropdown.Item className='text-dark' href='#'>Ticket Booking</NavDropdown.Item>
                                  <NavDropdown.Item className='text-dark' href='#'>Hotel Booking</NavDropdown.Item>
                              </NavDropdown>
                              <Nav.Link eventkey={5} href="/blog">Blog</Nav.Link>
                              <Nav.Link eventkey={2} href="https://forms.gle/r4SavJ4R4p2DUc7H6">Advertise With Us</Nav.Link>
                              <Nav.Link eventkey={4} href="/dunamistravels/registration" target='blank' title='for GHS 250 ($20)'><Button style={{ padding: '2px 6px' }}>REGISTER</Button></Nav.Link>
                          </Nav>
                      </Offcanvas.Body>
                  </Navbar.Offcanvas>
              </Container>
          </Navbar>
          <Marquee
              gradient={false}
              pauseOnHover={true}
              style={{ Direction: "right", width: "100%", height: "50", scrollAmount: "1055", backgroundColor: "Red", color: '#fff', fontWeight: 'bold' }} >
              ---<AnnouncementIcon /> HOT DEALS: {'    =>'}  2024 is Your Year to Travel - DEALS AVAILABLE: USA, Australia (Hairdressers) --REGISTER NOW--
              ---ADVERTS-- For your Affordable Data Bundles, Online Payment Services, Website Development, Graphic Designs, Network & Affiliate Marketing, CryptoGraphy. Call SUPERJCYBS today https://superjcybs.com ---
          </Marquee>
      </header>
              {/* categories */ }
    <nav className="navbar navbar-expand-sm navbar-dark catNavLink">
        <NavLinks categories={categories} />
    </nav>
    </>
  )
}

export default DTheader