import React from 'react'
import About from '../components/Home/About'
import Contact from '../components/Home/Contact'
import { Helmet } from 'react-helmet-async';

const AboutUs = () => {

     
  return (
    <>
     <Helmet>
       <title>About SUPERJCYBS</title>
       <meta name="description" content='DESRead about us and get used to us, Lets get you sorted out'/>
        <meta property="og:title" content='About SUPERJCYBS' />
        <meta property="og:description" content='OGRead about us and get used to us, Lets get you sorted out' />
        <meta property="og:url" content='https://superjcybs.com/about-us' />
        <link rel='canonical' href='https://superjcybs.com/about-us' />
        <meta name="twitter:title" content='About SUPERJCYBS' />
        <meta name="twitter:description" content='TWITTERRead about us and get used to us, Lets get you sorted out'/>
     </Helmet>
      <About />
      <Contact/>
    </>
  )
}

export default AboutUs