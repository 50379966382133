import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from "react-router-dom";

function Header() {
    const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch("")
    let value = search.trim().replace(/\s+/g, "-").toLocaleLowerCase(); // remove all spaces 
    navigate(`/blog/search/${value}`) // URL to redirect to
    setSearch(value)
  }

  return (
    <div>
      <img src="Assets/images/logos/getinformed.jpg" alt="Get Informed Blog" style={{ width: '100%' }} />
      {/* NAV 1 */}
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/"><i><ChevronLeftIcon /></i>Homepage</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link href="https://p.hbtl.co/zrT7b2" target="blank">Give a Donation</Nav.Link>
              <Nav.Link href="/#contact" target="blank">Contact Us</Nav.Link>
              <NavDropdown title="Other Pages" id="navbarScrollingDropdown">
                <NavDropdown.Item href="https://supamart.superjcybs.com" target="blank">SUPAMART Ghana</NavDropdown.Item>
                <NavDropdown.Item href="https://choptime.superjcybs.com" target="blank">Choptime Food App</NavDropdown.Item>
                <NavDropdown.Item href="/tsbf-ghana/sector=dunamis-Travels" target="blank">Dunamis Travel</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/tsbf-ghana" target="blank">TSBF Ghana</NavDropdown.Item>
                <NavDropdown.Item href="https://tsbftv.superjcybs.com/" target="blank">TSBF TV</NavDropdown.Item>
                <NavDropdown.Item href="https://www.youtube.com/channel/UCnYL6e5pYht_mWKlEE7NTLw?sub_confirmation=1" target="blank">Youtube</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="https://globalnewstyme.blogspot.com" target="blank">Global News Tyme</Nav.Link>

            </Nav>
            <Form className="d-flex" onSubmit={handleSearch}>
              <Form.Control
                type="search"
                placeholder="Search our site"
                className="me-2"
                aria-label="Search"
                required value={search} 
                onChange={e => setSearch(e.target.value)}
              />
              <Button type='submit' variant="outline-success">Search</Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* NAV 2 */}

    </div>
  )
}

export default Header;