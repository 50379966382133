import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { graphcms } from '../Graphql/Queries';
import { useMyContext } from '../Context/store';

const UseQueryPosts = ({ query, limit = 8 }) => {
  const { slug } = useParams();
  const { search, pathname } = useLocation();

  const page = Number(new URLSearchParams(search).get('page')) || 1;
  const skip = (page - 1) * limit


  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('');

  const { setLoading, setTotalPage } = useMyContext();

  useEffect(() => {
    setTotalPage(1)
  }, [pathname, setTotalPage])


  useEffect(() => {
    setStatus('start')
    setLoading(true)
    graphcms.request(query, { slug, limit, skip })
      .then(res => {
        setStatus('success')
        const count = res?.countConnection?.aggregate?.count || 0;
        setTotalPage(Math.ceil(count / limit))
        setPosts(res.posts)
        setError(null)
      })
      .catch(error => {
        setStatus('error')
        setTotalPage(1)
        setError(error.message)
        setPosts([])
      })
      .finally(() => setLoading(false))
  }, [query, slug, limit, skip, setLoading, setTotalPage])

  return { posts, error, status }
}

export default UseQueryPosts