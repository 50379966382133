import React from 'react'
import { Link } from 'react-router-dom'
// import CoursePage from './CoursePage'
import './Courses.css'
import { Helmet } from 'react-helmet-async'

const Courses = () => {

    return (
        <>
            <Helmet>
                <title>Our Courses - CROWNED BRAINS IPS</title>
                <meta name="description" content="We provide you training like you've never because we believe in practical, can-do tutoring" />
                <meta property="og:title" content='Our Courses - CROWNED BRAINS IPS' />
                <meta property="og:description" content="We provide you training like you've never because we believe in practical, can-do tutoring" />
                <meta property="og:url" content='https://superjcybs.com/courses' />
                <meta name="twitter:title" content='Our Courses - CROWNED BRAINS IPS' />
                <meta name="twitter:description" content="We provide you training like you've never because we believe in practical, can-do tutoring" />
            </Helmet>
            <section className="courses">
                <h2>Our Courses</h2>

                <div className="container courses__container">
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course1.jpg" alt='HTML - Web Development' />
                        </div>
                        <div className="course__info">
                            <h4>HTML - Web Development</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC01</button>
                            <p>
                                We have crafted our HTML course for the complete beginner to understand and practice.
                            </p>
                            <Link to="/courses/CBC-01" className="btn btn-primary" >Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course2.jpg" alt='CSS - Web Development' />
                        </div>
                        <div className="course__info">
                            <h4>CSS - Web Development</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC02</button>
                            <p>
                                We have crafted our CSS course for the complete beginner to understand and practice.
                            </p>
                            <Link to="/courses/CBC-02" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course3.jpg" alt='JavaScript - Web Development' />
                        </div>
                        <div className="course__info">
                            <h4>JavaScript - Web Development</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC03</button>

                            <p>
                                We have crafted our JAVASCRIPT course for the complete beginner to understand and practice.
                                We take you through strings, arrays, functions, etc
                            </p>
                            <Link to="/courses/CBC-03" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course4.jpg" alt='BOOTSTRAP - Web Development' />
                        </div>
                        <div className="course__info">
                            <h4>BOOTSTRAP - Web Development</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC04</button>

                            <p>
                                With our touchful and complete course on Bootstrap, you will be able to Take
                                UI Designing to the next level in your career/development. Its Hot Cake.
                            </p>
                            <Link to="/courses/CBC-04" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course5.jpg" alt='TAILWIND - Web Development' />
                        </div>
                        <div className="course__info">
                            <h4>TAILWIND - Web Development</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC05</button>

                            <p>
                                Our Tailwind course is designed to put you our there with a custom way our stunning up the looks
                                of our web works.
                            </p>
                            <Link to="/courses/CBC-05" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>

                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course6.jpg" alt='MONGODB - Web Development' />
                        </div>
                        <div className="course__info">
                            <h4>MONGODB - Web Development</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC06</button>

                            <p>
                                Looking forward to go into Database Management, Here you are as our course on MongoDB is a one-stop point of call.
                            </p>
                            <Link to="/courses/CBC-06" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>

                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course7.jpg" alt='NODE JS - Web Development' />
                        </div>
                        <div className="course__info">
                            <h4>NODE JS - Web Development</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC07</button>

                            <p>
                                Lets get our hands on some backend technologies beginning with the mother of it all, Node JS
                            </p>
                            <Link to="/courses/CBC-07" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course8.jpg" alt='EXPRESS JS - Web Development' />
                        </div>
                        <div className="course__info">
                            <h4>EXPRESS JS - Web Development</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC08</button>

                            <p>
                                Interested in creating web servers on your own from scratch, sign up for this course today.
                            </p>
                            <Link to="/courses/CBC-08" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course9.jpg" alt='REACT JS - Web Development' />
                        </div>
                        <div className="course__info">
                            <h4>REACT JS - Web Development</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC09</button>

                            <p>
                                Learn the Best frontend application making waves in the world starting with Facebook. start Learning also
                            </p>
                            <Link to="/courses/CBC-09" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course10.jpg" alt='Web Designing with CMS (NO CODING)' />
                        </div>
                        <div className="course__info">
                            <h4>Web Designing with CMS (NO CODING)</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC10</button>

                            <p>
                                For those who are not interested in coding yet wants to develop websites, this is the precise course for you.
                            </p>
                            <Link to="/courses/CBC-10" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course11.jpg" alt='Mobile App Development' />
                        </div>
                        <div className="course__info">
                            <h4>CODING - Mobile App Development</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC11</button>

                            <p>
                                You can begin creating mobile applications after taking this course. Dont deny yourself.
                            </p>
                            <Link to="/courses/CBC-11" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course11.jpg" alt='Mobile App Development' />
                        </div>
                        <div className="course__info">
                            <h4>NO CODING - Mobile App Development</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC12</button>

                            <p>
                                You can begin creating mobile applications after taking this course. Dont deny yourself.
                            </p>
                            <Link to="/courses/CBC-11" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course11.jpg" alt='Mobile App Development' />
                        </div>
                        <div className="course__info">
                            <h4>STOCK MANAGEMENT</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC13</button>

                            <p>
                                You can begin creating mobile applications after taking this course. Dont deny yourself.
                            </p>
                            <Link to="/courses/CBC-11" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course11.jpg" alt='Mobile App Development' />
                        </div>
                        <div className="course__info">
                            <h4>SUPPLY CHAIN MANAGEMENT</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC14</button>

                            <p>
                                You can begin creating mobile applications after taking this course. Dont deny yourself.
                            </p>
                            <Link to="/courses/CBC-11" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course11.jpg" alt='Mobile App Development' />
                        </div>
                        <div className="course__info">
                            <h4>FRONT DESK/RECEPTIONIST</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC15</button>

                            <p>
                                You can begin creating mobile applications after taking this course. Dont deny yourself.
                            </p>
                            <Link to="/courses/CBC-11" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course11.jpg" alt='Mobile App Development' />
                        </div>
                        <div className="course__info">
                            <h4>LEADERSHIP MASTERCLASS</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC16</button>
                            <p>
                                You can begin creating mobile applications after taking this course. Dont deny yourself.
                            </p>
                            <Link to="/courses/CBC-11" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course11.jpg" alt='Mobile App Development' />
                        </div>
                        <div className="course__info">
                            <h4>PERSONAL GROWTH</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC17</button>
                            <p>
                                You can begin creating mobile applications after taking this course. Dont deny yourself.
                            </p>
                            <Link to="/courses/CBC-11" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course5.jpg" alt='Microsoft Word' />
                        </div>
                        <div className="course__info">
                            <h4>Microsoft Word</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC18</button>
                            <p>
                                Don't be a mugu at the office environment any longer, equip yourself rightly in Word, create every document you may need at the office.
                            </p>
                            <Link to="/courses/CBC-18" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course1.jpg" alt='Microsoft Excel' />
                        </div>
                        <div className="course__info">
                            <h4>Microsoft Excel</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC19</button>       
                            <p>
                                Don't be a mugu at the office environment any longer, equip yourself rightly in Excel.
                            </p>
                            <Link to="/courses/CBC-14" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course3.jpg" alt='Microsoft Powerpoint' />
                        </div>
                        <div className="course__info">
                            <h4>Microsoft Powerpoint</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC20</button>       
                            <p>
                                Don't be a mugu at the office environment any longer, equip yourself rightly in Powerpoint skill and begin the wowing business.
                            </p>
                            <Link to="/courses/CBC-16" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course2.jpg" alt='Microsoft Access' />
                        </div>
                        <div className="course__info">
                            <h4>Microsoft Access</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC21</button>       
                            <p>
                                Don't be a mugu at the office environment any longer, equip yourself rightly in Microsoft Access.
                            </p>
                            <Link to="/courses/CBC-15" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course4.jpg" alt="Microsoft Outlook" />
                        </div>
                        <div className="course__info">
                            <h4>Microsoft Outlook</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC22</button>       
                            <p>
                                Need to do some emailing and don't know where to start from, start here
                            </p>
                            <Link to="/courses/CBC-17" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course12.jpg" alt='Digital Marketing' />
                        </div>
                        <div className="course__info">
                            <h4>SOCIAL MEDIA - Digital Marketing</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC23</button>       
                            <p>
                                Getting followers, sending messages to win clients, Lets take you on a journey to champion the Digital Marketing sector.
                            </p>
                            <Link to="/courses/CBC-12" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course12.jpg" alt='Digital Marketing' />
                        </div>
                        <div className="course__info">
                            <h4>SMS - Digital Marketing</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC24</button>       
                            <p>
                                Getting followers, sending messages to win clients, Lets take you on a journey to champion the Digital Marketing sector.
                            </p>
                            <Link to="/courses/CBC-12" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course12.jpg" alt='Digital Marketing' />
                        </div>
                        <div className="course__info">
                            <h4>AFFILIATE MARKETING - Digital Marketing</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC25</button>       
                            <p>
                                Getting followers, sending messages to win clients, Lets take you on a journey to champion the Digital Marketing sector.
                            </p>
                            <Link to="/courses/CBC-12" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course12.jpg" alt='Digital Marketing' />
                        </div>
                        <div className="course__info">
                            <h4>NETWORK MARKETING - Digital Marketing</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC26</button>       
                            <p>
                                Getting followers, sending messages to win clients, Lets take you on a journey to champion the Digital Marketing sector.
                            </p>
                            <Link to="/courses/CBC-12" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course12.jpg" alt='Digital Marketing' />
                        </div>
                        <div className="course__info">
                            <h4>SOCIAL BOOSTING - Digital Marketing</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC27</button>       
                            <p>
                                Getting followers, sending messages to win clients, Lets take you on a journey to champion the Digital Marketing sector.
                            </p>
                            <Link to="/courses/CBC-12" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course7.jpg" alt="Computer Essentials" />
                        </div>
                        <div className="course__info">
                            <h4>Computer Essentials</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC28</button>       
                            <p>
                                Are you been chuckled because of your illetracy in computing, Start learning here and become good at computing.
                            </p>
                            <Link to="/courses/CBC-20" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/onlinepay.png" alt="Online Payment" />
                        </div>
                        <div className="course__info">
                            <h4>Online Payment</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC29</button>       
                            <p>
                                Need to make some payments to another country or online but dont know how. We are right here for you.
                            </p>
                            <Link to="/courses/CBC-20" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course13.jpg" alt='Graphic Designing' />
                        </div>
                        <div className="course__info">
                            <h4>PHOTOSHOP - Graphic Designing</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC30</button>       
                            <p>
                                To begin creating stunning designs for all occations, join our Graphics Designing course today
                            </p>
                            <Link to="/courses/CBC-13" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course13.jpg" alt='Graphic Designing' />
                        </div>
                        <div className="course__info">
                            <h4>ILLUSTRATOR - Graphic Designing</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC31</button>       
                            <p>
                                To begin creating stunning designs for all occations, join our Graphics Designing course today
                            </p>
                            <Link to="/courses/CBC-13" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/designonphone.jpg" alt='Graphic Designing-superjcybs' />
                        </div>
                        <div className="course__info">
                            <h4>PHONE - Graphic Designing</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC32</button>       
                            <p>
                                To begin creating stunning designs for all occations, join our Graphics Designing course today
                            </p>
                            <Link to="/courses/CBC-13" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course8.jpg" alt='Video Editing' />
                        </div>
                        <div className="course__info">
                            <h4>Video Editing</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC33</button>       
                            <p>
                                Begin a career on your own starting with this course on video editing.
                            </p>
                            <Link to="/courses/CBC-21" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course8.jpg" alt='Audio Editing' />
                        </div>
                        <div className="course__info">
                            <h4>Audio Editing</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC34</button>       
                            <p>
                                Begin a career on your own starting with this course on video editing.
                            </p>
                            <Link to="/courses/CBC-21" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course9.jpg" alt='Text, 2D & 3D Animation' />
                        </div>
                        <div className="course__info">
                            <h4>Text, 2D & 3D Animations</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC35</button>       
                            <p>
                                Lets get you a career with Animation.
                            </p>
                            <Link to="/courses/CBC-22" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course9.jpg" alt='Product Customisation' />
                        </div>
                        <div className="course__info">
                            <h4>Product Customisation</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC36</button>       
                            <p>
                                Lets get you a career with Product Customisation.
                            </p>
                            <Link to="/courses/CBC-22" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course6.jpg" alt='Importation Business' />
                        </div>
                        <div className="course__info">
                            <h4>Importation Business</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC38</button>       
                            <p>
                               Start a good business and start making your own business in importation.
                            </p>
                            <Link to="/courses/CBC-19" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/course6.jpg" alt='Exportation Business' />
                        </div>
                        <div className="course__info">
                            <h4>Exportation Business</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC39</button>       
                            <p>
                               Start a good business and start making your own business in importation.
                            </p>
                            <Link to="/courses/CBC-19" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/freeBusiness.jpg" alt="Free Business Mentorship" />
                        </div>
                        <div className="course__info">
                            <h4>FREE BUSINESS MENTORSHIP</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC40</button>       
                            <p>
                                Wants to do business, start here
                            </p>
                            <Link to="/courses/CBC-23" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/data1.png" alt="Data Bundle 1" />
                        </div>
                        <div className="course__info">
                            <h4>DATA BUNDLE 1</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC41</button>       
                            <p>
                                Wants to do business, start here with our Data Bundle 1. You will get MTN & AIRTELTIGO
                            </p>
                            <Link to="/courses/CBC-23" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/data2.png" alt="Data Bundle 1" />
                        </div>
                        <div className="course__info">
                            <h4>DATA BUNDLE 2</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC42</button>       
                            <p>
                                Wants to do business, start here with our Data Bundle 1. You will get cheap MTN data bundle plans
                            </p>
                            <Link to="/courses/CBC-23" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/jumiaforce.png" alt="Data Bundle 1-superjcybs" />
                        </div>
                        <div className="course__info">
                            <h4>JUMIA AGENT</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC43</button>       
                            <p>
                                Wants to do business, Become a Jumia Agent
                            </p>
                            <Link to="/courses/CBC-23" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/freetv.png" alt="Premium DSTV-superjcybs" />
                        </div>
                        <div className="course__info">
                            <h4>PREMIUM DSTV</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC45</button>       
                            <p>
                                Get over 17,000 channels right on your phone for a Lifetime
                            </p>
                            <Link to="/courses/CBC-23" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/freeCoding.png" alt='Cryptography' />
                        </div>
                        <div className="course__info">
                            <h4>CRYPTOGRAPHY</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC46</button>       
                            <p>
                               Learn coding for free. We are dashing you this.
                            </p>
                            <Link to="/courses/CBC-24" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/freeCoding.png" alt='Free Coding Lessons' />
                        </div>
                        <div className="course__info">
                            <h4>FREE CODING LESSONS</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC47</button>       
                            <p>
                               Learn coding for free. We are dashing you this.
                            </p>
                            <Link to="/courses/CBC-24" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/virtualcard.png" alt='Free virtual card-superjcybs' />
                        </div>
                        <div className="course__info">
                            <h4>FREE VIRTUAL CARD</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC48</button>       
                            <p>
                               Learn coding for free. We are dashing you this.
                            </p>
                            <Link to="/courses/CBC-24" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                    <article className="course">
                        <div className="course__image">
                            <img src="Assets/images/courses/evdforall.png" alt='EVD to all network-superjcybs' />
                        </div>
                        <div className="course__info">
                            <h4>FREE EVD TO ALL NETWORK</h4>
                            <button className='warning' style={{backgroundColor:'white', fontWeight:'bold'}} disabled>CBC49</button>       
                            <p>
                               Learn coding for free. We are dashing you this.
                            </p>
                            <Link to="/courses/CBC-24" className="btn btn-primary">Learn More</Link>
                        </div>
                    </article>
                </div>
            </section>
        </>
    )
}

export default Courses