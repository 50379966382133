import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './CVjerry.css'
import { Helmet } from 'react-helmet-async';

// import './supportFiles/js/main'
// import './supportFiles/js/particles.json'
// import './supportFiles/js/particles2.json'
// import './supportFiles/js/jquery.min'
// import './supportFiles/js/modernizr'


const CVjerry = () => {
  
  // window.onload, function () {

  //     /*======== Window Load Function ========*/
  //     /*======== Isotope Setup ========*/
  //     if ($('.portfolio-items').length) {
  //         var $elements = $(".portfolio-items"),
  //             $filters = $('.portfolio-filter ul li');
  //         $elements.isotope();
  //         $filters.on('click', function () {
  //             $filters.removeClass('active');
  //             $(this).addClass('active');
  //             var selector = $(this).data('filter');
  //             $(".portfolio-items").isotope({
  //                 filter: selector,
  //             });
  //         });
  //     }
  // };

  // document.querySelector(document).ready(function () {
  //   document.querySelector('.pages-stack .page').appendChild(function () {
      
  //   })


    /*======== SimpleBar Setup ========*/
    // ('.pages-stack .page').each(function () {
    //   var $id = '#' + $(this).attr('id');
    //   new SimpleBar($($id)[0], {
    //     scrollbarMinSize: 15
    //   });
    // });
  // });
  // $(window).on('load', function () {

  //   /*======== Preloader Setup ========*/
  //   document.querySelector(".loading-text").delay(1000).fadeOut("slow");
  //   document.querySelector(".preloader").delay(2000).fadeOut("slow");
  // })

  // document.ready(function () {
  //   if (document.querySelector('.text-slideshow').length) {
  //     // animateText()
  //   }
  // })

  useEffect(() => {
    function run() {
      const startText =  document.getElementsByClassName(".loading-text")
      setTimeout(startText,1000)
      const starter = document.querySelector(".preloader")
      starter.classList.add=".fadeOut"
      setTimeout(() => {
        starter.style.display="none"
      }, 3000);
    }
    run()
  }, [])
  

  const showMenu = () => {
   const menuClicked =  document.querySelector('.nav-menu')
    menuClicked.classList.add('.nav-menu.nav-menu--open')
}

  return (
    <>
      <Helmet>
        <title>CV - Jerry Babatunde</title>
        <meta name="description" content='Get intouch with me, lets deal on a professional level' />
        <meta property="og:title" content="CV - Jerry Babatunde" />
        <meta property="og:description" content='Get intouch with me, lets deal on a professional level' />
        <meta property="og:url" content='https://superjcybs.com/cv=jerry-babatunde (Jerry Kwophi Supernatural)' />
      </Helmet>

      {/*Preloader Start*/}
      <div className="preloader">
        <div className="loading-text">
          <span className="loading-text-words" data-preloader="L">L</span>
          <span className="loading-text-words" data-preloader="O">O</span>
          <span className="loading-text-words" data-preloader="A">A</span>
          <span className="loading-text-words" data-preloader="D">D</span>
          <span className="loading-text-words" data-preloader="I">I</span>
          <span className="loading-text-words" data-preloader="N">N</span>
          <span className="loading-text-words" data-preloader="G">G</span>
        </div>
      </div>
      {/*Preloader End*/}

      {/* Header Start */}
      <header>
        {/* logo */}
        <Link to="/cv=jerry-babatunde (Jerry Kwophi Supernatural)" className="cv__logo"><span>JB</span></Link>

        {/* navigation button */}
        <div className="menu-button" onClick={showMenu}>
          <span />
          <span />
          <span />
        </div>

        {/* navigation menu */}
        <nav className="nav-menu">
          <div className="nav-menu--links">
            <Link className="nav-menu--link link--active" to="#home"><i className="fas fa-home" /><span>Home</span></Link>
            <Link className="nav-menu--link" to="#about"><i className="fas fa-user" /><span>About</span></Link>
            <Link className="nav-menu--link" to="#resume"><i className="fas fa-address-card" /><span>Resume</span></Link>
            <Link className="nav-menu--link" to="#portfolio"><i className="fas fa-briefcase" /><span>Portfolio</span></Link>
            <Link className="nav-menu--link" to="#blog"><i className="fas fa-book" /><span>Blog</span></Link>
            <Link className="nav-menu--link" to="#contact"><i className="fas fa-envelope" /><span>Contact</span></Link>
          </div>
          {/* social links */}
          <div className="social-links">
            <a className="social-link" href="https://www.twitter.com/superjcybs">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="social-link" href="https://www.facebook.com/kofisupernatural">
              <i className="fab fa-facebook-f" />
            </a>
            <a className="social-link" href="https://www.linkedin.com/in/jerry-babatunde-81a26bb5/">
              <i className="fab fa-linkedin-in" />
            </a>
            <a className="social-link" href="https://www.youtube.com/channel/UCnYL6e5pYht_mWKlEE7NTLw?sub_confirmation=1">
              <i className="fab fa-youtube" />
            </a>
            <a class="social-link" href="https://github.com/superjcybs">
              <i class="fab fa-github"></i>
            </a>
          </div>
          {/* copyright text */}
          <div className="copy">
            <p>Copyright © SUPER 'J' CY-BUSINESS AND SERVICE (2021), All rights Reserved.</p>
          </div>
        </nav>
      </header>
      {/* Header End */}

      {/* Pages Stack Start */}
      <div id="main" className="pages-stack">

        {/*Banner Page Start*/}
        <section id="home" className="page section-home" style={{ backgroundImage: 'url("Assets/img/background/bg-img.jpg")' }}>
          <div className="banner-text">
            <div className="profile-img">
              <img src="Assets/img/profile-img.jpg" alt="personimage" />
            </div>
            <h4>JERRY BABATUNDE</h4>
            <div className="text-slideshow" data-effect="fx3">
              <div className="text-slide text-slide--current"><h2 className="animate-title">Administrator/Document Controller</h2></div>
              <div className="text-slide"><h2 className="animate-title">Full Stack MERN Web Developer</h2></div>
              <div className="text-slide"><h2 className="animate-title">Graphics Designer</h2></div>
              <div className="text-slide"><h2 className="animate-title">Wordpress Developer</h2></div>
              <div className="text-slide"><h2 className="animate-title">Human Resource Manager</h2></div>
              <div className="text-slide"><h2 className="animate-title">Digital Marketer</h2></div>
              <div className="text-slide"><h2 className="animate-title">Micosoft Instructor</h2></div>
              <div className="text-slide"><h2 className="animate-title">Supply Chain Management</h2></div>
            </div>
          </div>
        </section>
        {/*Banner Page End*/}










        {/*About Page Start*/}
        <section id="about" className="page section-about">
          <div className="container">
            <div className="page-heading">
              <h2 data-shadow="About">About</h2>
            </div>
            {/*About Info Section Start*/}
            <div className="row about-info mb-60">
              <div className="col-lg-5">
                {/* about image */}
                <div className="about-img">
                  <img src="Assets/img/about-img.jpg" alt="imag-1" />
                </div>
              </div>
              <div className="col-lg-7">
                <h3 className="mb-20">Web Designer &amp; Developer</h3>
                <p>Hello! I'm <strong>Jerry Babatunde</strong>. Web Designer with over 2 years of experience. Graphic Designer with over 2 years of experience. Microsoft Products trainer with over 6 years knoowledge including advanced <strong>Ms Word, Ms Excel, Ms Powerpoint, Outlook, Teams. I also have advance knowledge concerning Google products</strong>. I aim to make a difference through my creative solution.</p>
                <div className="row mb-20">
                  <div className="col-sm-6">
                    {/* about list */}
                    <ul className="about-list">
                      <li> <span className="title">Name</span> <span className="value">Jerry Babatunde</span> </li>
                      <li> <span className="title">Age</span> <span className="value">29 Years</span> </li>
                      <li> <span className="title">Email</span> <span className="value">jerrybabatunde1@gmail.com</span> </li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    {/* about list */}
                    <ul className="about-list">
                      <li> <span className="title">Phone</span> <span className="value">+974 6693 0807</span> </li>
                      <li> <span className="title">Address</span> <span className="value">Al Mansoura, Qatar</span> </li>
                      <li> <span className="title">Freelance</span> <span className="value">Available</span> </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {/* resume button */}
                    <div className="resume-button">
                      <a className="main-btn" href="download">Download CV</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*About Info Section End*/}


















            
            {/*Services Section Start*/}
            <div className="row services mb-20">
              <div className="col-md-12">
                <div className="sub-heading">
                  <h3>Services</h3>
                </div>
              </div>
              {/*service item*/}
              <div className="col-lg-3 col-sm-6">
                <div className="service-item">
                  <i className="icon-desktop" />
                  <h4 className="title">Web Development</h4>
                  <p>Reach out to me for your website works; from scratch or modifications</p>
                </div>
              </div>
              {/*service item*/}
              <div className="col-lg-3 col-sm-6">
                <div className="service-item">
                  <i className="icon-tools" />
                  <h4 className="title">Digital Marketer</h4>
                  <p>Lets boost your online presence and make your product sell.</p>
                </div>
              </div>
              {/*service item*/}
              <div className="col-lg-3 col-sm-6">
                <div className="service-item">
                  <i className="icon-paintbrush" />
                  <h4 className="title">Graphics Design</h4>
                  <p>I will take care of your graphics needs to show your brand and display class whereever you show up</p>
                </div>
              </div>
              {/*service item*/}
              <div className="col-lg-3 col-sm-6">
                <div className="service-item">
                  <i className="icon-lightbulb" />
                  <h4 className="title">Support</h4>
                  <p>I am always available to offer support to those who have questions or need some tit-bits to do things from Business Management (Start-ups or Growth), Online Activities, Digital, Affiliate as well as Products Marketing. </p>
                </div>
              </div>
            </div>
            {/*Services Section End*/}
            {/*Testimonials Section Start*/}
            <div className="row testimonials mb-60">
              <div className="col-md-12">
                <div className="sub-heading">
                  <h3>Testimonials</h3>
                </div>
              </div>
              {/*testimonials list*/}
              <div className="owl-carousel owl-theme">
                {/*testimonial item*/}
                <div className="testimonial-item">
                  <div className="description">
                    <p>We were so fortunate to meet this guy and the Super 'J' Cy-Business and Services, They have been more than a blessing to our organisation.</p>
                  </div>
                  <div className="testimonial-review">
                    <img src="Assets/img/testimonials/author-1.jpg" alt='' />
                    <h3 className="testimonial-title">
                      Mr. Davidson
                      <small>CEO of Gold Trust Ltd</small>
                    </h3>
                    <ul className="rating">
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star-half" /></li>
                    </ul>
                  </div>
                </div>
                {/*testimonial item*/}
                <div className="testimonial-item">
                  <div className="description">
                    <p>During the maiden edition of our Awards program where we had no idea how to go about updating nominees and followers. He with his Super 'J' Cy-Business and Services directed us on the steps to take to make a success and also took care of the web works. Thank you </p>
                  </div>
                  <div className="testimonial-review">
                    <img src="Assets/img/testimonials/author-2.jpg" alt="imag-1" />
                    <h3 className="testimonial-title">
                      Emmanuel Ekwam
                      <small>CEO Fiyosty Media</small>
                    </h3>
                    <ul className="rating">
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                    </ul>
                  </div>
                </div>
                {/*testimonial item*/}
                <div className="testimonial-item">
                  <div className="description">
                    <p>Sir Jerry as we call him is our teacher. He tutored and made some of us to become lovers of technology that today some of us can do what we are able to do today. God bless you sir for your selfless service to humanity.</p>
                  </div>
                  <div className="testimonial-review">
                    <img src="Assets/img/testimonials/author-3.jpg" alt="imag-1" />
                    <h3 className="testimonial-title">
                      Isaac Amoasi
                      <small>Student</small>
                    </h3>
                    <ul className="rating">
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                      <li><i className="fas fa-star" /></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*Testimonial Section End*/}

            {/*Clients Logo Section Start*/}
            <div className="row clients mb-50">
              <div className="col-md-12">
                <div className="sub-heading">
                  <h3>Clients</h3>
                </div>
              </div>
              <div className="owl-carousel owl-theme">
                {/*client logo*/}
                <div className="client-logo">
                  <Link to="/features">
                    <img src="Assets/img/clients/client-01.png" alt="imag-1" />
                  </Link>
                </div>
                {/*client logo*/}
                <div className="client-logo">
                  <Link to="/features">
                    <img src="Assets/img/clients/client-02.png" alt="imag-1" />
                  </Link>
                </div>
                {/*client logo*/}
                <div className="client-logo">
                  <Link to="/features">
                    <img src="Assets/img/clients/client-03.png" alt="imag-1" />
                  </Link>
                </div>
                {/*client logo*/}
                <div className="client-logo">
                  <Link to="/features">
                    <img src="Assets/img/clients/client-04.png" alt="imag-1" />
                  </Link>
                </div>
                {/*client logo*/}
                <div className="client-logo">
                  <Link to="/features">
                    <img src="Assets/img/clients/client-05.png" alt="imag-1" />
                  </Link>
                </div>
                {/*client logo*/}
                <div className="client-logo">
                  <Link to="/features">
                    <img src="Assets/img/clients/client-06.png" alt="imag-1" />
                  </Link>
                </div>
                {/*client logo*/}
                <div className="client-logo">
                  <Link to="/features">
                    <img src="Assets/img/clients/client-07.png" alt="imag-1" />
                  </Link>
                </div>
                {/*client logo*/}
                <div className="client-logo">
                  <Link to="/features">
                    <img src="Assets/img/clients/client-08.png" alt="imag-1" />
                  </Link>
                </div>
                {/*client logo*/}
                <div className="client-logo">
                  <Link to="/features">
                    <img src="Assets/img/clients/client-09.png" alt="imag-1" />
                  </Link>
                </div>
              </div>
            </div>
            {/*Clients Logo Section End*/}
          </div>
        </section>
        {/*About Section End*/}
        {/*Resume Page Start*/}
        <section id="resume" className="page section-resume">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-heading">
                  <h2 data-shadow="Resume">Resume</h2>
                </div>
              </div>
            </div>
            <div className="row mb-10">
              {/*Experience Column Start*/}
              <div className="col-lg-6">
                <div className="sub-heading">
                  <h3>Experience</h3>
                </div>
                {/*experience timeline start*/}
                <ul className="experience timeline">
                  {/*timeline item*/}
                  <li className="event">
                    <h4>Lead UI Designer</h4>
                    <h5>
                      <span className="date">2017-2018</span>
                      <span className="company">Big Design Company, NY, USA</span>
                    </h5>
                    <p>Sit cupiditate praesentium ex esse nulla Facere fuga perspiciatis eveniet provident neque Ea ratione non minus.</p>
                  </li>
                  {/*timeline item*/}
                  <li className="event">
                    <h4>Web Designer</h4>
                    <h5>
                      <span className="date">2016-2017</span>
                      <span className="company">Design Company, London, UK</span>
                    </h5>
                    <p>Dolor id atque accusantium ut impedit odit provident soluta voluptatem Veritatis ipsam neque mollitia vero ipsa.</p>
                  </li>
                  {/*timeline item*/}
                  <li className="event">
                    <h4>Web Developer</h4>
                    <h5>
                      <span className="date">2015-2016</span>
                      <span className="company">Exis Development, London, UK</span>
                    </h5>
                    <p>Consectetur pariatur fugiat ipsam aperiam maiores. Nisi in dignissimos debitis expedita asperiores delectus.</p>
                  </li>
                </ul>
                {/*experience timeline end*/}
              </div>
              {/*Experience Column End*/}
              {/*Education Column Start*/}
              <div className="col-lg-6">
                <div className="sub-heading">
                  <h3>Education</h3>
                </div>
                {/*education timeline start*/}
                <ul className="education timeline">
                  {/*timeline item*/}
                  <li className="event">
                    <h4>MA Graphics</h4>
                    <h5>
                      <span className="date">2014-2015</span>
                      <span className="company">Royal College Of Art, London, UK</span>
                    </h5>
                    <p>Provident veritatis exercitationem eum doloremque itaque expedita? Ex voluptatem nostrum numquam illum.</p>
                  </li>
                  {/*timeline item*/}
                  <li className="event">
                    <h4>Ba(hons) Graphics</h4>
                    <h5>
                      <span className="date">2013-2014</span>
                      <span className="company">Central Saint Martins, London, UK</span>
                    </h5>
                    <p>Elit voluptatibus quas necessitatibus ullam tenetur provident voluptatibus. Ratione delectus lorem minus ipsa.</p>
                  </li>
                  {/*timeline item*/}
                  <li className="event">
                    <h4>Web &amp; Graphics Diploma</h4>
                    <h5>
                      <span className="date">2012-2013</span>
                      <span className="company">Tech Arts Institute, CA, USA</span>
                    </h5>
                    <p>Ipsum ex exercitationem est aliquid eos saepe. Dolorum aperiam cupiditate labore vero deleniti Aut unde deser.</p>
                  </li>
                </ul>
                {/*education timeline end*/}
              </div>
              {/*Education Column End*/}
            </div>
            {/*Skills Row Start*/}
            <div className="row">
              {/* Design Skills Column Start */}
              <div className="col-lg-6 skills">
                <div className="sub-heading">
                  <h3>Design Skills</h3>
                </div>
                {/*skill item*/}
                <div className="skill-item">
                  <h4 className="progress-title">Coral Draw</h4>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: '91%' }}>
                      <div className="progress-value">91%</div>
                    </div>
                  </div>
                </div>
                {/*skill item*/}
                <div className="skill-item">
                  <h4 className="progress-title">Photoshop</h4>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: '82%' }}>
                      <div className="progress-value">82%</div>
                    </div>
                  </div>
                </div>
                {/*skill item*/}
                <div className="skill-item">
                  <h4 className="progress-title">Illustrator</h4>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: '94%' }}>
                      <div className="progress-value">94%</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Design Skills Column End */}
              {/* Coding Skills Column Start */}
              <div className="col-lg-6 skills">
                <div className="sub-heading">
                  <h3 data-shadow="Coding Skills">Coding Skills</h3>
                </div>
                {/*skill item*/}
                <div className="skill-item">
                  <h4 className="progress-title">HTML5</h4>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: '83%' }}>
                      <div className="progress-value">83%</div>
                    </div>
                  </div>
                </div>
                {/*skill item*/}
                <div className="skill-item">
                  <h4 className="progress-title">CSS3</h4>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: '75%' }}>
                      <div className="progress-value">75%</div>
                    </div>
                  </div>
                </div>
                {/*skill item*/}
                <div className="skill-item">
                  <h4 className="progress-title">Javascript</h4>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: '92%' }}>
                      <div className="progress-value">92%</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Coding Skills Column End */}
            </div>
            {/*Skills Row End*/}
          </div>
        </section>
        {/*Resume Page End*/}



        {/*Portfolio Page Start*/}
        <section id="portfolio" className="page section-portfolio">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-heading">
                  <h2 data-shadow="Portfolio">Portfolio</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 portfolio-filter">
                <ul>
                  <li className="active" data-filter="*">All</li>
                  <li data-filter=".brand">Brand</li>
                  <li data-filter=".design">Design</li>
                  <li data-filter=".graphic">Graphic</li>
                </ul>
              </div>
            </div>
            <div className="row portfolio-items mb-30">
              <div className="item col-lg-4 col-sm-6 graphic brand">
                <figure>
                  <img src="Assets/img/portfolio/img-1.jpg" alt="" />
                  <figcaption>
                    <h3>Project Name</h3>
                    <p>Graphic</p>
                    <i className="fas fa-image" />
                    <Link className="image-link" to="img/portfolio/img-1.jpg">View More</Link>
                  </figcaption>
                </figure>
              </div>
              <div className="item col-lg-4 col-sm-6 graphic brand">
                <figure>
                  <img src="Assets/img/portfolio/img-2.png" alt="imag-1" />
                  <figcaption>
                    <h3>Project Name</h3>
                    <p>Design</p>
                    <i className="fas fa-image" />
                    <Link className="image-link" to="Assets/img/portfolio/img-2.png">View More</Link>
                  </figcaption>
                </figure>
              </div>
              <div className="item col-lg-4 col-sm-6 design graphic">
                <figure>
                  <img src="Assets/img/portfolio/img-3.png" alt="imag-1" />
                  <figcaption>
                    <h3>Project Name</h3>
                    <p>Graphic</p>
                    <i className="fas fa-video" />
                    <Link className="video-link" to="https://www.youtube.com/watch?v=k_okcNVZqqI">View More</Link>
                  </figcaption>
                </figure>
              </div>
              <div className="item col-lg-4 col-sm-6 design graphic">
                <figure>
                  <img src="Assets/img/portfolio/img-4.jpg" className="img-fluid" alt='a' />
                  <figcaption>
                    <h3>Project Name</h3>
                    <p>Design</p>
                    <i className="fas fa-image" />
                    <Link className="image-link" to="Assets/img/portfolio/img-4.jpg">View More</Link>
                  </figcaption>
                </figure>
              </div>
              {/*portfolio item*/}
              <div className="item col-lg-4 col-sm-6 graphic">
                <figure>
                  <img src="Assets/img/portfolio/img-5.jpg" alt="imag-1" />
                  <figcaption>
                    <h3>Project Name</h3>
                    <p>Design</p>
                    <i className="fas fa-video" />
                    <Link className="video-link" to="https://www.youtube.com/watch?v=k_okcNVZqqI">View More</Link>
                  </figcaption>
                </figure>
              </div>
              <div className="item col-lg-4 col-sm-6 brand design">
                <figure>
                  <img src="Assets/img/portfolio/img-6.jpg" alt="imag-1" />
                  <figcaption>
                    <h3>Project Name</h3>
                    <p>Brand</p>
                    <i className="fas fa-image" />
                    <Link className="image-link" to="Assets/img/portfolio/img-6.jpg">View More</Link>
                  </figcaption>
                </figure>
              </div>
              <div className="item col-lg-4 col-sm-6 brand graphic">
                <figure>
                  <img src="Assets/img/portfolio/img-7.jpg" alt="imag-1" />
                  <figcaption>
                    <h3>Project Name</h3>
                    <p>Brand</p>
                    <i className="fas fa-image" />
                    <a className="image-link" href="Assets/img/portfolio/img-7.jpg">View More</a>
                  </figcaption>
                </figure>
              </div>
              <div className="item col-lg-4 col-sm-6 brand graphic">
                <figure>
                  <img src="Assets/img/portfolio/img-8.jpg" alt="imag-1" />
                  <figcaption>
                    <h3>Project Name</h3>
                    <p>Brand</p>
                    <i className="fas fa-image" />
                    <a className="image-link" href="Assets/img/portfolio/img-8.jpg">View More</a>
                  </figcaption>
                </figure>
              </div>
              <div className="item col-lg-4 col-sm-6 brand graphic">
                <figure>
                  <img src="Assets/img/portfolio/img-9.jpg" alt="imag-1" />
                  <figcaption>
                    <h3>Project Name</h3>
                    <p>Graphic</p>
                    <i className="fas fa-image" />
                    <a className="image-link" href="Assets/img/portfolio/img-9.jpg">View More</a>
                  </figcaption>
                </figure>
              </div>
              <div className="item col-lg-4 col-sm-6 brand graphic">
                <figure>
                  <img src="Assets/img/portfolio/img-10.png" alt="imag-1" />
                  <figcaption>
                    <h3>Project Name</h3>
                    <p>Graphics Design</p>
                    <i className="fas fa-video" />
                    <a className="video-link" href="https://www.youtube.com/watch?v=k_okcNVZqqI">View More</a>
                  </figcaption>
                </figure>
              </div>
              <div className="item col-lg-4 col-sm-6 brand graphic">
                <figure>
                  <img src="Assets/img/portfolio/img-11.jpg" alt="imag-1" />
                  <figcaption>
                    <h3>Project Name</h3>
                    <p>Graphic</p>
                    <i className="fas fa-image" />
                    <a className="image-link" href="Assets/img/portfolio/img-11.jpg">View More</a>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </section>
        {/*Portfolio Page End*/}



        {/*Blog Page Start*/}
        <section id="blog" className="page section-blog">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-heading">
              <h2 data-shadow="Blogs">Blogs</h2>
            </div>
          </div>
        </div>
        <div className="row blogs-container">
          <div className="col-lg-4 col-md-6">
            <a className="blog-item" href="single-blog.html">
              <div className="post-img">
                <img src="Assets/img/blogs/img-1.jpg" alt='blogimage1 - superjcybs' />
              </div>
              <div className="post-content">
                <span className="cat">Travel</span>
                <h3 className="title">Explore the Pacific Northwest</h3>
                <div className="meta">
                  <span className="date">Posted On March 8, 2015</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a className="blog-item" href="single-blog.html">
              <div className="post-img">
                    <img src="Assets/img/blogs/img-2.jpg" alt='blogimage2 - superjcybs' />
              </div>
              <div className="post-content">
                <span className="cat">Travel</span>
                <h3 className="title">Explore the Pacific Northwest</h3>
                <div className="meta">
                  <span className="date">Posted On March 8, 2015</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a className="blog-item" href="single-blog.html">
              <div className="post-img">
                    <img src="Assets/img/blogs/img-3.jpg" alt='blogimage3 - superjcybs' />
              </div>
              <div className="post-content">
                <span className="cat">Travel</span>
                <h3 className="title">Explore the Pacific Northwest</h3>
                <div className="meta">
                  <span className="date">Posted On March 8, 2015</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a className="blog-item" href="single-blog.html">
              <div className="post-img">
                    <img src="Assets/img/blogs/img-4.jpg" alt='blogimage4 - superjcybs' />
              </div>
              <div className="post-content">
                <span className="cat">Travel</span>
                <h3 className="title">Explore the Pacific Northwest</h3>
                <div className="meta">
                  <span className="date">Posted On March 8, 2015</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a className="blog-item" href="single-blog.html">
              <div className="post-img">
                    <img src="Assets/img/blogs/img-5.jpg" alt='blogimage5 - superjcybs' />
              </div>
              <div className="post-content">
                <span className="cat">Travel</span>
                <h3 className="title">Explore the Pacific Northwest</h3>
                <div className="meta">
                  <span className="date">Posted On March 8, 2015</span>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-6">
            <a className="blog-item" href="single-blog.html">
              <div className="post-img">
                    <img src="Assets/img/blogs/img-6.jpg" alt='blogimage6 - superjcybs' />
              </div>
              <div className="post-content">
                <span className="cat">Travel</span>
                <h3 className="title">Explore the Pacific Northwest</h3>
                <div className="meta">
                  <span className="date">Posted On March 8, 2015</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
        {/*Blog Page End*/}




        {/*Contact Page Start*/}
        <section id="contact" className="page section-contact">
          <div className="container">
            <div className="row">
          <div className="col-md-12">
            <div className="page-heading">
              <h2 data-shadow="Contact">Contact</h2>
            </div>
          </div>
        </div>
            <div className="row">
              <div className="col-lg-6 contact-detail">
            <div className="sub-heading mb-20">
              <h3>Get In Touch</h3>
            </div>
            <p>For more information on any of my services or to talk about how we may be able to help you, please get in touch with us using the form opposite. Or you can call and follow our social media.</p>
            <ul className="contact-info">
              <li className="phone">
                <span className="title">Phone</span><span className="value">(+974) 6693 0807 / 77398295</span>
              </li>
              <li className="address">
                <span className="title">Address</span><span className="value">Zone 23, Street 832, Al Mansoura, Qatar</span>
              </li>
              <li className="email">
                <span className="title">E-mail</span><span className="value">jerrybabatunde1@gmail.com</span>
              </li>
            </ul>
            <div className="social-media">
              <h4>Follow</h4>
              <div className="social-media-icons">
                <a href="https://www.facebook.com/kofisupernatural"><i className="fab fa-facebook" /></a>
                <a href="https://www.twitter.com/superjcybs"><i className="fab fa-twitter" /></a>
                <a href="https://www.instagram.com/superjcybs"><i className="fab fa-instagram" /></a>
                <a href="https://www.linkedin.com/in/jerry-babatunde-81a26bb5/"><i className="fab fa-linkedin" /></a>
              </div>
            </div>
          </div>
              {/*Contact Information Column End*/}

              {/*Contact Form Column Start*/}
              <div className="col-lg-6">
            <form id="contact-form" method="post" action="mail.php">
              <div className="input">
                <input className="input-field cf-validate" id="cf-name" type="text" name="name" placeholder="Your Name" />
              </div>
              <div className="input">
                <input className="input-field cf-validate" id="cf-email" type="text" name="email" placeholder="Your Email" />
              </div>
              <div className="input">
                <textarea className="input-field cf-validate" id="cf-message" name="message" rows={5} placeholder="Message" defaultValue={""} />
              </div>
              <div className="alert-container col-md-12" />
              <div className="submit-button text-center">
                <button className="main-btn" id="cf-submit">Send Message</button>
              </div>
            </form>
          </div>
              {/*Contact Form Column End*/}
            </div>

            {/*Google Map Row Start*/}
            <div className="row">
          <div className="col-md-12 google-map">
            <div id="map" data-latitude="25.271237" data-longitude="51.535128" data-zoom={15} />
          </div>
        </div>
            {/*Google Map Row End*/}

          </div>
        </section>
        {/*Contact Page End*/}
      </div>
    </>

  )
}

export default CVjerry