import React from 'react'
import './AdjacentPostCard.css'
import { Link } from 'react-router-dom'
import moment from 'moment'

const AdjacentPostCard = ({ post, position }) => {
  // console.log(post)

  return (
    <>
      <div className='adjacentPosts' style={{ backgroundImage: `url('${post.featuredImage.url}')`}}/>
      <div className="adjacentPosts1" />
      <div className="adjacentPosts__info">
        <p className="adjacentPosts__period">{moment(post.createdAt).format('MMM DD, YYYY')}</p>
        <p className="adjacentPosts__title">{post.title}</p>
        </div>
      <Link to={`/blog/post/${post.slug}`}><span className="adjacentPosts__link" ></span></Link>
      {position === 'LEFT' && (
        <div className="adjacentPosts__prev">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </div>
      )}

      {position === 'RIGHT' && (
        <div className="adjacentPosts__next">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>
      )}
    </>
  )
}

export default AdjacentPostCard