import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom';
import { getFeed } from '../../API/BlogStore';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const BlogFeed = () => {
    const [Feed, setFeed] = useState([]);

    useEffect(() => {
        async function receivedPosts() {
            const rPostss = await getFeed()
            // console.log('feed',rPostss);
            setFeed(rPostss)
        }
        receivedPosts()
    }, [])
    return (
        <section id="blogfeed" className="blogfeed">

            <div className='container-fluid bg-light'>
                <div className="section-title text-center">
                    <h2>Blog Feed</h2>
                    <p>From our blog</p>
                </div>
                <Row xs={1} md={3} className="g-4">
                    {Feed.map((post, index) => (
                        <Col key={index}>
                            <Card className='mb-4'>
                                <Link to={`/blog/post/${post.slug}`} style={{ color: 'blue' }}>
                                    <Card.Img variant="top" src={post.featuredImage.url} alt={post.title} style={{height:'200px'}} className='blogfeed__image' />
                                    <Card.Body>
                                        <Card.Title>{post.title}</Card.Title>
                                        <Card.Text>
                                            {post.excerpt}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <small className="text-muted">{moment(post.createdAt).format('MMM DD, YYYY')}</small> 
                                    </Card.Footer>
                                </Link>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </section>
    )
}

export default BlogFeed