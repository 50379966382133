import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Privacy = () => {
  useEffect(() => {
    document.title = "Our Privacy Policy - SUPERJCYBS"
  }, [])
  
  return (
    <div className='p-4'>

      <h2 className='text-center mt-4'>PRIVACY POLICY</h2>
      <section style={{ lineHeight: "2rem", width: "500px", margin: "auto", textAlign: "center", padding: "3rem" }}>
        <article>
          We are the <b>Super 'J' Cy-Business And Services</b>, abbreviated as SUPERJCYBS.
          We are registered in Ghana under the Registrars General Department with registration number BN313342017.
        </article>
        <article>
          We are registered as a Sales of General Goods, Mobile Money Services as well as ICT Service Provision firm.
        </article>
        <article>
          We have been operating for 5 years now and have expertised ourselves with what we do.
        </article>
        <article>
          When operationing with us financially, kindly ensure that recieving accounts bears the name of the business as formerly stated or the name of the CEO; Jerry Babatunde else please dont continue with the transaction.        </article>
        <article>
          Kindly note that our active and viable payment channels can be found on our official website <Link to="/pay" style={{ color: "blue" }}>here</Link>
        </article>
        <article>
          In case of any challenge, kindly dont relent in reaching out to us on our channels advertised clearly on our official website <Link to="/#contact" style={{ color: "blue" }}>https://superjcybs.com</Link>
        </article>
        <article>
          Lets make this clear that we are under a bigger umbrella <b>THE SUPERNATURALS BUSINESS FAMILY GHANA (TSBF GH)</b> <Link to="/tsbf-ghana" style={{ color: "blue" }}>https://tsbfgh.superjcybs.com</Link>
          {' '}and we also have amazing sectors for you to work with, we've got you covered.
        </article>
        <article>
          Because we trust in our services, we parade ourself with the slogan <i>...BRINGING YOU SMILES </i>
        </article>
      </section>
    </div>
  )
}

export default Privacy