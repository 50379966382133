import React from 'react'
import { Link } from 'react-router-dom'

const Posts = ({ posts }) => {

    // console.log('posts@DTPostView', posts)

    return (
        <div className='dtposts-container'>
            {
                posts?.map(post => (
                    <div className='dtposts-box' key={post.id}>
                        <div className='dtposts-img'>
                            <Link to={`/dunamistravels/article/${post.slug}`}>
                                <img src={post.coverPhoto.url} alt={post.title} />
                            </Link>
                            <div className='dtcategories menu-lists'>
                                {
                                    post.categories?.map(category => (
                                        <Link to={`/dunamistravels/posts/${category.slug}`} key={category.id} className='category' style={{ background: category.color.css }}>
                                            {category.name}
                                        </Link>
                                    ))
                                }

                            </div>
                        </div>
                        <div className='dtposts-text'>
                            <div className='dtcategory-time'>
                                {
                                    post.categories?.map(category => (
                                        <Link to={`/dunamistravels/posts/${category.slug}`} key={category.id} style={{ color: category.color.css }}>
                                            {category.name}
                                        </Link>
                                    ))
                                }
                                <span className='dtpublished-time'>
                                    {new Date(post.updatedAt).toDateString()}
                                </span>
                            </div>

                            <Link to={`/dunamistravels/article/${post.slug}`}>
                                <h3>{post.title}</h3>
                            <p className='dtexcerpt'>{post.description}</p>
                            </Link>
                        </div>
                    </div>

                ))
            } 
        </div>
    )
}

export default Posts