import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowLeftSharpIcon from '@mui/icons-material/ArrowBackIosNew';

const Pagination = ({totalPage}) => {


    const { search } = useLocation();
    const [pageNumber, setpageNumber] = useState([]);
    const page = Number(new URLSearchParams(search).get('page')) || 1;

    useEffect(() => {
        if (totalPage <= 1) return;

        if (totalPage <= 5) {
            const newArr = [...Array(totalPage)].map((_, i) => i + 1);
            return setpageNumber(newArr);
        }
        
        let newArr = [];
        for (let index = 1; index <= 5; index++) {
            if (page <= 2) {
                newArr.push(index)
            }
            if (page > 2 && page < totalPage - 2) {
                newArr.push(page + index - 3)
            }
            if (page >= totalPage - 2) {
                newArr.push(index - 5 + totalPage)
            }
        }
        setpageNumber(newArr)
        // console.log('ttp<=5',newArr)
    }, [totalPage, page])

    if (totalPage <= 1) return null;


    return (
        <div className='dtpagination'>
            <Link to={`?page=${page - 1 <= 1 ? 1 : page - 1}`} className='dtprev'>
                <ArrowLeftSharpIcon /> Prev
            </Link>

            <ul className='dtnumbers'>
                {
                    pageNumber.map(num => (
                        <Link to={`?page=${num}`} key={num}>
                            <li className={page === num ? 'active' : ''}>{num}</li>
                        </Link>
                    )
                    )
                }
            </ul>

            <Link to={`?page=${page + 1 >= totalPage ? totalPage : page + 1}`} className='dtnext'>
                Next
                <ArrowForwardIcon />
            </Link>
        </div>
    )
}

export default Pagination