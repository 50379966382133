import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { db, collection, getDocs, doc, setDoc, storage } from "././../../../API/Firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CheckCircleIcon from '@mui/icons-material/Payments';
import CardPaymentIcon from '@mui/icons-material/Payment';
import ThumbUpIcon from '@mui/icons-material/AccountBalance';
import PaystackPop from '@paystack/inline-js'
import { Helmet } from 'react-helmet-async';
import emailjs from '@emailjs/browser';

const DTregistration = () => {

  const navigate = useNavigate();
  const date = new Date();

  const [usedCodes, setUsedCodes] = useState([])
  const [paymentRef, setPaymentRef] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [congratModal, setCongratModal] = React.useState(false);
  const [payOptionModal, setPayOptionModal] = React.useState(false);
  
  const [file, setFile] = useState(null)
  const [formData, setFormData] = useState({
    fName: '',
    mName: '',
    lName: '',
    dob: '',
    gender: '',
    ayV: '',
    nation: '',
    phone1: '',
    phone2: '',
    email: '',
    passport: '',
    gCard: '',
    nhis: '',
    city: '',
    EducationalQualification: '',
    FieldOfStudy: '',
    surburb: '',
    HouseNumber: '',
    userLand: '',
    profession: '',
    CV: '',
    extraCap: '',
    pJobs: '',
    pCountries: '',
    emergencyPersonName: '',
    emergencyPersonPhone: '',
    emergencyPersonRelation: '',
    emergencyPersonAddress: '',
    emergencyPersonLand: '',
    officer: 'EUNICE MAAME ESI SUPERNATURAL',
    designation: 'Vice President',
    signature: 'Signed',
    Date: date,
  })

  // LOAD DATA ON FIRST UNMOUNT
  useEffect(() => {
    function simulateNetworkRequest() {
      return new Promise((resolve) => setTimeout(resolve, 2000));
    }
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false)
      });
    }
  }, [isLoading])

  useEffect(() => {
    toast.info("Form is GHS 250");
    toast.warn("FEE IS NON-REFUNDABLE", { theme: "dark" })
    
    const fetchUsedCodes = async () => {    // To retrieve documents from my collection, 
      const querySnapshot = await getDocs(collection(db, 'DTusedCodes'));
      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data();
        setUsedCodes(docData.value);    // If there my documents, it sets its into state for usedCodes
      }
    };
    fetchUsedCodes();
  }, [])

  useEffect(() => {     // this useEffect listens for changes in my usedCodes array, then updates firestore with the new document
    if (usedCodes.length > 0) {
      const updateFirestore = async () => {
        const docRef = doc(db, 'DTusedCodes', 'usedCodes');
        await setDoc(docRef, { value: usedCodes });
      };
      updateFirestore();
    }
  }, [usedCodes]);


  const handleFileChange =  (e) => {
    setFile(e.target.files[0]);

  };

  const uploadFileToFirebase = async (file) => {
    const storageRef = ref(storage, `DTRegistrationUploads/${file.name}`);
    
    try {
      // Upload the file to Firebase Storage
      const snapshot = await uploadBytes(storageRef, file);
      
      // Get the file's download URL
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Return the download URL if you need to use it
      return downloadURL;
    } catch (error) {
      toast.error("Error uploading file:", error);
      return
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData, [name]: value
    });
  };

  const sendEmail = (e) => {
    // e.preventDefault();
    const templateParams = {
      user_name: formData.fName,
      user_email: formData.email,
      comment: "Not Applicable",
      type: "Registration",
      message: "Not Applicable",
      subject: "Not Applicable",
      platform: "DUNAMIS TRAVELS GHANA",
      slug: "Not Applicable"
    };
    console.log('toBeSent',templateParams)
    emailjs
      .send('service_qxsvb5t', 'template_zgr4cxj', templateParams, { publicKey: 'ibZjaF1rjAsvzDVGf' })
      .then(
        (result) => {
          setTimeout(() => {
          }, 5000);
        },
        (error) => {
          setTimeout(() => {
          }, 5000);
        });
  };

  const sendToDT = async(e) => {
    if(file){
      await uploadFileToFirebase(file)
    }
    db.collection("dtregistration").add({
      firstName: formData.fName,
      middleName: formData.mName,
      lastName: formData.lName,
      dob: formData.dob,
      gender: formData.gender,
      areyouVaccinated: formData.ayV,
      nation: formData.nation,
      phone1: formData.phone1,
      phone2: formData.phone2,
      email: formData.email,
      passport: formData.passport,
      ghanaCard: formData.gCard,
      nhis: formData.nhis,
      city: formData.city,
      EducationalQualification: formData.EducationalQualification,
      FieldOfStudy: formData.FieldOfStudy,
      surburb: formData.surburb,
      HouseNumber: formData.HouseNumber,
      userLandmark: formData.userLand,
      profession: formData.profession,
      CV: formData.CV,
      extraCapabilities: formData.extraCap,
      preferredJobs: formData.pJobs,
      preferredCountries: formData.pCountries,
      emergencyPersonName: formData.emergencyPersonName,
      emergencyPersonPhone: formData.emergencyPersonPhone,
      emergencyPersonRelation: formData.emergencyPersonRelation,
      emergencyPersonAddress: formData.emergencyPersonAddress,
      emergencyPersonLandmark: formData.emergencyPersonLand,
      officer: formData.officer,
      designation: formData.designation,
      signature: formData.signature,
      Date: formData.Date,
    })

      .then(() => {
        sendEmail()
        setCongratModal(true)
      })
      .catch((error) => {
        toast.error(error.message)
      })
    // e.target.reset()
  }

  const payWithPaystack = async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {

        const paystack = new PaystackPop()
        paystack.newTransaction({
          reference: (new Date()).getTime().toString(),
          email: formData.email ? formData.email : "user@example.com",
          currency: 'GHS',
          amount: 25000,
          key: process.env.REACT_APP_PAYSTACK,
          callback: (transaction) => {
              toast.success("Payment Successful")
            setPaymentRef(transaction.reference)
            sendToDT()
          },
          onClose: () => {
            toast.error(`Payment failed`)
            reject(new Error('Payment is unsuccessful'))
          }
        })
        // paystack.openIframe();
      }, 2000);
    });
  };

  //S---U--B----M----I----T-----  
  const HandleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setValidated(true)
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      toast.error('validation error, Please fill out all unticked fields appropraitely')
    } else { 
    setPayOptionModal(true)
      }
    }
  
    // PAYMENT OPTION MODAL
    function PayOptionModal(props) {
      const onModalClose = () => {
        props.onHide()
      }
             
        const Momo_BankPay = (e)=>{
          const promptResponse = window.prompt('Enter the code you receive via SMS in the box below?')
          var CODED = process.env.REACT_APP_PAID_CODE
          const PDS = CODED.split(", ");
          const CodeIsCorrect = PDS.includes(promptResponse)
          if (promptResponse != null && promptResponse.length >0){
            let processedResponse = promptResponse.trim().replace(/\s+/g, "") // remove all spaces 
            if (CodeIsCorrect) {  
              const prevUsedCode = [...usedCodes];
              if (prevUsedCode.includes(processedResponse)){
                window.confirm('This Code has already been used, Please try again')
                return
              } else {
                prevUsedCode.push(processedResponse)
                setUsedCodes(prevUsedCode)
                const docRef = doc(db, 'DTusedCodes', 'usedCodes'); // for firestore DB
                setDoc(docRef, { value: usedCodes });   // for firestore DB
              }
              toast.success('Payment processed Successfully')
              onModalClose()
              sendToDT()
            } else {
              alert('Incorrect Code, Please TRY AGAIN!')
              return
            }
      } else {
        toast.error('No entry, Please try again')
      }
    }
    
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className='bg-info' style={{ display: 'flex', justifyContent: 'center' }}>
          <Modal.Title id="contained-modal-title-vcenter" className='text-white text-center'>
            CHOOSE A PAYMENT OPTION! <CheckCircleIcon />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', textAlign: 'center' }}>
          <h4> <b>FOR THOSE IN GHANA</b></h4>
          <p>Send GHS 250 to Account Name: <i>Super 'J' Cy-Business And Services</i><br />
            (MTN) 0558443647<br />
            (AT) 0579100909    (Agent Code: 98284) <br />
            (TELCEL) 0503095567 (Agent Code: 903342)<br /></p>
          <Link to='/pay' target='blank' style={{ color: 'red' }}>For Bank Transfer Details, click here</Link>
          <br />
          <Button onClick={Momo_BankPay} >I HAVE PAID WITH MOMO or BANK <ThumbUpIcon /></Button>
          <br />
          <h4> <b>FOR OTHERS/ALTERNATIVE OPTION</b></h4>
          <Button onClick={payWithPaystack}>PAY WITH CARD <CardPaymentIcon /></Button>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }} >
          <Button onClick={onModalClose}
          >Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  // CONFIRMATION MODAL
  function CongratulatoryModal(props) {
    const onModalClose = () => {
      props.onHide()
      navigate('/dunamistravels')
    }
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className='bg-success' style={{ display: 'flex', justifyContent: 'center' }}>
          <Modal.Title id="contained-modal-title-vcenter" className='text-white text-center'>
            CONGRATULATIONS! <CheckCircleIcon />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', textAlign: 'center' }}>
          <h4> <b>YOUR REGISTRATION WITH DUNAMIS TRAVELS IS SUCCESSFUL</b></h4>
          <p>
            <b>IF YOU PAID WITH FOR OTHERS/ALTERNATIVE OPTION</b>
            Please take note of this reference code <b>{paymentRef}</b>,<br /> Text it to +233247792110 if you dont receive a callback after 24 hours<br />
            Well Done! <ThumbUpIcon />
          </p>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }} >
          <Button onClick={onModalClose}
          >Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <div className='dtregmain'>
      <Helmet>
        <title>Dunamis Travels Registration</title>
        <meta name="description" content='Register with Dunamis Travels today and sign that form to set you on your travel experience' />
        <meta property="og:title" content='Dunamis Travels Registration' />
        <meta property="og:description" content='Register with Dunamis Travels today and sign that form to set you on your travel experience' />
        <meta property="og:url" content='https://superjcybs.com/dunamistravels/registration' />
        <meta name="twitter:title" content='Dunamis Travels Registration' />
        <meta name="twitter:description" content='Register with Dunamis Travels today and sign that form to set you on your travel experience' />
      </Helmet>
      <p style={{ textAlign: 'center', backgroundColor: 'green' }}>
        <Link to='/dunamistravels'>Back to DUNAMIS TRAVELS HOMEPAGE</Link>
      </p>
      <CongratulatoryModal show={congratModal} onHide={() => setCongratModal(false)} backdrop="static"
        keyboard={false} />
      <PayOptionModal show={payOptionModal} onHide={() => setPayOptionModal(false)} backdrop="static"
        keyboard={false} />
      <Container>
        <h2 className='text-center p-2'>DUNAMIS TRAVELS (GHANA)</h2>
        <h4 className='text-center p-2'><u>REGISTRATION FORM</u></h4>
        <Stack direction='horizontal' className='bg-light' gap={3}>
          <div className='p-2'><a href='https://facebook.com/dunamistravels1' style={{ color: 'blue' }} target='blank'>Facebook</a></div>
          <div className='ms-auto p-2'>REG.NO: BN206370321</div>
          <div className='ms-auto p-2'><a href='https://wa.me/+233247792110?text=Hello+I+need+help+with+the+Dunamis+Travels+Registration' style={{ color: 'green' }} target='blank'>Whatsapp</a></div>
        </Stack>

        <h6 className='bg-dark text-white text-center p-4 mt-3'>PERSONAL INFORMATION</h6>
        <Form id='paymentForm' onSubmit={HandleSubmit} autoComplete='on' noValidate validated={validated}>
          <Row className='g-2'>
            <Col md className='mb-1'>
              <FloatingLabel controlId='fName' label='First Name'>
                {/* <Form.Control type='text' placeholder='Enter only your first name' name='fName' value={formData.fName} onChange={handleChange} minLength={3} pattern="^[a-zA-Z-]+$" required isInvalid={validated && !/^([a-zA-Z0-9\\-]+)\s([a-zA-Z0-9\\-]+)$/.test(formData.fName)} /> */}
                <Form.Control type='text' placeholder='Enter only your first name' name='fName' value={formData.fName} onChange={handleChange} minLength={3} pattern="^[a-zA-Z]+(-[a-zA-Z]+)?" required isInvalid={validated && !/^[a-zA-Z]+(-[a-zA-Z]+)?/.test(formData.fName)} />
                <Form.Control.Feedback type="invalid">Please enter a valid name (alphabets only)</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='mName' label='Middle Name'>
                <Form.Control type='text' placeholder='Middle Name (OPTIONAL)' name='mName' value={formData.mName} onChange={handleChange} pattern="^[a-zA-Z]+(-[a-zA-Z]+)?" isValid={validated || !/^[a-zA-Z]+(-[a-zA-Z]+)?/.test(formData.mName)}></Form.Control>
                <Form.Control.Feedback type="invalid">Middle name is optional (should be alphabet only)</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='lName' label='Last Name'>
                <Form.Control type='text' placeholder='Enter only your last name' name='lName' value={formData.lName} onChange={handleChange} required minLength={3} pattern="^[a-zA-Z]+(-[a-zA-Z]+)?" isInvalid={validated && !/^[a-zA-Z]+(-[a-zA-Z]+)?/.test(formData.lName)}></Form.Control>
                <Form.Control.Feedback type="invalid">Please enter a valid name (alphabets only)</Form.Control.Feedback>
              </FloatingLabel>

            </Col>
          </Row>

          <Row className='g-2'>
            <Col md className='mb-1'>
              <FloatingLabel controlId='dob' label='Date of Birth'>
                <Form.Control type='date' placeholder='DDYYYY/MMM/' name='dob' value={formData.dob} onChange={handleChange} required></Form.Control>
                <Form.Control.Feedback type="invalid">The date picker will help you</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='gender' label='Gender'>
                <Form.Select className='text-dark' name='gender' value={formData.gender} onChange={handleChange} required>
                  <option disabled defaultValue value=''>Choose below</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">Please choose approriately (required)</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='ayv' label='Are you vacinated?'>
                <Form.Select className='text-dark' name='ayV' value={formData.ayV} onChange={handleChange} required>
                  <option disabled defaultValue value=''>Choose below</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">Please be sincere (required)</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='nation' label='Nationality'>
                <Form.Control type='text' placeholder='' name='nation' value={formData.nation} onChange={handleChange} required pattern="^[a-zA-Z_\-\.]{3,30}$" isInvalid={validated && !/^[a-zA-Z_\-\\.]{3,30}$/.test(formData.nation)}></Form.Control>
                <Form.Control.Feedback type="invalid">Please enter a valid country</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>

          <Row className='g-2'>
            <Col md className='mb-1'>
              <FloatingLabel controlId='phone1' label='Phone Number'>
                <Form.Control type='text' placeholder='main phone number' name='phone1' value={formData.phone1} onChange={handleChange} autoComplete='on' required pattern='^[+]{1}(?:[0-9_\-]\s?){9,15}[0-9]{1}$' isInvalid={validated && !/^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){9,15}[0-9]{1}$/.test(formData.phone1)} />
                <Form.Control.Feedback type="invalid">Include country code and not less than 11 characters</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='phone2' label='Extra Phone'>
                <Form.Control type='text' placeholder='Any other number' name='phone2' value={formData.phone2} onChange={handleChange} pattern='^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$' isValid={validated || !/^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){9,14}[0-9]{1}$/.test(formData.phone2)} />
                <Form.Control.Feedback type="invalid">Must include your country code and not less than 11 characters</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='email' label='Email'>
                <Form.Control type='email' className='' placeholder='Enter your email' name='email' value={formData.email} onChange={handleChange} autoComplete='on' required pattern='^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$' isInvalid={validated && !/^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5})$/.test(formData.email)} />
                <Form.Control.Feedback type="invalid">Only valid email address Eg: contact@superjcybs.com</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>

          <Row className='g-2'>
            <Col md className='mb-1'>
              <FloatingLabel controlId='passport' label='Passport Number'>
                <Form.Control type='text' placeholder='G000000' name='passport' value={formData.passport} onChange={handleChange} required minLength={3} />
                <Form.Control.Feedback type="invalid">Please enter a valid number (required)</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='gCard' label='National ID'>
                <Form.Control type='text' placeholder='National ID' name='gCard' value={formData.gCard} onChange={handleChange} required minLength={3} />
                <Form.Control.Feedback type="invalid">Please this field is required</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='nhis' label='NHIS (For Ghanaians Only)'>
                <Form.Control type='text' placeholder='For Ghanaians Only' name='nhis' value={formData.nhis} onChange={handleChange} minLength={8} />
                <Form.Control.Feedback type="invalid">This field is optional (accepts only numbers, minimum 8 digits)</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>

          <h6 className='bg-dark text-white text-center  p-4 mt-3'>RESIDENTIAL & OTHER INFORMATION</h6>

          <Row className='g-2'>
            <Col md className='mb-1'>
              <FloatingLabel controlId='city' label='City'>
                <Form.Control type='text' placeholder='Enter your city' name='city' value={formData.city} onChange={handleChange} minLength={3} required></Form.Control>
                <Form.Control.Feedback type="invalid">Please enter a valid city</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='surburb' label='Surburb'>
                <Form.Control type='text' placeholder='Surburb in that community' name='surburb' value={formData.surburb} onChange={handleChange} minLength={3} required></Form.Control>
                <Form.Control.Feedback type="invalid">Please enter a valid community</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='HouseNumber' label='House Number'>
                <Form.Control type='text' placeholder='Please be specific' name='HouseNumber' value={formData.HouseNumber} onChange={handleChange} minLength={3} required></Form.Control>
                <Form.Control.Feedback type="invalid">Please enter a valid location</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>

          <Row className='g-2'>
            <Col md className='mb-1'>
              <FloatingLabel controlId='userLand' label='Closest Landmark'>
                <Form.Control type='text' placeholder='Please be specific' name='userLand' value={formData.userLand} onChange={handleChange} minLength={3} required></Form.Control>
                <Form.Control.Feedback type="invalid">Please enter a valid location (required)</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='profession' label='Profession'>
                <Form.Control type='text' placeholder='your profession' name='profession' value={formData.profession} onChange={handleChange} minLength={3} required></Form.Control>
                <Form.Control.Feedback type="invalid">Please enter a certified profession (required)</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='CV' label='Do you have a CV?'>
                <Form.Select className='text-dark' name='CV' value={formData.CV} onChange={handleChange} required>
                  <option disabled defaultValue value=''>Choose below</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>
          <Row className='g-2'>
            <Col md className='mb-1'>
              <FloatingLabel controlId='educationalQualification' label='Highest Education Level'>
                <Form.Control type='text' placeholder='What is your Edu. Qualification' name='EducationalQualification' value={formData.EducationalQualification} onChange={handleChange} minLength={3} required></Form.Control>
                <Form.Control.Feedback type="invalid">Basic, Secondary or Tertiary</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='fieldOfStudy' label='Field of Study'>
                <Form.Control type='text' placeholder='Your field of Study' name='FieldOfStudy' value={formData.FieldOfStudy} onChange={handleChange} minLength={3} ></Form.Control>
                <Form.Control.Feedback type="invalid">Nursing, IT, Marketings etc</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='HouseNumber' label='Empty'>
                <Form.Control type='text' placeholder='Please be specific' name='HouseNumber' value='' onChange={handleChange} minLength={3} disabled></Form.Control>
                <Form.Control.Feedback type="invalid">Please enter a valid location</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>
          <FloatingLabel className='mb-1' controlId='extraCap' label='Extra Capabilities'>
            <Form.Control as='textarea' placeholder='Please separate them with comma' name='extraCap' value={formData.extraCap} onChange={handleChange} required style={{ height: '70px' }} minLength={3} isInvalid={validated && formData.extraCap.length < 3} />
            <Form.Control.Feedback type="invalid">Please separate abilities with comma (required)</Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel className='mb-1' controlId='pJobs' label='Preferred Jobs'>
            <Form.Control as='textarea' placeholder='Please separate them with comma' name='pJobs' value={formData.pJobs} onChange={handleChange} required style={{ height: '70px' }} minLength={3} isInvalid={validated && formData.pJobs.length < 3} />
            <Form.Control.Feedback type="invalid">Please separate jobs with comma (required)</Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel className='mb-1' controlId='pCountries' label='Preferred Countries'>
            <Form.Control as='textarea' placeholder='Please separate them with comma' name='pCountries' value={formData.pCountries} onChange={handleChange} required style={{ height: '70px' }} minLength={3} isInvalid={validated && formData.pCountries.length < 3} />
            <Form.Control.Feedback type="invalid">Please separate countries with comma (required)</Form.Control.Feedback>
          </FloatingLabel>

          <h6 className='bg-dark text-white text-center  p-4 mt-3'>EMERGENCY CONTACT INFORMATION</h6>
          <Row className='g-2'>
            <Col md className='mb-1'>
              <FloatingLabel controlId='emergencyPersonName' label="Person's Name">
                <Form.Control type='text' placeholder='Enter a full legal name' name='emergencyPersonName' value={formData.epName} onChange={handleChange} required pattern='^([a-zA-Z0-9\-\(\)]+)\s([a-zA-Z0-9\-]+)$' isInvalid={validated && !/^([a-zA-Z0-9\\-]+)\s([a-zA-Z0-9\\-]+)$/.test(formData.emergencyPersonName)} ></Form.Control>
                <Form.Control.Feedback type="invalid">Please enter a legal first and last name</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='emergencyPersonPhone' label="Person's Number">
                <Form.Control type='text' placeholder='Enter a complete number' name='emergencyPersonPhone' value={formData.epPhone} onChange={handleChange} required pattern='^[+]{1}(?:[0-9_\-]\s?){9,15}[0-9]{1}$' isInvalid={validated && !/^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){9,15}[0-9]{1}$/.test(formData.emergencyPersonPhone)}></Form.Control>
                <Form.Control.Feedback type="invalid">Must include your country code and not less than 11 characters</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>
          <Row className='g-2'>
            <Col md className='mb-1'>
              <FloatingLabel controlId='emergencyPersonRelation' label="Relation">
                <Form.Control type='text' placeholder='Who is this person to you?' name='emergencyPersonRelation' value={formData.epRelation} onChange={handleChange} required minLength={3}></Form.Control>
                <Form.Control.Feedback type="invalid">This field is required (mother, father, spouse, son, daughter, guardian)</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='emergencyPersonAddress' label="Residential Address">
                <Form.Control type='text' placeholder='Enter a well-located direction' name='emergencyPersonAddress' value={formData.epAddress} onChange={handleChange} required minLength={3}></Form.Control>
                <Form.Control.Feedback type="invalid">Please enter a valid address (required)</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>
          <Row className='g-2'>
            <Col md className='mb-1'>
              <FloatingLabel controlId='emergencyPersonLand' label="Closest Landmark">
                <Form.Control type='text' placeholder='Enter a valid landmark' name='emergencyPersonLand' value={formData.epLand} onChange={handleChange} required minLength={3} />
                <Form.Control.Feedback type="invalid">Please enter a valid location (required)</Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col md className='mb-1'>
              <FloatingLabel controlId='image' label="Photo of your Passport">
                {/* <Form.Control type='file' name='image' value={formData.image} onChange={handleChange} required accept='image/*' isInvalid={!!imageUpError} /> */}
                <Form.Control type='file' name='image' onChange={handleFileChange} required accept=".jpg, .jpeg, .png"  />
                <Form.Control.Feedback type="invalid">Please select an image file (png, jpg, jpeg)</Form.Control.Feedback>
                {/* {imageUpError && <div className='invalid-feedback d-block'>{imageUpError}</div>} */}
              </FloatingLabel>
            </Col>
          </Row>

          <h6 className='bg-dark text-white text-center p-4 mt-3'>FOR OFFICE USE</h6>
          <InputGroup className='mb-1'>
            <InputGroup.Text>Account Officer</InputGroup.Text>
            <Form.Control type='text' value='EUNICE MAAME ESI SUPERNATURAL' name='officer' readOnly />
          </InputGroup>
          <InputGroup className='mb-1'>
            <InputGroup.Text >Designation</InputGroup.Text>
            <Form.Control type='text' value='Vice President' name='designation' readOnly />
          </InputGroup>
          <InputGroup className='mb-1'>
            <InputGroup.Text>Signature</InputGroup.Text>
            <Form.Control type='text' value='Signed' name='signature' readOnly />
          </InputGroup>
          <InputGroup className='mb-1'>
            <InputGroup.Text>Date</InputGroup.Text>
            <Form.Control type='text' value={date} name='Date' readOnly />
          </InputGroup>
          <Form.Group className='mb-3 ms-auto' id='formBasicCheckbox'>
            <Form.Check type='checkbox' htmlFor='newsletter-checkbox' label='Check me out to receive updates' name='newsletter-checkbox' feedback="You must agree before submitting." feedbackType="invalid" required ></Form.Check>
          </Form.Group>
          <div className="alert alert-danger">
            <strong>Attention!</strong> You have you make a <b>non-refundable</b> payment of GHS 250 before you can be able to submit
          </div>
          {/* <Button size='lg' variant='primary' style={{ width: '100%' }} disabled={isLoading} onClick={()=>setPayOptionModal(true)}>{isLoading ? 'Loading...' : 'Pay & Submit'}</Button> */}
          <Button size='lg' variant='primary' style={{ width: '100%' }} disabled={isLoading} type='submit'>{isLoading ? 'Loading...' : 'Pay & Submit'}</Button>
        </Form>
      </Container>
    </div>
  )
}

export default DTregistration