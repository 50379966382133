import React  from 'react';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

const MydModalWithGrid = (props) => {
    // console.log(props)
  return (
      <div>
          <Modal fullscreen {...props} aria-labelledby="contained-modal-title-vcenter">
              <Modal.Header closeButton>

              </Modal.Header>
              <Modal.Body className="bg-success show-grid">
                  <Container>
                        {props.content}
                  </Container>
              </Modal.Body>
              {/* <Modal.Footer>
                  <span style={{ justifyContent: 'center'}}>{props.content.props?.alt || props.content.props?.title}</span> 
              </Modal.Footer> */}
          </Modal>
    </div>
  )
}

export default MydModalWithGrid