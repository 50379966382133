import React, { useEffect } from 'react'
import './DunamisTravels.css'
import { toast } from 'react-toastify';

import Footer from "./DunamisTravels/components/DTFooter.js"
import Pagination from "./DunamisTravels/components/DTPagination.js"
import Loading from "./DunamisTravels/components/Loading.js"
import NotFound from "./DunamisTravels/components/DTNotFound.js"
import Posts from './DunamisTravels/components/DTPosts.js';

import { useMyContext } from './DunamisTravels/Context/store'
import { QUERY_POSTS } from './DunamisTravels/Graphql/Queries';
import UseQueryPosts from './DunamisTravels/Hooks/UseQueryPosts';
import DTheader from './DunamisTravels/components/DTheader.js';
import { Helmet } from 'react-helmet-async';
// import DTLOGO from '/Assets/images/sectors/Dunamis.jpg'

const DunamisTravels = () => {

  useEffect(() => {
    toast.success("Welcome to Dunamis Travels Official Website", { theme: "colored" })
    toast.warning("You can send us a whatsapp message with the icon on the lower right");
  }, [])

  const { loading, totalPage } = useMyContext()
  const { posts, error, status } = UseQueryPosts({ query: QUERY_POSTS})

  if (status === 'sucess' && posts.length === 0) return <NotFound />
  if (error) <alert style={{ textAlign: 'center' }}>{error}</alert>

  // console.log('posts@DTHome',posts)
  return (
    <>
      <Helmet>
        <title>Dunamis Travels Ghana</title>
        <meta property="og:image" content= 'http://superjcybs.com/Assets/images/sectors/Dunamis.jpg'/>
        <meta name="description" content='We provide you with Local & Internatural Manpower Recruitment, Passport, Airport Support, Hotel & Flight Bookings'/>
        <meta property="og:title" content='Dunamis Travels Ghana'/>
        <meta property="og:description" content='We provide you with Local & Internatural Manpower Recruitment, Passport, Airport Support, Hotel & Flight Bookings'/>
        <meta property="og:url" content='https://superjcybs.com/dunamistravels' />
        <meta name="twitter:title" content='Dunamis Travels Ghana'/>
        <meta name="twitter:description" content='We provide you with Local & Internatural Manpower Recruitment, Passport, Airport Support, Hotel & Flight Bookings'/>
      </Helmet>
      {loading && <Loading />}
      <div className='dtpage'>
        <DTheader />
        <div id='dtmain' >
          <Posts posts={posts} />
        </div>
        <Pagination totalPage={totalPage} />
        <Footer />
      </div>
    </>
  )
}

export default DunamisTravels