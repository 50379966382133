import React from 'react'
import { Link } from 'react-router-dom'
import './Services2.css'
const Services2 = () => {
    return (
        <div>
            <section id="service">
                <div className="container">
                    <div className="section-title text-center">
                        <h2>Services</h2>
                        <p>We've been building unique digital products, platforms, and experiences for the past 4 years</p>
                    </div>

                    <div className="row">
                        <div className="col-md-4 animated fadeInUp">
                            <div className="service-box animated fadeInDown">
                                <div className="service-ico">
                                    <img src="Assets/images/Services/buscon.jpg" alt="Business Consultancy" />
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Business Consultanty Service</h2>
                                    <p className="s-description text-center">
                                        We have professionals in Project Management,
                                        Human Resource Management, Customer Relations Management
                                        Supply Chain Management and Online Investments.
                                    </p>
                                    <Link to="/services/business-consultancy"> <button className='text-center btn btn-primary'>Enquire More</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <img src="Assets/images/Services/webdev.jpg" alt="Web Development" />
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Web Development</h2>
                                    <p className="s-description text-center">
                                        Get a stunning websites with us.
                                        From E-commerce, showcase, landing pages, corporate, Entertainment...<br/>
                                        For all; Large, Medium and Small Businesses.<br/>
                                        Come what may, we have a unique service for you
                                    </p>
                                    <Link to="/blog/post/web-development-service"><button className='text-center btn btn-primary'>Enquire More</button></Link>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <img src="Assets/images/Services/digmarket.jpg" alt="Digital Marketing" />
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Digital Marketing</h2>
                                    <p className="s-description text-center">
                                        Call on us when you need help with marketing your business venture online,
                                        From Social Media Marketing, SEO, Email Marketing,
                                        Advertising, Campaigns, Content Mgt, Sales, Promotions etc.
                                    </p>
                                    <Link to=""> <button className='text-center btn btn-primary'>Enquire More</button></Link>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <img src="Assets/images/Services/mobapp3.jpg" alt="Mobile App Development" />
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Mobile App Development</h2>
                                    <p className="s-description text-center">
                                        Order for our mobile applications built to standard, comes with support 24/7.
                                        Because we are keen to given you the very best in functionality.
                                    </p>
                                    <Link to="/blog/post/mobile-app-development-service"><button className='text-center btn btn-primary'>Enquire More</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <img src="Assets/images/Services/Graphics.jpg" alt="Graphic & Web Design" />
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Graphic & Web Design</h2>
                                    <p className="s-description text-center">
                                        Let us serve you with amazing banners and flyers that meets all your business needs,
                                        from logo, banners, stickers, flyers, branding, mockups, 2D, 3D, Web Designs
                                    </p>
                                    <Link to="/blog/post/graphic-and-web-design-serivce"><button className='text-center btn btn-primary'>Enquire More</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <img src="Assets/images/Services/comtrain.jpg" alt="Computer Training" />
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Computer Training</h2>
                                    <p className="s-description text-center">
                                        We will train you to do just as we do,
                                        Yes we continue until we see you have become Link replica of us
                                        and even more...
                                        We've got you covered and you will enjoy it
                                    </p>
                                    <Link to="/blog/post/computer-training-service"> <button className='text-center btn btn-primary'>Enquire More</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <img src="Assets/images/Services/hrscm.jpg" alt="HR with Supply Chain Management" />
                                </div>
                                <div className="service-content">
                                    <h3 className="s-title">Human Resource & Supply Chain Management</h3>
                                    <p className="s-description text-center">
                                        Need business management help?  <br />
                                        Let us be the team to manage (charge-up) your human resources as well as keep your supply system consistent.
                                    </p>
                                    <Link to="/blog/post/human-resource-and-supply-chain-management"> <button className='text-center btn btn-primary'>Enquire More</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <img src="Assets/images/Services/bulksms.png" alt="Bulk Messaging" />
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Bulk Messaging</h2>
                                    <p className="s-description text-center">
                                        We will train you to do just as we do,
                                        Yes we continue until we see you have become a replica of us
                                        and even more... We've got you covered
                                    </p>
                                    <Link to="/blog/post/bulk-messaging-service"><button className='text-center btn btn-primary'>Enquire More</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <img src="Assets/images/Services/projMgt.jpg" alt="Project Management" />
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Project Management</h2>
                                    <p className="s-description text-center">
                                        We handle the projects of most of our mentees in the process of mentoring them to be up and ready to stand and orchestrate their business establishments the way they want it to go or grow.
                                    </p>
                                    <Link to="/blog/post/human-resource-and-supply-chain-management"><button className='text-center btn btn-primary'>Enquire More</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services2