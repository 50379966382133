import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Header from './Header';
import PostCard from './PostCard';
import PostWidget from './PostWidget';
import Categories from './Categories';
import Pagination from './Pagination';
import { getSearchedPosts } from '../../API/BlogStore';


const BlogSearchResult = () => {
  const [Posts, setPosts] = useState([]);
  const [totalPage, setTotalPage] = useState(1);


  const { search } = useLocation();
  const page = Number(new URLSearchParams(search).get('page')) || 1;

    const { slug } = useParams();

    useEffect(() => {
      async function SearchResult(){
        const { edges, aggregate } = await getSearchedPosts(slug, page)
        setTotalPage(aggregate.count)
        setPosts(Array.isArray(edges) ? edges : []);
      }
      SearchResult()
    }, [slug, page])
  
    console.log(Posts)

  return (
    <>
    <Header/>
      <div className="container">
        <div className='blog__contentLeft'>
          {Posts.map((post, index) => (
            <PostCard post={post.node} key={index} />
          ))}
          <br />
          <a href="https://bit.ly/superjcybsonppl" className='d-flex justify-content-center'>
            <img src="http://cashsuperstar.com/ppl/images/makemoneynow728x90.jpg" style={{ width: '80vw' }} alt="Want To Increase Your Online Income? Then Get This System..." />
          </a>
          <br />
        </div>

        <div className="blog__contentRight">
          <div className="relative">
            <PostWidget />
            <Categories />
          </div>
        </div>
      </div>
      <Pagination setPosts={setPosts} totalPage={totalPage} />
    </>
  )
}

export default BlogSearchResult