import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { getCategoryPost } from '../../API/BlogStore';
import Categories from './Categories';
import PostCard from './PostCard';
import './CatPage.css'
import Pagination from './Pagination';
import Header from './Header';

const CatPage = () => {

    const [Posts, setPosts] = useState([]);
    const [totalPage, setTotalPage] = useState(1);

    const {slug} = useParams()
    const { search } = useLocation();
    const page = Number(new URLSearchParams(search).get('page')) || 1;
    const postsRef = useRef(Posts); // Keep a ref to track previous posts
    
    useEffect(() => {
        async function CatPostsList() {
            const { edges, aggregate } = await getCategoryPost(slug, page)
            setTotalPage(aggregate.count)
            setPosts(Array.isArray(edges) ? edges : []);
        }
        CatPostsList()
    }, [slug, page])

    // Logging only when Posts change
    useEffect(() => {
        if (postsRef.current !== Posts) {
            postsRef.current = Posts; // Update the ref to the current Posts
        }
    }, [Posts]);
    return (
        <>
        <Header />
        <div className="container">
            <div className="catpage__inner">
                <div className="catpage__left">
                    {Posts.map((post, index) => (
                        <PostCard key={index} post={post.node} />
                    ))}
                </div>
                <div className="catpage__right">
                    <div className="catpage__cat">
                        <Categories />
                    </div>
                </div>
            </div>
            <Pagination setPosts={setPosts} totalPage={totalPage} />

        </div>
        </>
    )
}

export default CatPage