import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import { publishReply, submitComment } from '../../API/BlogStore';
import { toast } from 'react-toastify';

const ReplyForm = ({ addReply, parentId, slug }) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)

    const [reply, setReply] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const handleReply = async (e) => {
        e.preventDefault();
        setLoading(true)

        const replyData = {
            name,
            email, 
            comment:reply,
            slug, 
            parentCommentId: parentId
        };
        try {
            const result = await submitComment(replyData);
            console.log('Reply result: ',result)
            if(result){
                const pubReply = await publishReply(result.id);

                console.log('Publish result: ',pubReply)
                if (!pubReply===null){
                    
                    setSuccess(true)
                    addReply(false)
                    console.log('pass1')
                    sendEmail()
                    console.log('pass2')
                    setReply('')
                    console.log('pass3')
                    setName('');
                    console.log('pass4')
                    setEmail('');
                    console.log('pass5')
                }else{
                    setSuccess(false)
                }
            } else {
                setSuccess(false)
            }
        } catch (error) {
            toast.error('Error posting reply', error)
            setSuccess(false)

        } finally{
            setLoading(false)
        }
        }


    const sendEmail = (e) => {
        const templateParams = {
            user_name: name,
            user_email: email,
            comment: reply,
            type: "Reply",
            message: "Not Applicable",
            subject: "Not Applicable",
            platform: "GET INFORMED BLOG",
        };
        emailjs
            .send('service_qxsvb5t', 'template_zgr4cxj', templateParams, { publicKey: 'ibZjaF1rjAsvzDVGf' })
            .then(
                (result) => {
                    setSuccess(true)
                    setTimeout(() => {
                        setSuccess(null)
                    }, 5000);
                },
                (error) => {
                    setSuccess(false);
                    setTimeout(() => {
                        setSuccess(null)
                    }, 5000);
                });
    };

    return (
        <>
            <form className='ms-5' onSubmit={handleReply}>
                <Row className=' ms-5'>
                    <textarea
                        value={reply}
                        name='reply'
                        placeholder="Reply"
                        // cols="30"
                        // rows="2"
                        required
                        autoComplete='off'
                        onChange={e => setReply(e.target.value)}
                    >
                    </textarea>
                </Row>

                <Row>
                    <Col md className=''>
                        <Form.Control
                            type="text"
                            name='user_name'
                            value={name}
                            required
                            autoComplete='off'
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name" />
                    </Col>
                    <Col md className=''>
                        <Form.Control
                            type="email"
                            name='user_email'
                            required
                            autoComplete='off'
                            placeholder='Email'
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </Col>
                </Row>
                {(success === true) ?
                    (<Alert variant='success'>Reply posted Successfully. Thank you!</Alert>)
                    :
                    ((success === false) ? (<Alert variant='danger'>Sorry, posting error, retry</Alert>)
                        :
                        (''))
                }
                <button className='mx-auto' type="submit" disabled={loading} >{loading ? 'Loading...' : 'Post Reply'}</button>
            </form>
        </>
    )
}

export default ReplyForm