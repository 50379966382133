import React from 'react'
import './SocialFeeds.css'

const SocialFeeds = () => {
  //       const { share, isLoading, error } = useShare();

  //   async function handleShare() {
  //     await share({
  //       href: 'http://www.facebook.com',
  //     });
  //   }
  return (
    <div className='p-4'>
      <div className="section-title text-center">
        <h2>Social Media Feeds - SUPERJCYBS</h2>
      </div>
      <iframe className='mx-2' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsuperjcybs&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=686871576266039" width="300" height="500" style={{ border: 'none', overflow: 'hidden' }} scroll="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" title='superjcybs'></iframe>
      <iframe className='mx-2' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDunamisTravels1&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=686871576266039" width="300" height="500" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" title='Dunamis Travels'></iframe>
      <iframe className='mx-2' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fts4humanity&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=686871576266039" width="300" height="500" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" title='TSBF TV'></iframe>
      <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftsbftv&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=686871576266039" width="300" height="500" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" title='TS4 Humanity'></iframe>
      {/* <div className="fb-page" data-href="https://www.facebook.com/superjcybs" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/superjcybs" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/superjcybs">Super J Cy-Business &amp; Services</a></blockquote></div>
      <div className="fb-page" data-href="https://www.facebook.com/superjcybs" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/superjcybs" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/superjcybs">Super J Cy-Business &amp; Services</a></blockquote></div>
      <div className="fb-page" data-href="https://www.facebook.com/superjcybs" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/superjcybs" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/superjcybs">Super J Cy-Business &amp; Services</a></blockquote></div>
      <div className="fb-page" data-href="https://www.facebook.com/superjcybs" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/superjcybs" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/superjcybs">Super J Cy-Business &amp; Services</a></blockquote></div> */}
    </div>
  )
}

export default SocialFeeds