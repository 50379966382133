import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getCategories } from '../../API/BlogStore';
import './Categories.css'

const Categories = () => {
   
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories()
      .then((newCategories) => {
      setCategories(newCategories);
    });
  }, []);
  
  // console.log('4rm Categories',categories)
  
  return (
    <div id='categories'>
      <h3 className='categories__head'>Categories</h3>
      
{categories.map((category, index) => (
  <Link to={`/blog/category/${category.slug}`} key={index}>
    <span className='categories__item text-dark'>{category.name}</span>
  </Link>
  
))}

    </div>
  )
}

export default Categories