import React from 'react'
import moment from 'moment';
import Image from 'react-bootstrap/Image'
import { Link } from 'react-router-dom';
import './FeaturedPostCard.css'

const FeaturedPostCard = ({post}) => {
  return (
      <div>
          <div className="featuredpostcard">
              <div className="featuredpostcard__1" style={{ backgroundImage: `url('${post.featuredImage.url}')` }} />
              <div className="featuredpostcard__2" />
              <Link to={`/blog/post/${post.slug}`}>
              <div className="featuredpostcard__info flex flex-col rounded-lg p-4 items-center justify-center absolute w-full h-full">
                  <p className="featuredpostcard__period">{moment(post.createdAt).format('MMM DD, YYYY')}</p>
                  <p className="featuredpostcard__title">{post.title}</p>
                  <div className="featuredpostcard__aut">
                      <Image
                          unoptimized="true"
                          alt={post.author.name}
                          height="30px"
                          width="30px"
                          className="featuredpostcard__img"
                          src={post.author.photo.url}
                      />
                      <p className="featuredpostcard__autname">{post.author.name}</p>
                  </div>
              </div>
              <span className="featuredpostcard__link" /></Link>
          </div>
    </div>
  )
}

export default FeaturedPostCard