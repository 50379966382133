import React, { useContext, useState } from 'react'


export const DTStore = React.createContext();

export const ContextProvider = ({children})=>{
  const [loading, setLoading]=useState(false);
  const [totalPage, setTotalPage]=useState(1);
  // console.log('TTpage4rmStore',totalPage)

const value = {loading, setLoading, totalPage, setTotalPage}
  return (
    <DTStore.Provider value={value}>
{children}
    </DTStore.Provider>
  )
}

export const useMyContext=()=>useContext(DTStore)