import React from 'react';
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BackToTop from './components/common/BackToTop';
import Header from './components/common/Header';
import Footer from './components/common/Footer';

import BlogPage from './components/blog/BlogPage';
import PostView from './components/blog/PostView';
import CatPage from './components/blog/CatPage';

import CVjerry from './components/CV/CVjerry';
import CVjoseph from './components/CV/CVjoseph';
import CVmillicent from './components/CV/CVmillicent';
import CVisaac from './components/CV/CVisaac';
import CVeunice from './components/CV/CVeunice';

import Home from './Views/Home'
import AboutUs from './Views/AboutUs';
import NotFound from './Views/NotFound';
import PortfolioPage from './Views/PortfolioPage';
import ServicesPage from './Views/ServicesPage';
import Privacy from './Views/Privacy';
import Terms from './Views/Terms';
import Courses from './Views/Courses';
import Features from './Views/Features';
import Pay from './Views/Pay';
import CoursePage from './Views/CoursePage';
import EarningWays from './Views/EarningWays';
import FreeInternetTools from './Views/FreeInternetTools';


import TSBFGH from './ExtWebsites/TSBFGH';
import TSTelecom from './ExtWebsites/TSTelecom'
import CrownedBrainsIPS from './ExtWebsites/CrownedBrainsPTI'
import EstGyimFarms from './ExtWebsites/EstGyimFarms'
import FlashSolveFMS from './ExtWebsites/FlashSolveFMS'
import IWINyec from './ExtWebsites/IWINyec'
import SuperCityAccodationPub from './ExtWebsites/SuperCityAccomodationPub'
import TS4Humanity from './ExtWebsites/TS4Humanity'
import TSConstructionID from './ExtWebsites/TSConstructionID'
import TSIndustries from './ExtWebsites/TSIndustries'
import TStransportServices from './ExtWebsites/TStransportServices'
import Supamart from './ExtWebsites/SupaMartGH'
import DunamisTravels from './ExtWebsites/DunamisTravels';
import WealthMakingNetwork from './Views/WealthMakingNetwork';
import TSMicrofinance from './ExtWebsites/TSMicrofinance';
import TSCateringServices from './ExtWebsites/TSCateringServices';

import DTpostView from './ExtWebsites/DunamisTravels/pages/DTPostView';
import DTsearchResult from './ExtWebsites/DunamisTravels/pages/DTSearchResult';
import DTpostByCategory from './ExtWebsites/DunamisTravels/pages/DTPostsByCategory';
import DTregistration from './ExtWebsites/DunamisTravels/pages/DTregistration';
import DTheader from './ExtWebsites/DunamisTravels/components/DTheader';
import DTFooter from './ExtWebsites/DunamisTravels/components/DTFooter';
import DTPagination from './ExtWebsites/DunamisTravels/components/DTPagination';
import BlogSearchResult from './components/blog/BlogSearchResult';

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        // transition:Bounce
      />
      <Routes>
        <Route path="/" element={<> <Header /><Home />  <Footer />  </>} ></Route>
        <Route path="/about-us" element={<AboutUs /> } ></Route>
        <Route path="/earningways" element={<> <Header /><EarningWays />  <Footer />  </>} ></Route>
        <Route path="/freeinternettools" element={<> <Header /><FreeInternetTools />  <Footer />  </>} ></Route>

        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/post/:slug" element={<PostView />} />
        <Route path="/blog/category/:slug" element={<CatPage />} />
        <Route path='/blog/search/:slug' element={<BlogSearchResult/>} />

        <Route path='/cv=jerry-babatunde (Jerry Kwophi Supernatural)' element={<CVjerry />} />
        <Route path='/cv=isaac-amoasi' element={<CVisaac />} />
        <Route path='/cv=millicent-asomani' element={<CVmillicent />} />
        <Route path='/cv=joseph-effraim' element={<CVjoseph />} />
        <Route path='/cv=eunice-maame-esi-supernatural' element={<CVeunice />} />

        <Route path="/portfolio" element={<>  <Header /> <PortfolioPage /><Footer /></>} />
        <Route path="/features" element={<Features />} />
        <Route path="/pay" element={<><Header /> <Pay /> <Footer /></>} />

        <Route path="/wealthmakingnetwork" element={<WealthMakingNetwork />} />
        <Route path="/wealthmakingnetwork/register" element={<WealthMakingNetwork />} />
        <Route path="/wealthmakingnetwork/login" element={<WealthMakingNetwork />} />
        <Route path="/wealthmakingnetwork/profile" element={<WealthMakingNetwork />} />
        <Route path="/wealthmakingnetwork/dashboard" element={<WealthMakingNetwork />} />

        <Route path="/services" element={<><Header /><ServicesPage /> <Footer /> </>} />
        <Route path="/services/:id" element={<><Header /><ServicesPage /><Footer /></>} />

        <Route path="/courses" element={<> <Header /> <Courses /><Footer /> </>} />
        <Route path="/courses/:id" element={<CoursePage />} />

        <Route path="/tsbf-ghana" element={<TSBFGH />} />
        <Route path='/tsbf-ghana/sector=TSTelecom' element={<TSTelecom />} />
        <Route path='/tsbf-ghana/sector=CrownedBrainsPTI' element={<CrownedBrainsIPS />} />
        <Route path='/tsbf-ghana/sector=EstGyimFarms' element={<EstGyimFarms />} />
        <Route path='/tsbf-ghana/sector=FlashSolveFMS' element={<FlashSolveFMS />} />
        <Route path='/tsbf-ghana/sector=IWINYEC' element={<IWINyec />} />
        <Route path='/tsbf-ghana/sector=SuperCityAccomodationHub' element={<SuperCityAccodationPub />} />
        <Route path='/tsbf-ghana/sector=TS4Humanity' element={<TS4Humanity />} />
        <Route path='/tsbf-ghana/sector=TSConstructionID' element={<TSConstructionID />} />
        <Route path='/tsbf-ghana/sector=TSTransport' element={<TStransportServices />} />
        <Route path='/tsbf-ghana/sector=TSIndustries' element={<TSIndustries />} />
        <Route path='/dunamistravels' element={<DunamisTravels />} />
        <Route path='/tsbf-ghana/sector=supamart' element={<Supamart />} />
        <Route path='/tsbf-ghana/sector=tsmicrofinance' element={<TSMicrofinance />} />
        <Route path='/tsbf-ghana/sector=tscateringservices' element={<TSCateringServices />} />

        <Route path='/dunamistravels/posts/:slug' element={<><DTheader/><DTpostByCategory/><DTPagination/><DTFooter/></>} />
        <Route path='/dunamistravels/article/:slug' element={<><DTheader/><DTpostView/><DTPagination/><DTFooter/></>} />
        <Route path='/dunamistravels/search/:slug' element={<><DTheader/><DTsearchResult/><DTPagination/><DTFooter/></>} />
        <Route path='/dunamistravels/registration' element={<DTregistration />} />

        <Route path="/privacy" element={<><Header /> <Privacy /> <Footer /> </>} />
        <Route path="/terms" element={<> <Header /> <Terms /> <Footer /></>} />
        <Route path='*' element={<><NotFound /><Footer /></>} />
      </Routes>
      <BackToTop />
    </>
  );
}

export default App;
