import React, { useState } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './BackToTop.css'


const BackToTop = () => {

    const [visible, setvisible] = useState(false)

    window.onscroll = function () { scrollFunction() }
    function scrollFunction() {
        if (document.documentElement.scrollTop > 40 || document.documentElement.scrollTop > 40) {
            setvisible(true);
        } else {
            setvisible(false);
        }
    }

    return (
        <div>
            <a href="https://wa.me/+233247792110?text=Hi%20Super%20J%20,%20I%20want%20to%20make%20enquiries%20on%20" className="whatsapp"><i><WhatsAppIcon fontSize='large' /></i></a>
            <a aria-label="Chat on WhatsApp" href='https://wa.me/+233247792110'><img alt='Chat on WhatsApp' src='WhatsAppButtonGreenLarge.svg'/></a>
            <span onClick={()=>document.documentElement.scrollTop=0} style={{ display: visible ? 'inline' : "none" }} className="back-to-top" ><i> <KeyboardArrowUpIcon fontSize='large' /></i></span>
        </div>
    )
}

export default BackToTop