import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Image from 'react-bootstrap/Image'
import { Link } from 'react-router-dom'
import { getRecentPosts, getSimilarPosts } from '../../API/BlogStore'
import './PostWidget.css'

const PostWidget = ({ categories, slug }) => {
  const [relatedPosts, setRelatedPosts] = useState([])

  useEffect(() => {
    if (slug) {
      getSimilarPosts(categories,slug)
      .then((result)=>setRelatedPosts(result))
    } else {
      getRecentPosts()
      .then((result)=>setRelatedPosts(result))
    }
  }, [slug, categories])

  // console.log('relatedPosts',relatedPosts);
  return (
    <div id='postwidget'>
      <h3>
        {slug ? 'Related Posts' : 'Recent Posts'}
      </h3 >

      {
        relatedPosts.map((post, index) => (
          <div className='postwidget__details' key={index}>
            <div className='postwidget__image'>
              <Image
                // loader={graphCMSImageLoader}
                alt={post.title}
                height="60px"
                width="60px"
                unoptimized='true'
                style={{ borderRadius: '9999px', verticalAlign: 'middle' }}
                src={post.featuredImage.url||''}
              />
            </div>
            <div className='postwidget__extras'>
              <p>{moment(post.createdAt).format('MMM DD, YYYY')}</p>
              <Link to={`/blog/post/${post.slug}`} style={{ fontSize: '0.8rem', color: 'black' }} key={index}>{post.title}</Link>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default PostWidget