import React from 'react'
// import { useParams } from 'react-router-dom'
import { QUERY_ARTICLE } from '../Graphql/Queries';
import Post from '../components/DTPost';
import Comments from '../components/DTComments';
import UseQueryPosts from '../Hooks/UseQueryPosts';

const DTPostView = () => {
  const { posts, error } = UseQueryPosts({ query: QUERY_ARTICLE })

  if (error) return <h2 style={{ textAlign: 'center' }}>{error}</h2>

  // const {slug} = useParams();
  // const [posts, setPosts]=useState([]);

  // useEffect(() => {
  //   graphcms.request(QUERY_ARTICLE,{slug})
  //   .then(res=>setPosts(res.posts))
  // }, [slug])

  return (
    <div className='dtpost-container'>
      {
        posts?.map(post => (
          <React.Fragment key={post.id}>
            <Post post={post} />
            <Comments comments={post?.comments} />
          </React.Fragment>
        ))
      }
    </div>
  )
}

export default DTPostView