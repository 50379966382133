import React from 'react'
import { QUERY_POSTS_BY_CATEGORY } from '../Graphql/Queries';
import Posts from '../components/DTPosts';
import UseQueryPosts from '../Hooks/UseQueryPosts';
import NotFound from '../components/DTNotFound';


const PostsByCategory = () => {
    const {posts, error, status} = UseQueryPosts({query:QUERY_POSTS_BY_CATEGORY,limit:2})   

    if(status === 'sucess' && posts.length === 0) return <NotFound/>  
  if(error)return <h2 style={{textAlign:'center'}}>{error}</h2>        

  // console.log('at pBCat', posts)
  // const { slug } = useParams();
  // const [posts, setPosts] = useState([]);

  // useEffect(() => {
  //   graphcms.request(QUERY_POSTS_BY_CATEGORY, { slug })
  //     .then(res => setPosts(res.posts))
  // }, [slug])

  // console.log({posts})

  return (
    <React.Fragment>
      <Posts posts={posts} />
    </React.Fragment>
  )
}

export default PostsByCategory