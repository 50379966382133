import React, { useEffect } from 'react'

const ServicesPage = () => {
  useEffect(() => {
    document.title = "Our Services - SUPERJCYBS"
  }, [])
  return (
    <div>
      <h1 id='graphicsdesign' className='text-center text-danger'>GRAPHICS DESIGNING</h1>
<p style={{minHeight:"100vh"}}>For Graphics Design</p>
      <h1 id='graphicsdesign' className='text-center text-danger'>WEB DESIGNING UI</h1>
<p style={{minHeight:"100vh"}}>For Web Design</p>

      <h1 id='web-development' className='text-center text-danger'>WEB DEVELOPMENT</h1>
      <p style={{minHeight:"100vh"}}>For Web Development</p>

      <h1 id='project-management' className='text-center text-danger'>PROJECT MANAGEMENT</h1>
      <p style={{minHeight:"100vh"}}>For Project Management</p>

<p>Showing for project management</p>
      <h1 id='digital-marketing' className='text-center text-danger'>DIGITAL MARKETING</h1>
      <p style={{minHeight:"100vh"}}>For Digital Marketing</p>

      <h1 id='mobile-app-development' className='text-center text-danger'>MOBILE APP DEVELOPMENT</h1>
      <p style={{minHeight:"100vh"}}>For Mobile App Development</p>

      <h1 id='computer-training' className='text-center text-danger'>COMPUTER TRAINING</h1>
      <p style={{minHeight:"100vh"}}>For Computer Training</p>

      <h1 id='hrmgt' className='text-center text-danger'>HUMAN RESOURCE MANAGEMENT</h1>
      <p style={{minHeight:"100vh"}}>For Human Resource Management</p>

      <h1 id='supplychainmgt' className='text-center text-danger'>SUPPLY CHAIN MANAGEMENT</h1>
      <p style={{minHeight:"100vh"}}>For Supply Chain Management</p>

      <h1 id='bulk-messaging' className='text-center text-danger'>BULK MESSAGING</h1>
      <p style={{minHeight:"100vh"}}>For Bulk Messaging</p>

    </div>
  )
}

export default ServicesPage