import React, {useState } from 'react'
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './Portfolio.css'
import MydModalWithGrid from '../common/Modal';
import { Link } from 'react-router-dom';

const Portfolio = () => {
  const [modalShow, setModalShow] = useState(false);
  const [image, setImage] = useState(false);

  
  const filterExe = (e) => {
    e.preventDefault()
    const filter = e.target.dataset.filter;

    const ChosenPortfolio = document.querySelectorAll('.portfolio-item');
    const filterButton = document.querySelectorAll('.fitbtn');

    const changeActiveItem = () => {
      filterButton.forEach(itSel => {
        itSel.classList.remove('filter-active')
      })
    }

    filterButton.forEach(btnSel => {
      btnSel.addEventListener('click', () => {
        changeActiveItem()
        btnSel.classList.add('filter-active')
      })
})


    ChosenPortfolio.forEach((item) => {
        if (filter === 'all') {
          item.style.display = 'block'
        }      
        else {
          if (item.classList.contains(filter)) {
            item.style.display = 'block'
          } else {
            item.style.display = 'none'
          }
        }
      })
    };
    

  return (
    <>
      <MydModalWithGrid show={modalShow} onHide={() => setModalShow(false)} content={
        <img class="embed-responsive-item" src={image} alt='our services' style={{ border: "1px solid #ddd", borderRadius: "4px" }} />

      } />

      <section id="portfolio" className="portfolio">
        <div className="container">
          <div className="section-title text-center" data-aos="fade-up" >
            <h2>Our Portfolio</h2>
            <p>Check out our beautiful portfolio</p>
          </div>

          <div className="row" data-aos="fade-up" data-aos-delay={100}>
            <div className="col-lg-12">
              <ul id="portfolio-flters">
                <li onClick={filterExe} className="fitbtn filter-active" data-filter="all">All</li>
                <li onClick={filterExe} className="fitbtn" data-filter="filter-app">App</li>
                <li onClick={filterExe} className="fitbtn" data-filter="filter-marketing">Marketing</li>
                <li onClick={filterExe} className="fitbtn" data-filter="filter-web">Web</li>
                <li onClick={filterExe} className="fitbtn" data-filter="filter-graphics">Graphics</li>
                <li onClick={filterExe} className="fitbtn"  data-filter="filter-trainings">Trainings</li>
              </ul>
            </div>
          </div>
          <div className="row portfolio-container text-center" data-aos="fade-up" data-aos-delay={200}>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphics">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/ad3.jpg" className="img-fluid" alt='chop better superjcybs' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/ad3.jpg")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Chop Better Food Joint</h4>
                  <p>@ 2018</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphics">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/ad4.jpg" className="img-fluid" alt='learn graphics design' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/ad4.jpg")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Christ Embassy Apam flyer</h4>
                  <p>@ 2019</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-marketing">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/ad5.jpg" className="img-fluid" alt='product deals - supamart ghana' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/ad5.jpg")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>SupaMart Gh</h4>
                  <p>Online Store</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-trainings">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/freecoding.png" className="img-fluid" alt='free coding advert - crowned brains PTI' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/freecoding.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Free Coding Online</h4>
                  <p>visit Features for more</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-trainings">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/whatsapp.png" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/whatsapp.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Graphics Design course</h4>
                  <p>visit Our Courses for more</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/services.png" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/services.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>App 3</h4>
                  <p>App</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-marketing">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/ad6.png" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/ad6.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Card 1</h4>
                  <p>Card</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphics">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/ad2.jpg" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/ad2.jpg")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>church program</h4>
                  <p>Banner</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/adpay.png" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/adpay.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Web 3</h4>
                  <p>Web</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/Domain.png" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/Domain.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Web 3</h4>
                  <p>Web</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/website.jpg" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/website.jpg")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Free Tutorials</h4>
                  <p>Tutorial Video</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphics">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/aborrba.png" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/aborrba.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>The Bride Clothing</h4>
                  <p>3D LOGO</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphics">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/joysNaming.png" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/joysNaming.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Joy's Naming</h4>
                  <p>invitation</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphics">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/degree.png" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/degree.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span> 
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link> 
                </div>
                <div className="portfolio-info">
                  <h4>Get a Degree from home</h4>
                  <p>Flyer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphics">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/doroDelicious.jpg" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/doroDelicious.jpg")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Doro's Delicious Snacks</h4>
                  <p>Flyer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphics">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/doroDelicious1.png" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/doroDelicious1.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Doro's Delicious Snacks</h4>
                  <p>Flyer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphics">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/FAddoEnterprise.png" className="img-fluid" alt='a' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/FAddoEnterprise.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>F. Addo Enterprise</h4>
                  <p>Flyer</p>
                </div>
              </div>
            </div>
{/* ---------------------------------------------------------------------------- */}
            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/adweb.png" className="img-fluid" alt='Web Devt Logo' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/adweb.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Get a Website</h4>
                  <p>WEB</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphics">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/adlogo.png" className="img-fluid" alt='Logo Advert' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/adlogo.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Get a logo</h4>
                  <p>2D LOGO</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphics">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/adcomm.png" className="img-fluid" alt='Data Commission flyer' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/adcomm.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Data Commission</h4>
                  <p>GRAPHICS</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-marketing">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/addatabundle.png" className="img-fluid" alt='Data Bundle flyer' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/addatabundle.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Buy Data bundles</h4>
                  <p>MARKETING</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-trainings">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/advircard.png" className="img-fluid" alt='Virtual Card Advert' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/advircard.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Get a virtual card</h4>
                  <p>FREE</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-trainings">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/learnevd.png" className="img-fluid" alt='Learn EVD' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/learnevd.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Learn Evd</h4>
                  <p>FREE EVD Training</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/wmninner.jpg" className="img-fluid" alt='WMN Inner circle' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/wmninner.jpg")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>The Bride Clothing</h4>
                  <p>3D LOGO</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphic">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/mydatewiththebible.png" className="img-fluid" alt='WMN Inner circle' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/mydatewiththebible.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>The Bride Clothing</h4>
                  <p>3D LOGO</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-trainings">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/learndata.png" className="img-fluid" alt='Learn Data sales' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/learndata.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Learn Data Bundle</h4>
                  <p>Training</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-marketing">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/combined.png" className="img-fluid" alt='Network Marketing' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/combined.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Network Market</h4>
                  <p>Marketing</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-marketing">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/advertisejob.png" className="img-fluid" alt='Advertise Jobs - superjcybs' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/advertisejob.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Advertise Your Jobs</h4>
                  <p>Marketing</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-marketing">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/resultchecking.png" className="img-fluid" alt='result checking - superjcybs' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/resultchecking.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Result Checking</h4>
                  <p>Marketing</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-marketing">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/americalottery.png" className="img-fluid" alt='America Lottery-superjcybs' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/americalottery.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>America Lottery</h4>
                  <p>Marketing</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-marketing">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/paychannel.png" className="img-fluid" alt='Payment Channel' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/paychannel.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Payment Channel</h4>
                  <p>Marketing</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphic">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/mead.png" className="img-fluid" alt='Weekly Exclusive Meeting - superjcybs' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/mead.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Weekly Exclusive Meeting</h4>
                  <p>Graphic</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphic">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/eggs.png" className="img-fluid" alt='Eggs business - supamartgh' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/eggs.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Eggs for sale</h4>
                  <p>Graphic</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphic">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/bxd.jpg" className="img-fluid" alt='BXD business - superjcybs' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/bxd.jpg")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Eggs for sale</h4>
                  <p>Graphic</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-graphic">
              <div className="portfolio-wrap">
                <img src="Assets/images/works/fix-it-clinic.png" className="img-fluid" alt='fixitclinic - superjcybs' />
                <div className="portfolio-links">
                  <span onClick={() => {
                    setImage("Assets/images/works/fix-it-clinic.png")
                    setModalShow(true)
                  }}><i><VisibilityIcon /></i></span>
                  <Link to="/portfolio" title="More Details"><i><LinkIcon /></i></Link>
                </div>
                <div className="portfolio-info">
                  <h4>Fix It Clinic</h4>
                  <p>Graphic</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Portfolio