import React from 'react'
import { Link } from 'react-router-dom'
import './Pay.css'
import { Helmet } from 'react-helmet-async'

const Pay = () => {

  return (
    <>
      <Helmet>
        <title>Pay to Us - SUPERJCYBS</title>
        <meta name="description" content='Choose your preferred way to Pay to us' />
        <meta property="og:title" content='Pay to Us - SUPERJCYBS' />
        <meta property="og:description" content='Choose your preferred way to Pay to us' />
        <meta property="og:url" content='https://superjcybs.com/pay' />
        <meta name="twitter:title" content='Pay to Us - SUPERJCYBS' />
        <meta name="twitter:description" content='Choose your preferred way to Pay to us' />
      </Helmet>
      <div className="payments" style={{ background: 'linear-gradient(to right, rgba(166, 192, 188, 1),rgba(31, 161, 13, 0.9))', boxShadow: 'inset 0 0 3rem rgb(0, 0, 0/50%)', padding: '2rem' }}>
        <h1 style={{ fontSize: '2rem', marginBottom: '2rem', textAlign: 'center', lineHeight: '1.2', color: '#fff', fontWeight: 'bold', display: 'block' }}>
          PAY TO<br />
          {/* TO <br /> */}
          SUPER 'J' CY-BUSINESS AND SERVICES
        </h1>
        <div className="payment__modes" >
          <div className="payment__mode" style={{ display: 'flex', flexDirection: 'column', padding: '2rem', alignItems: 'center', gap: '0.5rem', background: 'rgba(255, 255, 155, 1.0)' }}>
            <h4 className='text-center fw-bold'>OPTION 0: UNIVERSAL PAYMENT (CARD, MOMO, WIRE)</h4>
            <div><a href="https://paystack.com/pay/buycoffeeforsuperj" style={{ color: 'blue' }}>Click Here To Pay</a></div>
            <br/>
            <h4 className='text-center fw-bold'>OPTION 1: PAYMENT THROUGH MOBILE MONEY</h4>
            Account Name: Super J Cy-Business And Services
            <h4 className='fw-bold'>MTN:</h4>
            <h5>Account Number: 0558443647</h5>
            <br/>
            <h4 className='fw-bold'>AIRTELTIGO:</h4>
            <h5>Account Number: <b>0579100909</b></h5>
            <h5>Agent Code: <b>98284</b></h5>
            <br />
            <h4 className='fw-bold'>TELECEL:</h4>
            <h5>Account (TILL) Number: <b>903342</b></h5>
            <h5>Agent Code: <b>172294</b></h5>
          </div>

          <div className="payment__mode" style={{ display: 'flex', flexDirection: 'column', padding: '2rem', alignItems: 'center', gap: '0.5rem', background: 'rgba(255, 255, 155, 1.0)' }}>
            <h4>OPTION 2: PAYMENT THROUGH BANK</h4>
            <h4 className='text-center fw-bold'>UBA GHANA</h4>
            Branch: Accra Kaneshie <br/>
            Account Number:  00638730702552 <br />
            Account Name: Jerry Babatunde<br />
            Swift code: STBGGHAC
            <br/>
            <br/>
            <h4 className='text-center fw-bold'>GUARANTEED TRUST BANK GHANA </h4>
            Branch: Graphic Road, Accra <br />
            Account Number: 2071110591590 <br />
            Account Name: Jerry Babatunde <br />
            Swift code: GTBIGHAC
            <br />
            <br/>
            <h4 className='text-center fw-bold'>OPPORTUNITY INTERNATIONAL SAVINGS AND LOANS</h4>
            Branch: Apam <br />
            Account Number: 1307301910117 <br />
            Account Name: Super 'J' Cy-Business And Services
          </div>
          <div className="payment__mode" style={{ display: 'flex', flexDirection: 'column', padding: '2rem', alignItems: 'center', gap: '0.5rem', background: 'rgba(255, 255, 155, 1.0)' }}>
            <h4 className='text-center fw-bold'>OPTION 3: PAYMENT WITH CRYPTOCURRENCY</h4>
            Binance ID: 169827774<br />
            BITCOIN: 1JiPvmovCeesw1GDZ6d7Bd9HCoGpZaAaZQ <br />
            ETHERUM: 0xeb589bf215455cf7d6bf2013ed6738f26c8f89b0<br/>
            USDT: 0xeb589bf215455cf7d6bf2013ed6738f26c8f89b0<br/>
            SOL: 5sbYFEeLM58haxzkPa3EVbJ6P6Mo2mgmtN6HzPrthNZx 
            <p>
              <i>If you dont have a wallet account already, </i>
            </p>
            <a href="https://www.binance.com/en/register?ref=169827774" style={{ color: 'blue' }}>Click to Register </a>
          </div>
          <div className="payment__mode" style={{ display: 'flex', flexDirection: 'column', padding: '2rem', alignItems: 'center', gap: '0.5rem', background: 'rgba(255, 255, 155, 1.0)' }}>
            <h4 className='fw-bold'>OPTION 4: PAYMENT THROUGH PAYPAL</h4>
            Email address: eunicemaameesisupernatural@gmail.com <br />Account Name: Sweet Dezerie Joy Roa Dalondonan
            <br />
            <br />
            Email address: info.superj@gmail.com <br />Account Name: SUPERJCYBS <br /> <a href="https://paypal.me/tsbfgh" style={{color:'blue'}}>Click to Pay</a>
          </div>
          <div className="payment__mode" style={{ display: 'flex', flexDirection: 'column', padding: '2rem', alignItems: 'center', gap: '0.5rem', background: 'rgba(255, 255, 155, 1.0)' }}>
            <h4>OPTION 5: PAYMENT THROUGH THIRD-PARTY APPS</h4>
            <h4 className='fw-bold'>BARTER/FLUTTERWAVE:</h4>
            <a href='https://dashboard.flutterwave.com/signup?referrals=RV149135' style={{ color: 'blue' }}>Click to Pay</a>
            <h4 className='fw-bold'>EXPRESSPAY</h4>
            <p>jerrybabatunde1@gmail.com</p>
            <h4 className='fw-bold'>PAYSTACK</h4>
            <p>
              <a href="https://paystack.com/pay/buycoffeeforsuperj" style={{ textDecoration: 'none',color: 'blue'}}>Click to Pay</a>
            </p>
            <h4 className='fw-bold'>BUY ME A COFFEE</h4>
            <p>
              <a href="https://buymeacoff.ee/superjcybs" style={{ textDecoration: 'none', color: 'blue' }}>Click to Pay</a>
            </p>
          </div>
          <div className="payment__mode" style={{ display: 'flex', flexDirection: 'column', padding: '2rem', alignItems: 'center', gap: '0.5rem', background: 'rgba(255, 255, 155, 1.0)' }}>
            <h4 className='fw-bold'>OPTION 6: PAYMENT THROUGH <br/>PERFECT MONEY</h4>
            <h4>Account Name: Mst Ayesha Kathun</h4>
            <h5>AccountID: 2163127</h5>
            <h5>Dollar Accout: U15102420</h5>
            <p>
              Don't have an account,
              <a href="http://perfectmoney.is/?ref=2163127" style={{ color: 'blue' }}> Click to Register</a>
            </p>
            <br />
            <h4 className='fw-bold'>USING PAYONEER</h4>
            <h5>Account Name: Jerry Babatunde</h5>
            <h5>AccountID: 43314940</h5>
            <p>
              Don't have an account,
              <a href="http://share.payoneer.com/nav/RC7uLjghaot2_BiC3Z_1ug2kFMDFS4Lz3y9JlgT2O8Hx2RCU2Y3OIAvO1ru9N0x0ivtumIm9r1O3zsr8NDJHyw2" style={{ color: 'blue' }}> Click to Register</a>
            </p>
            <br />
          </div>
        </div>
        <div className="contactus" style={{ display: 'flex', justifyContent: 'center', margin: '3rem 0' }}>
          <p>
            If you are a facing any challenge,{' '}
            <Link to="/#contact" style={{ color: 'blue' }}>Click Here to Ask for Support</Link>
          </p>
        </div>
      </div>

    </>
  )
}

export default Pay