import React from 'react'
import { Helmet } from 'react-helmet-async';

const CVisaac = () => {

  return (
    <>
      <Helmet>
        <title>CV - Isaac Amoasi</title>
        <meta name="description" content='Get intouch with me, lets deal on a professional level' />
        <meta property="og:title" content='CV - Isaac Amoasi' />
        <meta property="og:description" content='Get intouch with me, lets deal on a professional level' />
        <meta property="og:url" content='https://superjcybs.com/cv=isaac-amoasi' />
      </Helmet>
<p>CURRICULUM VITAE - Mr. Isaac Amoasi</p>
    </>
      )
}

export default CVisaac