import { gql, request, GraphQLClient } from 'graphql-request';

const graphqlAPI=process.env.REACT_APP_GRAPHQL_API
export const graphcms = new GraphQLClient(process.env.REACT_APP_GRAPHQL_API)

export const perPage = 5; // A.k.a limit

export const getPosts = async (page = 1) => {
  const skip = (page - 1) * perPage;

  const query = gql`
 query MyQuery ($first: Int!, $skip: Int!){
  postsConnection(orderBy: createdAt_DESC, first: $first, skip: $skip) {
    edges {
        cursor
      node {
        author {
          bio
          name
          id
          photo {
            url
          }
        }
        createdAt
        slug
        title
        excerpt
        featuredImage {
          url
        }
        categories {
          name
          slug
        }
      }
    }
       aggregate{
            count
          }
    }
    }
    `;
    
    const result = await request(graphqlAPI, query, { first: perPage, skip });
    return result.postsConnection
  }


export const getSearchedPosts = async (slug,page=1) => {
  const skip = (page - 1) * perPage;
  const query = gql`
    query getSearchedPosts($slug: String!, $first: Int!, $skip: Int!) {
      postsConnection(where: {_search: $slug}, orderBy: createdAt_DESC, first: $first, skip: $skip) {
        edges {
          cursor
          node {
            author {
              bio
              name
              id
              photo {
                url
              }
            }
            createdAt
            slug
            title
            excerpt
            featuredImage {
              url
            }
            categories {
              name
              slug
            }
          }
        }
       aggregate{
            count
          }
      }
    }
  `;

  const result = await request(graphqlAPI, query, {first:perPage, skip, slug });
  return result.postsConnection;
};
  
export const getPostDetails = async (slug) => {
  const query = gql`
    query GetPostDetails($slug : String!) {
      post(where: {slug: $slug}) {
        title
        excerpt
        featuredImage {
          url
        }
        author{
          name
          bio
          photo {
            url
          }
        }
        createdAt
        slug
        content {
          raw
        }
        categories {
          name
          slug
        }

      }
    }
  `;

  const result = await request(graphqlAPI, query, { slug });
  // const result = await request(graphqlAPI, query);
  return result.post;
};

export const graphCMSImageLoader = ({ src }) => src;

export const getRecentPosts = async () => {
  const query = gql`
  query GetPostDetails(){
    posts(orderBy:createdAt_DESC
      first:3
      ){
        title
        featuredImage{
          url
        }
        createdAt
        slug
      }
  }
  `
  const result = await request(graphqlAPI, query);
  return result.posts;
}

export const getFeed = async () => {
  const query = gql`
  query GetFeed(){
    posts(orderBy: createdAt_DESC
      first:3
      ){
        title
        excerpt
        featuredImage{
          url
        }
        createdAt
        slug
      }
  }
  `
  const result = await request(graphqlAPI, query);
  return result.posts;
}

export const getSimilarPosts = async (categories, slug) => {
  const query = gql`
    query GetPostDetails($slug: String!, $categories: [String!]) {
      posts(
        where: {slug_not: $slug, AND: {categories_some: {slug_in: $categories}}}
        last: 3
      ) {
        title
        featuredImage {
          url
        }
        createdAt
        slug
      }
    }
  `;
  const result = await request(graphqlAPI, query, { slug, categories });
  // const result = await request(graphqlAPI, query);
  return result.posts;
};

export const getCategories = async () => {
  const query = gql`
    query GetGategories {
        categories {
          name
          slug
        }
    }
  `;
  const result = await request(graphqlAPI, query);
  return result.categories;
};


export const getAdjacentPosts = async (createdAt, slug) => {
  const query = gql`
    query GetAdjacentPosts($createdAt: DateTime!,$slug:String!) {
      next:posts(
        first: 1
        orderBy: createdAt_ASC
        where: {slug_not: $slug, AND: {createdAt_gte: $createdAt}}
      ) {
        title
        featuredImage {
          url
        }
        createdAt
        slug
      }
      previous:posts(
        first: 1
        orderBy: createdAt_DESC
        where: {slug_not: $slug, AND: {createdAt_lte: $createdAt}}
      ) {
        title
        featuredImage {
          url
        }
        createdAt
        slug
      }
    }
  `;

  const result = await request(graphqlAPI, query, { slug, createdAt });

  return { next: result.next[0], previous: result.previous[0] };
};

export const getCategoryPost = async (slug,page=1) => {
  const skip = (page - 1) * perPage;
  const query = gql`
    query GetCategoryPost($slug: String!, $first: Int!, $skip: Int!) {
      postsConnection(where: {categories_some: {slug: $slug}}, orderBy: createdAt_DESC, first: $first, skip: $skip) {
        edges {
          cursor
          node {
            author {
              bio
              name
              id
              photo {
                url
              }
            }
            createdAt
            slug
            title
            excerpt
            featuredImage {
              url
            }
            categories {
              name
              slug
            }
          }
        }
       aggregate{
            count
          }
      }
    }
  `;

  const result = await request(graphqlAPI, query, {first:perPage, skip, slug });
  return result.postsConnection;
};


export const getFeaturedPosts = async () => {
  const query = gql`
    query GetCategoryPost() {
      posts(where: {featured: true},orderBy: createdAt_DESC) {
        author {
          name
          photo {
            url
          }
        }
        featuredImage {
          url
        }
        title
        slug
        createdAt
      }
    }   
  `;

  const result = await request(graphqlAPI, query);
  return result.posts;
};

export const submitComment = async (obj) => {
  const graphQLClient = new GraphQLClient((process.env.REACT_APP_GRAPHQL_API), {
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_GRAPHCMS_TOKEN}`,
    },
  });

  const createCommentQuery = gql`
    mutation CreateComment(
      $name: String!, 
      $email: String!, 
      $comment: String!, 
      $slug: String!
    ) {
      createComment(
        data: {
          name: $name,
          email: $email,
          comment: $comment,
          post: { connect: { slug: $slug } }
        }
      ) { id }
    }
  `;

  const updateCommentQuery = gql`
    mutation UpdateComment(
      $id: ID!,
      $reply: ReplyCreateInput!
    ) {
      updateComment(
        where: { id: $id },
        data: { replies: { create: [$reply] } }
      ) { id 
        replies(last:1){id} 
        }
    }
  `;

  try {
    if (obj.parentCommentId) {
      // Creating a reply
      const replyData = {
        name: obj.name,
        email: obj.email,
        reply: obj.comment
      };

      const result = await graphQLClient.request(updateCommentQuery, {
        id: obj.parentCommentId,
        reply: replyData
      });
      console.log('Created reply result:', result.updateComment.replies[0]);
      return result.updateComment.replies[0];//edited
    } else {
      // Creating a comment
      const result = await graphQLClient.request(createCommentQuery, {
        name: obj.name,
        email: obj.email,
        comment: obj.comment,
        slug: obj.slug
      });
      console.log('Created comment result:', result.createComment);
      return result.createComment;
    }
  } catch (error) {
    console.log(error);
  }
};



export const getComments = async (slug) => {
  const query = gql`
    query GetComments($slug:String!) {
      comments(orderBy:updatedAt_DESC, where: {post: {slug:$slug}}){
        id
        name
        createdAt
        comment
        replies{
          id
          name
          reply
          createdAt
        }
      }
    }
  `;
  const result = await request(graphqlAPI, query, { slug });
  return result.comments;
};

export const publishComment = async (id) =>{
  const graphQLClient = new GraphQLClient((process.env.REACT_APP_GRAPHQL_API), {
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_GRAPHCMS_TOKEN}`,
    },
  });
  const query = gql`
    mutation PublishComment($id:ID!){
        publishComment(where:{id:$id}, to: PUBLISHED){
            id
        }
    }

`;
try{
  const result = await graphQLClient.request(query, {id});
console.log('Publish comment result: ', result)
return result.publishComment;
} catch(error){
  console.log('Error publishing comment: ', error)
}
}

export const publishReply = async (id) =>{
  const graphQLClient = new GraphQLClient((process.env.REACT_APP_GRAPHQL_API), {
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_GRAPHCMS_TOKEN}`,
    },
  });
  const query = gql`
    mutation PublishReply($id:ID!){
        publishComment(where:{id:$id}, to: PUBLISHED){
            id
        }
    }

`;
try{
  const result = await graphQLClient.request(query, {id});
console.log('Publish reply result: ', result)
return result.publishReply;
} catch(error){
  console.log('Error publishing comment: ', error)
}
}