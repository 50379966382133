import React, { useState } from 'react'
    import './Subweb.css'
    import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
    import Modal from 'react-bootstrap/Modal';

    
    const TSMicrofinance = () => {
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
  
    function handleShow(breakpoint) {
      setFullscreen(breakpoint);
      setShow(true);
    }
      return (
        <div className='ts4humanity'>
           <p style={{ textAlign: 'center' }}>
           <Link to='/tsbf-ghana'><i><ChevronLeftIcon /></i> Back to TSBF GHANA</Link>
            </p>
            <h1 className='text-center fw-bold'>TS MICROFINANCE</h1>
            <button className='mb-5 btn btn-primary' onClick={() => handleShow(true)}> Click on me to see our completion progress </button>
                      {/* {values.map((v, idx) => (
          <Button key={idx} className="me-2 mb-2" onClick={() => handleShow(v)}>
            Full screen
            {typeof v === 'string' && `below ${v.split('-')[0]}`}
          </Button>
        ))} */}
              <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
              <Modal.Header className='bg-danger justify-center' closeButton>
           <Link to='/tsbf-ghana'><Modal.Title className='text-center text-white'><i><ChevronLeftIcon /></i> Back to TSBF GHANA</Modal.Title></Link>
           <h1 className='text-center fw-bold'>TS MICROFINANCE</h1>

              </Modal.Header>
              <Modal.Body closeButton>
                <iframe src="https://tsfinservices.superjcybs.com/" frameBorder="0" title='final Work' width="1320" height="1000"></iframe>
              </Modal.Body>
            </Modal>
    </div>
      )
    }
    
    export default TSMicrofinance
