import React from 'react'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const PortfolioPage = () => {

  const filterExe = (e) => {
    e.preventDefault()
    const filter = e.target.dataset.filter;

    const ChosenPortfolio = document.querySelectorAll('.portfolio-item');
    const filterButton = document.querySelectorAll('.fitbtn');

    const changeActiveItem = () => {
      filterButton.forEach(itSel => {
        itSel.classList.remove('filter-active')
      })
    }

    filterButton.forEach(btnSel => {
      btnSel.addEventListener('click', () => {
        changeActiveItem()
        btnSel.classList.add('filter-active')
      })
    })

    // })

    ChosenPortfolio.forEach((item) => {
      if (filter === 'all') {
        item.style.display = 'block'
      }
      else {
        if (item.classList.contains(filter)) {
          item.style.display = 'block'
        } else {
          item.style.display = 'none'
        }
      }
    })
  };

  return (
    <>
      <Helmet>
        <title>Our Portfolio - SUPERJCYBS</title>
        <meta name="description" content='Checkout a handful of some works done in times past' />
        <meta property="og:title" content='Our Portfolio - SUPERJCYBS' />
        <meta property="og:description" content='Checkout a handful of some works done in times past' />
        <meta property="og:url" content='https://superjcybs.com/portfolio' />
        <meta name="twitter:title" content='Our Portfolio - SUPERJCYBS' />
        <meta name="twitter:description" content='Checkout a handful of some works done in times past' />
      </Helmet>
      <section className="portfolio">
        <h2>Our Portfolio - CLIENT HYPE</h2>
        <h4 className='text-center'>We have done a lot during this our 5 years of establishment</h4>
        <div className="row" data-aos="fade-up" data-aos-delay={100}>
          <div className="col-lg-12">
            <ul id="portfolio-flters">
              <li onClick={filterExe} className="fitbtn filter-active" data-filter="all">All</li>
              <li onClick={filterExe} className="fitbtn" data-filter="filter-app">App</li>
              <li onClick={filterExe} className="fitbtn" data-filter="filter-marketing">Marketing</li>
              <li onClick={filterExe} className="fitbtn" data-filter="filter-web">Web</li>
              <li onClick={filterExe} className="fitbtn" data-filter="filter-graphics">Graphics</li>
              <li onClick={filterExe} className="fitbtn" data-filter="filter-trainings">Trainings</li>
            </ul>
          </div>
        </div>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col className='portfolio-item filter-graphics'>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/ad3.jpg" alt="chop better food joint - superjcybs" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/ad4.jpg" alt="christ embassy apam - superjcybs" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/ad5.jpg" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col className="portfolio-item filter-trainings">
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/freecoding.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col className="portfolio-item filter-trainings">
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/whatsapp.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col className="portfolio-item filter-trainings">
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/services.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/ad6.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/ad2.jpg" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col className="portfolio-item filter-marketing">
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/adpay.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col className="portfolio-item filter-marketing"> 
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/Domain.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col className="portfolio-item filter-graphics">
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/website.jpg" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/aborrba.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/vicky1.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/vicky2.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
<Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/adweb.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/adlogo.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/addatabundle.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/adcomm.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/learnevd.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/advircard.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/learndata.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/wmninner.jpg" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/combined.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/about-us" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/joysNaming.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/FAddoEnterprise.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/doroDelicious.jpg" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/doroDelicious1.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/degree.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/mydatewiththebible.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/bxd.jpg" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/eggs.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/advertisejob.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/resultchecking.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/americalottery.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={2} className="g-4 p-5">
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/paychannel.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white">
              <Card.Img src="Assets/images/works/mead.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Title>
                  <Link to="/" className="btn btn-primary">Visit Client</Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
      </section>
    </>
  )
}

export default PortfolioPage