import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const NavLinks = ({ categories }) => {
    const { pathname } = useLocation();

    return (
        <ul className='menu-lists'>
      <label htmlFor='menu-btn' className='fa-solid fa-xmark'/>

            <Link to='/dunamistravels'>
                <li className={`${pathname === '/dunamistravels' ? 'active':''}`}>ALL</li>
            </Link>
            {
                categories?.map(category => (
                    <Link key={category.slug} to={`/dunamistravels/posts/${category.slug}`}>
                        <li className={`/dunamistravels/posts/${category.slug}`=== pathname ? 'active':''}>
                            {category.name}
                        </li>
                    </Link>
                ))
            }
        </ul>
    )
}

export default NavLinks