import React from 'react'
import './Stats.css'
const Stats = () => {
  return (
    // <div>
      <div className="counts section-bg">
        <div className="container paralax-mf " >

        <div className="row">

            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">
              <div className="count-box">
                <i className="icofont-simple-smile" style={{ color: '#20b38e' }} />
                <span data-toggle="counter-up">232</span>
                <p>Happy Clients</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay={200}>
              <div className="count-box">
                <i className="icofont-document-folder" style={{ color: '#c042ff' }} />
                <span data-toggle="counter-up">521</span>
                <p>Projects</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay={400}>
              <div className="count-box">
                <i className="icofont-live-support" style={{ color: '#46d1ff' }} />
                <span data-toggle="counter-up">1,463</span>
                <p>Hours Of Support</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay={600}>
              <div className="count-box">
                <i className="icofont-users-alt-5" style={{ color: '#ffb459' }} />
                <span data-toggle="counter-up">15</span>
                <p>Hard Workers</p>
              </div>
            </div>
        </div>
      </div>
      
      </div>

    // </div>
  )
}

export default Stats