import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Terms = () => {
  useEffect(() => {
    document.title = "Our Terms - SUPERJCYBS"
  }, [])
  
  return (
    <div className='p-4'>
      <h2 className='text-center mt-4'>TERMS AND CONDITIONS</h2>
      <section style={{ lineHeight: "2rem", width: "600px", margin: "auto", padding: "3rem" }}>
        <article className='text-center mb-5'>
          CONTRACT BUSINESS CONSULTANCY SERVICE CONTRACT BETWEEN
          SUPER 'J' CY-BUSINESS AND SERVICES
          AND
          <br /><br />
          …………………………………………………………………………………

        </article>
        <article>
          <dl>
            <dt>1.0	- BASE INFORMATION AND DETAILS</dt>
            <dd>1.1 - The terms and conditions entrenched in this legal document (contract) is bound by the approved time stated in the CONTRACT DURATION section.</dd>
            <dd>1.2 - This contract sees the offeror as SUPER 'J' CY-BUSINESS AND SERVICES and the offeree as stated in ink above.</dd>
          </dl>
        </article>

        <article>
          <dl>
            <dt> 2.0 - CONTRACT SERVICE</dt>
            <dd>2.1 - We; offeror accepts to provide/execute the following marked service to the offeree;</dd>
          </dl>
          <ol style={{ fontWeight: 'bold' }}>
            <li>DIGITAL MARKETING</li>
            <ol type='a'>
              <li>Social Media</li>
              <ol type='i'>
                <li>Instagram</li>
                <li>Whatsapp</li>
                <li>Facebook</li>
              </ol>
              <li>Email</li>
              <ol type='i'>
                <li>Content Marketing</li>
              </ol>
              <li>SMS</li>
              <ol type='i'>
                <li>One Time Bulk SMS</li>
                <li>Daily Bulk SMS</li>
                <li>Weekly Bulk SMS</li>
                <li>Monthly Bulk SMS</li>
              </ol>
            </ol>

            <li>CUSTOMER RELATIONS MANAGEMENT</li>
            <ol type='a'>
              <li>Client Database</li>
              <li>Follow up </li>
            </ol>

            <li>FINANCIAL MANAGEMENT</li>
            <ol type='a'>
              <li>Investing wisely internally or externally</li>
              <li>Managing Personal and Business Funds</li>
              <li>Business Registration</li>
              <li>Business Bank Account  - Loans and Grants</li>
            </ol>

            <li>BUSINESS STARTUP / CONTINUITY MANAGEMENT</li>
            <ol type='a'>

              <li>Business Representation (Marketer)</li>
              <li>Marketing consultancy</li>
              <ol type='i'>

                <li>Advertising/Pitching - Audio / Video</li>
                <li>Branding</li>
              </ol>
            </ol>

            <li>BRANDING</li>
            <ol type='a'>
              <li>Logo</li>
              <li>Flyers</li>
              <li>Item customization (T-Shirt, keyholders, aprons)</li>
              <li>Business cards</li>
            </ol>
            <li>WEBSITE DEVELOPMENT</li>
            <ol type='a'>
              <li>Annual Renewal</li>
              <li>One-Time</li>
            </ol>
            <li>PRIVATE CLASSES (All Levels)</li>
            <ol>
              <li>Adult / Children computer training</li>
              <li>1 subject</li>
              <li>2-3 subjects</li>
              <li>More than 3</li>
            </ol>
          </ol>
        </article>

        <article>
          <dl>
            <dt>3.0 - CONTRACT DURATION</dt>
            <dd>3.1 - The offeree has agreed that the <b>CONTRACT SERVICE marked in SECTION 2.1</b> should be provided for the duration marked below;</dd>
            <li>1 month</li>
            <li>3 months</li>
            <li>6 months</li>
            <li>12 months (1 year)</li>
            <li>Other; kindly state your preferred duration ..........................................</li>
          </dl>

        </article>
        <article>
          <dl>
            <dt>4.0 - MONETORY</dt>
            <dd>4.1 - Our first meeting action with client is FREE-OF-CHARGE</dd>
            <dd>4.2 - MODE OF PAYMENT; Payments for service renders and any other funds that may not be stated in the confines of this document MUST be made to either the company MOBILE MONEY ACCOUNTS or BANK ACCOUNT stated on our payment page</dd>
            <Link to="/pay" style={{ color: "blue" }}>https://superjcybs.com/pay</Link>
          </dl>
        </article>
        <article>
          <dl>
            <dt>5.0 - ON BREACH OF TERMS</dt>
            <dd>5.1 - In a situation where the offeror is not able to complete the approved duration stated in this contract, The offeree MUST is entitled to REFUND if any payments has been made prior to this. The offeror MUST also compensate the offeree with 10% of the paid amount.</dd>
            <dd>5.2 - In a situation where the offeree is NOT ABLE or doesn't want to continue with the contract, The FULL PAYMENT for the service MUST be made to the offeror.</dd>
          </dl>

        </article>
        <article>
          Computer Repairs
          Computer Formatting
          Software Installation
          Internet for Homes and Offices (Wifi)

          Online Registrations  And Printings
          Nov/Dec Bio-metric Online Registration
          WAEC Access Cards
          WAEC Result Checking
          School Placement Checking & Printing

          University Online Registration
          Colleges of Education Online Registration

          Nursing online Registration
          E-Payslip Printing
          All kinds Admission Letter Printing

          Police Online Registration
          Prison Service Online Registration
          Fire Service Online Registration
          Immigration Online Registration
          Ghana Armed Force Online Registration
          And all kinds of Online Registration & Online Printing

          Examination Papers Printing
          Typesetting And Printing,
          Pamphlets
          Scanning,
          Document Printing
          Color and B&W Printing

          Invitation Cards
          Program guides, Wedding Programs
          Funeral Posters
          Certificates, Business cards
          Envelopes
          Flyers, Posters, Banners etc.
        </article>
      </section>
      <div className="contactus" style={{ display: 'flex', justifyContent: 'center', margin: '3rem 0' }}>
        <p>
          If you are a facing any challenge,{' '}
          <Link to="/#contact" style={{ color: 'blue' }}>Click Here to Ask for Support</Link>
        </p>
      </div>
    </div>
  )
}

export default Terms