import React from 'react'
import { Helmet } from 'react-helmet-async'

const CVjoseph = () => {

  return (
    <>
      <Helmet>
        <title>CV - Joseph Effraim</title>
        <meta name="description" content='Get intouch with me, lets deal on a professional level' />
        <meta property="og:title" content="CV - Joseph Effraim" />
        <meta property="og:description" content='Get intouch with me, lets deal on a professional level' />
        <meta property="og:url" content='https://superjcybs.com/cv=joseph-effraim' />
      </Helmet>
    <p>CURRICULUM VITAE - Joseph Effraim</p>
    </>
  )
}

export default CVjoseph