import React from 'react'
import { Helmet } from 'react-helmet-async';

const CVeunice = () => {

  return (
    <>
      <Helmet>
        <title>CV - Eunice Maame Esi Supernatural</title>
        <meta name="description" content='Get intouch with me, lets deal on a professional level' />
        <meta property="og:title" content="CV - Eunice Maame Esi Supernatural" />
        <meta property="og:description" content='Get intouch with me, lets deal on a professional level' />
        <meta property="og:url" content='https://superjcybs.com/cv=eunice-maame-esi-supernatural' />
      </Helmet>
      <p>CURRICULUM VITAE - Mr. Eunice Maame Esi Supernatural</p>
    </>
  )
}

export default CVeunice