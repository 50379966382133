import React from 'react'

function Adverts() {
  return (
    <div>
      <section id="advert" className="contact" style={{ backgroundColor: '#ff2a00', paddingTop: '2rem', paddingBottom: '2rem', borderTop: '3px solid #eb5d1e', borderBottom: '3px solid #25D366' }}>
        <div className="container">
          <div className="section-title text-center" data-aos="fade-up">
            <h2 className='text-white'><u>HOT ADVERTS</u></h2>
            <br />
          </div>
          <div className="row">
            <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
              <img src="Assets/images/works/addatabundle.png" className="img-fluid mb-1" alt='Data Bundle flyer' title='data bundle flyer' loading='lazy'/>
            </div>
            <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
              <img src="Assets/images/works/datalearn.png" className="img-fluid mb-1" alt='Learn Data Bundle flyer' title='Learn Data Bundle flyer' loading='lazy'/>
            </div>
            <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
              <img src="Assets/images/works/afa.jpg" className="img-fluid" alt='AFA Registration flyer' title='AFA Registration flyer' loading='lazy'/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
              <img src="Assets/images/works/learnwebdev.png" className="img-fluid mb-1" alt='Learn webDev' title='Learn WebDev' loading='lazy'/>
            </div>
            <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
              <img src="Assets/images/works/learnPhotoFraming.png" className="img-fluid mb-1" alt='Learn PhotoFraming' title='Learn Photo Framing' loading='lazy'/>
            </div>
            {/* <div className="col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
              <img src="Assets/images/works/afa.jpg" className="img-fluid" alt='AFA Registration flyer' title='AFA Registration flyer' loading='lazy'/>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Adverts