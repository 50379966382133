import React, { useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import './About.css'
import Stats from './Stats';
import MydModalWithGrid from '../common/Modal';

const About = (props) => {
  const [modalShow, setModalShow] = useState(false);

  return (

    <>
      <MydModalWithGrid show={modalShow} onHide={() => setModalShow(false)} content={
        // <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://youtu.be/oKu_yB5dyUA" title='our services'></iframe>
      } />



      <div id="about" className="about-area area-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-title text-center">
                <h2>About</h2>
                <p>SUPER 'J' CY-BUSINESS AND SERVICES</p>
              </div>
            </div>
          </div>
          <div className="row forall">
            {/* single-well start*/}
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="well-left">
                <div className="single-well" onClick={() => setModalShow(true)}>
                  {/* <Link to="https://youtu.be/oKu_yB5dyUA" > */}
                  <img src="Assets/images/about.jpg" alt='Superjcybs banner' className="img-fluid" title='Superjcybs banner' loading='lazy' style={{ object: 'contain', width: '40vw', float: 'left' }} />
                </div>
              </div>
            </div>
            {/* single-well end*/}
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="well-middle">
                <div className="single-well mt-3">
                  <p>
                    We've been building unique digital products, platforms, and experiences for the past 5 years.
                    Undertaking excellent jobs for our awesome clients.
                    We have been operating and serving diverse people irrespective of background.
                    We do serve not just in our physical location but also all around the globe via our
                    awesome tech team.
                    We get clients all around the world and we serve them as though we were there in person.
                    Our awesome customer service stays in touch 24/7.
                    We believe that, every client is worth our time.
                    Enquire of our services today and be a part of our amazing clients.
                  </p>
                  {/* <Link component="button" variant='body2' onClick={() => { console.info("I'm a button") }}>Button Link from Material UI</Link>
                  <Link href='#' color='inherit' underline='hover'>{'underline=hover'} </Link> */}
                  <ul>
                    <li>
                      <i ><CheckIcon /></i> Service with Excellence
                    </li>
                    <li>
                      <i ><CheckIcon /></i> Service with Preciseness
                    </li>
                    <li>
                      <i ><CheckIcon /></i> Service with Abundance
                    </li>
                    <li>
                      <i ><CheckIcon /></i> Serving always with a smile.
                    </li>
                    <li>
                      <i ><CheckIcon /></i> Service for all
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Stats />
      </div>


    </>
  )
}

export default About