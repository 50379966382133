import React, { useEffect, useRef } from 'react'
// import { useEffect } from 'react'
import ReactPlayer from 'react-player'
// import axios from 'axios'

// export const YouTubeCreate = axios.create({
//     baseURL: 'https://www.googleapis.com/youtube/v3/',
//     params: {
//         part: {
//             "snippet": {
//                 "resourceId": {
//                     "channelId": "UCnYL6e5pYht_mWKlEE7NTLw"
//                 }
//             }
//         }
//     ,
//     key: KEY
// }
// })


const OurYoutubeFeed = () => {

    // function addSubscription() {
    //     const YouTube = 'https://www.googleapis.com/youtube/v3/'
    //     // const YouTube = 'https://youtube.googleapis.com/youtube/v3/'
    //     var channelId = 'UCnYL6e5pYht_mWKlEE7NTLw';
    // var resource = {
    //     snippet: {
    //         resourceId: {
    //             kind: 'youtube#channel',
    //             channelId: channelId
    //         }
    //     }
    // };

    // try {
    //     var response = YouTube.subscriptions.insert(resource )
    //         // , 'snippet');
    //     console.log(response);
    // } catch (e) {
    //     if (e.message.match('subscriptionDuplicate')) {
    //         console.log('Cannot subscribe; already subscribed to channel: ' + channelId);
    //     } else {
    //         console.log('Error adding subscription: ' + e.message);
    //     }
    //     }
    // }

    // CODE 3
    // const body=
    // {
    //     'snippet': {
    //         'resourceId': {
    //             'kind': 'youtube#channel',
    //             'channelId': 'UC_x5XG1OV2P6uZZ5FSM9Ttw'
    //         }
    //     }
    // }
    // .then(() => {
    //     const fetchit =  Axios.post(`https://youtube.googleapis.com/youtube/v3/subscriptions?part=snippet`, body)
    //     console.log(fetchit);
    //     })

    //   const handleSubmit = async () => {
    //       const response = await YouTubeCreate.post('/subscriptions', {
    //           headers: {
    //               authorization: `Bearer + ${'141703528072-ibvlo903pd9msfbhdvn0cctkd01tnr6d.apps.googleusercontent.com'}`,
    //               'Content-Type': 'application/json',
    //             },
    //         })
    //         console.log("this is resp", response);
    //     };

    // const handleSubmit = async () => {
    //   const response = await axios.post('https://youtube.googleapis.com/youtube/v3/subscriptions?part=id%2C%20snippet%2CsubscriberSnippet%2CcontentDetails&key=AIzaSyDajQPDR8RvQpZBdtMmw-f6V9zqKr1-JFI', {
    //       headers: {
    //           authorization: `Bearer + ${process.env.REACT_APP_YOUR_CLIENT_ID}`,
    //           'Content-Type': 'application/json',
    //         },
    //     })
    //     console.log("this is resp", response);
    // };

    //   const handleSubmit = async () => {
    //       const response = await YouTubeCreate.post('/subscriptions', {
    //           headers: {
    //               authorization: `Bearer + ${'141703528072-ibvlo903pd9msfbhdvn0cctkd01tnr6d.apps.googleusercontent.com'}`,
    //               'Content-Type': 'application/json',
    //             },
    //         })
    //         console.log("this is resp", response);
    //     };

    // const fetchB = Axios.post(`https://youtube.googleapis.com/youtube/v3/subscriptions?part=id%2C%20snippet%2CsubscriberSnippet%2CcontentDetails&key=[YOUR_API_KEY]`, {
    //     headers: {
    //         authorization: `Bearer ${''}`,
    //         Content- Type: 'application/json'
    //     },
    // }

    // {
    //     "snippet": {
    //         "resourceId": {
    //             "channelId": "UCnYL6e5pYht_mWKlEE7NTLw"
    //         }
    //     }
    // }
    // )



    //  <script>


    //   function authenticate() {
    //     return gapi.auth2.getAuthInstance()
    //         .signIn({scope: "https://www.googleapis.com/auth/youtube.force-ssl"})
    //         .then(function() { console.log("Sign-in successful"); },
    //               function(err) { console.error("Error signing in", err); });
    //   }
    //   function loadClient() {
    //     gapi.client.setApiKey("YOUR_API_KEY");
    //     return gapi.client.load("https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest")
    //         .then(function() { console.log("GAPI client loaded for API"); },
    //               function(err) { console.error("Error loading GAPI client for API", err); });
    //   }
    //   // Make sure the client is loaded and sign-in is complete before calling this method.
    //   function execute() {
    //     return gapi.client.youtube.subscriptions.insert({
    //       "part": [
    //         "id, snippet,subscriberSnippet,contentDetails"
    //       ],
    //       "resource": {
    //         "snippet": {
    //           "resourceId": {
    //             "channelId": "UCnYL6e5pYht_mWKlEE7NTLw"
    //           }
    //         }
    //       }
    //     })
    //         .then(function(response) {
    //                 // Handle the results here (response.result has the parsed body).
    //                 console.log("Response", response);
    //               },
    //               function(err) { console.error("Execute error", err); });
    //   }
    //   gapi.load("client:auth2", function() {
    //     gapi.auth2.init({client_id: "YOUR_CLIENT_ID"});
    //   });
    // </script>
    // <button onclick="authenticate().then(loadClient)">authorize and load</button>
    // <button onclick="execute()">execute</button> 





    const ref = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            ref.current.click();
        }, 5000);
    }, []);

    return (
        <section id="blogfeed" className="blogfeed">

            <div className='container-fluid bg-light'>
                <ReactPlayer playing controls
                    width='100%' height='490px'
                    url='https://www.youtube.com/playlist?list=PLTqgjuVaeZkHY2IbDXTWZjmLHFqS0A9cI'
                    config={{ facebook: { appId: '686871576266039' } }}
                    muted
                />
            </div>
            <hr />
            <div>
                <h2 id="subscribesocial" className='text-center'>SUBSCRIBE TO CHANNELS</h2>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="g-ytsubscribe" data-channelid="UCnYL6e5pYht_mWKlEE7NTLw" data-layout="full" data-count="default" data-theme="dark" ref={ref}></div> <br />
                    <div className="g-ytsubscribe" data-channelid="UCsJ5VuzC3MPhfBCY4CG1Umw" data-layout="full" data-count="default" data-theme="dark" ref={ref}>     </div> <br />
                </div>
            </div>
            <hr />
            {/* <button onClick={addSubscription}>Subscribe</button> */}
            {/* <button onClick={handleSubmit}>SubscribeNew</button> */}
        </section>
    )
}

export default OurYoutubeFeed