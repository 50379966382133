import React, { useRef, useState } from 'react'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Alert from 'react-bootstrap/Alert';
import CallIcon from '@mui/icons-material/Call';
import { Link } from 'react-router-dom'
import './Contact.css'
import emailjs from '@emailjs/browser';
const Contact = () => {
   
  const [success, setSuccess] = useState(null)
    const form = useRef();
    
    const sendEmail = (e) => {
      e.preventDefault();
  
       emailjs
         .sendForm('service_qxsvb5t', 'template_zgr4cxj', form.current, {publicKey:'ibZjaF1rjAsvzDVGf'})
       .then(
        (result) => {
          setSuccess(true)
          e.target.reset()
          setTimeout(() => {
          setSuccess(null)
          }, 5000);
        }, 
        (error) => {
          setSuccess(false);
          setTimeout(() => {
          setSuccess(null)
          }, 5000);
         });
    };

  // method="POST" action="https://formspree.io/f/mjvdbgrk"

  // console.log('from useRef 2:', form.current)

  return (
    <div>
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title text-center" data-aos="fade-up">
            <h2>Contact Us </h2>
            <p>Contact us to get started using {' '}
              <Link to="https://wa.me/233247792110" style={{ color: 'green ' }}>whatsApp</Link>
              {' '} or</p>
          </div>
          <div className="row">
            <div className="col-lg-5 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
              <div className="info">
                <div className="address">
                  <i> <LocationOnIcon /></i>
                  <h4>Location:</h4>
                  <p>U436 Chickweed Street, Apam, Central Region. Ghana, West Africa CI-0000-4366</p>
                </div>
                <div className="email">
                  <i><MailOutlineIcon /></i>
                  <h4>Email:</h4>
                  <p>contact@superjcybs.com</p>
                </div>
                <div className="phone">
                  <i><CallIcon /></i>
                  <h4>Call:</h4>
                  <p>+233 24 779 2110</p>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2033324.3661493158!2d-2.333123912592041!3d5.516954937743526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfde319f78500833%3A0x68157f60b7ca69b9!2sSuper%20&#39;J&#39;%20Cy-Business%20and%20Services!5e0!3m2!1sen!2sgh!4v1664238707112!5m2!1sen!2sgh"
                  frameBorder={0} style={{ border: 0, width: '100%', height: 290 }} allowFullScreen title='locateUs' />
              </div>
            </div>
            <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={200}>
              <form id="myForm" className="php-email-form" name="mainMessaage" ref={form} onSubmit={sendEmail}  >
                <div className="form-row">
                  <input type="hidden" name="mainMessaage" value="messages" />
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Your Name</label>
                    <input type="text" name="user_name" className="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                    <div className="validate" />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Your Email</label>
                    <input type="email" className="form-control" name="user_email" id="email" data-rule="email" data-msg="Please enter a valid email" />
                    <div className="validate" />
                  </div>
                </div>
                <div className="form-group" disabled>
                  <label htmlFor="name">Subject</label>
                  <input type="text" className="form-control" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                  <div className="validate" />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Message</label>
                  <textarea className="form-control" name="message" rows={10} data-rule="required" data-msg="Please write something for us" defaultValue={""} />
                  <div className="validate" />
                </div>
                <div className="mb-3">
                  <div className="loading">Loading</div>
                  <div className="error-message" />
                  <div className="sent-message">Your message has been sent. Thank you!</div>

                  {(success===true)? 
                  (<Alert variant='success'>Your message has been sent. Thank you!</Alert>):
                  ((success===false)?(<Alert variant='danger'>Sorry, sending error, retry</Alert>)
                  :(''))
                  }
                </div>
                <div className="text-center"><button type="submit" >Send Message</button></div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact