import React from 'react'
import './NotFound.css'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import MoodIcon from '@mui/icons-material/Mood';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='notfound container-fluid bg' style={{backgroundImage:'url(Assets/images/notfound.jpg)'}}>
      <b className='text-center'>You are seeing this page because the page you seek is <br/><span style={{display:'inline-block', fontSize:'30px', color:'linear-gradient rgb(245,123,123)'}}> <SentimentVeryDissatisfiedIcon/>NOT FOUND</span></b>
      <div className="top"><img src="Assets/images/logos/webb.png" className="App-logo" alt='404-superjcybs' /></div>
      <Link to='/' className='link'><MoodIcon/>Please click on me</Link>

    </div>
  )
}

export default NotFound